import React, {useEffect, useRef, useState, Fragment} from 'react';
import * as apis from "../providers/apis";
import {set as set_page_title} from "../reducers/page_title";
import {set as set_searchbar} from "../reducers/searchbar";
import {useNavigate, useParams, usePrompt} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Alert,
  Box,
  CircularProgress, Divider, FormControl,
  Grid,
  IconButton,
  InputLabel, MenuItem,
  Modal, OutlinedInput,
  Paper, Select,
  Snackbar,
  TextField,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Pagination
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import DatePicker from "@mui/lab/DatePicker";
import DateTimePicker from "@mui/lab/DateTimePicker";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {Draggable, Droppable, DragDropContext} from 'react-beautiful-dnd';
import Searchbar from "../components/searchbar";
import Info from "../components/info";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import * as globals from "../providers/globals";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CachedIcon from '@mui/icons-material/Cached';
import GroupsIcon from '@mui/icons-material/Groups';

function Playday() {

  const newData = {
    playday: {id: '', name: '', day_name: '', start: null, end: null},
    playday_prev: '',
    playday_next: '',
    teams: []
  }

  const newModal = {
    home: {name: '', id: '', img: ''},
    away: {name: '', id: '', img: ''},
    datum: null,
    channel: '',
    index: '',
    modal: false
  };

  const newGame = {
    id: '',
    optauuid: '',
    datum: null,
    datum_raw: null,
    removable: true,
    hasdata: false,
    refreshed: false,
    channel: '',
    home: {
      team_id: '',
      team_name: '',
      team_img: '',
      url: '',
      goals: 0
    },
    away: {
      team_id: '',
      team_name: '',
      team_img: '',
      url: '',
      goals: 0
    }
  };

  const params = useParams();
  const dispatch = useDispatch();
  const page = useSelector(state => state.page.value)
  const [data, setData] = useState([]);
  const [restgames, setRestgames] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [working, setWorking] = useState(false);
  const [info, setInfo] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [refresh, setRefresh] = useState({});
  const [modal, setModal] = useState(newModal);
  const navigate = useNavigate();

  useEffect(() => {
    setRestgames([])
    setDirty(false);
    let id = params.dayid;
    if (id != null) {
      setLoading(true);
      apis
        .playdayget(id)
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          if (json.games.length > 30) {
            setRestgames(json.games.slice(30))
            json.games = json.games.slice(0, 30);
          }
          dispatch(set_page_title(json.playday.name + ': Bearbeiten'))
          dispatch(set_searchbar({
            name: json.competition.name,
            id: json.competition.id,
            img: json.competition.img,
            type: 'wettbewerb'
          }))
          setData(json)
          setLoading(false)
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setLoading(true);
      apis
        .playdayget('', params.seasonid)
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          dispatch(set_page_title(json.competition.name + ': Spieltag anlegen'))
          dispatch(set_searchbar({
            name: json.competition.name,
            id: json.competition.id,
            img: json.competition.img,
            type: 'wettbewerb'
          }))
          let t = {...newData}
          t.seasonid = params.seasonid;
          t.gameweeks = json.gameweeks;
          setData(t);
          setLoading(false)
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [page])

  const changeField = (v, field) => {
    setDirty(true);
    let d = {...data};
    d.playday[field] = v;
    setData(d);
  }

  const removeGame = (i) => {
    setDirty(true)
    let d = {...data};
    d.games.splice(i, 1);
    setData(d)
  }

  const submit = () => {
    if (working) return false;
    let d = {...data}
    d.playday.start = globals.dateToUS(d.playday.start)
    d.playday.end = globals.dateToUS(d.playday.end)
    console.log(d);
    if (restgames.length > 0)
      d.games = [...d.games, ...restgames];
    apis.playdayset(d)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setWorking(false)
        if (json.code === 1) {
          setSuccess(true);
          setDirty(false);
          setTimeout(() => {
            if (d.playday.id === '')
              navigate('/search/competition/' + params.competitionid + '/season/' + params.seasonid + '/playday/' + json.id);
          }, 200)
        } else {
          setInfo(true);
          setMessage(json.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  usePrompt(
    "Änderungen wirklich verwerfen?",
    dirty
  );

  const addGame = () => {
    let d = {...data};
    if (modal.home.id === '' || modal.away.id === '') {
      setMessage('Team leer')
      setInfo(true)
      return false
    }
    if (!(modal.datum instanceof Date && !isNaN(modal.datum.valueOf()))) {
      let test = new Date(Date.parse(modal.datum));
      if (test instanceof Date && !isNaN(modal.datum.valueOf())) {
        setMessage('Falsches Datumformat')
        setInfo(true)
        return false
      }
    }
    if (modal.index === '') {
      let game = {...newGame};
      game.datum_raw = globals.datetimeToUS(modal.datum)
      game.datum = globals.weekday(modal.datum) + ' ' + globals.dateToGerman(modal.datum) + ' | ' + modal.datum.getHours() + ':' + modal.datum.getMinutes();
      game.channel = modal.channel;

      game.home.team_id = modal.home.id;
      game.home.team_name = modal.home.name;
      game.home.team_img = modal.home.img;
      game.home.team_img = modal.home.img;

      game.away.team_id = modal.away.id;
      game.away.team_name = modal.away.name;
      game.away.team_img = modal.away.img;

      d.games.unshift(game)
    } else {
      if (modal.datum !== d.games[modal.index].datum_raw)
        d.games[modal.index].datum = globals.weekday(modal.datum) + ' ' + globals.dateToGerman(modal.datum) + ' | ' + modal.datum.getHours() + ':' + modal.datum.getMinutes();
      d.games[modal.index].datum_raw = globals.datetimeToUS(modal.datum)
      d.games[modal.index].channel = modal.channel;

      d.games[modal.index].home.team_id = modal.home.id;
      d.games[modal.index].home.team_name = modal.home.name;
      d.games[modal.index].home.team_img = modal.home.img;
      d.games[modal.index].home.team_img = modal.home.img;

      d.games[modal.index].away.team_id = modal.away.id;
      d.games[modal.index].away.team_name = modal.away.name;
      d.games[modal.index].away.team_img = modal.away.img;
    }
    setDirty(true);
    let m = {...newModal}
    setData(d)
    setModal(m)
  }

  const goals = (action, type, index) => {
    setDirty(true)
    let d = {...data};
    if (action === 'inc') {
      d.games[index][type].goals++;
    } else if (action === 'dec') {
      d.games[index][type].goals--;
      if (d.games[index][type].goals < 0)
        d.games[index][type].goals = 0;
    }
    setData(d);
  }

  const doRrefresh = (id, index) => {
    let r = {...refresh};
    r[id] = true;
    setRefresh(r);
    apis.gameimport(id)
      .then((response) => response.json())
      .then(() => {
        let r = {...refresh};
        delete (r[id]);
        setRefresh(r);
        let d = {...data}
        d.games[index].refreshed = true;
        setData(d)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const addTeamHome = (v) => {
    let m = {...modal};
    let temp = {name: '', id: '', img: ''};
    if (!(v == null || v === 0)) {
      temp.id = v.id;
      temp.name = v.name;
      temp.img = v.img
    }
    m.home.id = temp.id;
    m.home.name = temp.name;
    m.home.img = temp.img
    setModal(m);
  }

  const addTeamAway = (v) => {
    let m = {...modal};
    let temp = {name: '', id: '', img: ''};
    if (!(v == null || v === 0)) {
      temp.id = v.id;
      temp.name = v.name;
      temp.img = v.img
    }
    m.away.id = temp.id;
    m.away.name = temp.name;
    m.away.img = temp.img
    setModal(m);
  }

  const changeDatum = (v) => {
    let m = {...modal};
    m.datum = v
    setModal(m)
  }

  const changeChannel = (e) => {
    let m = {...modal}
    m.channel = e
    setModal(m)
  }

  const editModal = (i) => {
    setModal({
      home: {name: data.games[i].home.team_name, id: data.games[i].home.team_id, img: data.games[i].home.team_img},
      away: {name: data.games[i].away.team_name, id: data.games[i].away.team_id, img: data.games[i].away.team_img},
      channel: data.games[i].channel ?? '',
      datum: data.games[i].datum_raw, index: i, modal: true
    })
  }

  const showAll = () => {
    let d = {...data}
    d.games = [...d.games, ...restgames];
    setData(d)
    setRestgames([]);
  }

  const screen = () => {
    if (data.playday)
      return (
        <Box sx={{mt: '10px'}}>
          <Info message={"Server meldet: " + message} open={info}
                cancel={() => setInfo(false)}/>
          <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            open={success}
            onClose={() => setSuccess(false)}
            autoHideDuration={4000}
            key={'playersnack'}>
            <Alert onClose={() => setSuccess(false)} severity="success" sx={{width: '100%'}}>
              Speichern erfolgreich!
            </Alert>
          </Snackbar>
          {working ? <Button sx={{position: 'fixed', bottom: 10, right: 10, visibility: dirty ? 'visible' : 'hidden'}}
                             color={'success'} variant="contained">
            <CircularProgress size={14} sx={{color: 'black'}}/>
          </Button> : <Button sx={{position: 'fixed', bottom: 10, right: 10, visibility: dirty ? 'visible' : 'hidden'}}
                              color={'success'} variant="contained"
                              endIcon={<CheckIcon/>} onClick={() => submit()}>
            Speichern
          </Button>}
          <Grid container spacing={2} sx={{mt: 0}} flexDirection={"column"}>
            <Grid item sm={12}>
              <Paper sx={{
                height: '40px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'right'
              }}>
                <Box>{data.playday.name}</Box>
                <ButtonGroup sx={{ml: '10px'}} variant={'outlined'} size="small">
                  <Button disabled={data.playday_prev === ''}
                          onClick={data.playday_prev !== '' ? () => navigate('/search/competition/' + params.competitionid + '/season/' + params.seasonid + "/playday/" + data.playday_prev.id) : null}>&lt;</Button>
                  <Button disabled={data.playday_next === ''}
                          onClick={data.playday_next !== '' ? () => navigate('/search/competition/' + params.competitionid + '/season/' + params.seasonid + "/playday/" + data.playday_next.id) : null}>&gt;</Button>
                </ButtonGroup>
              </Paper>
            </Grid>
            <Grid item sm={12}>
              <Grid container spacing={2} flexDirection={"row"}>
                <Grid item sm={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink>Name</InputLabel>
                    <Select
                      input={<OutlinedInput notched label={'Name'}/>}
                      fullWidth
                      value={data.playday.day_name}
                      onChange={(e) => changeField(e.target.value, 'day_name')}
                    >
                      {data.gameweeks && data.gameweeks.map(e => <MenuItem key={'gw' + e.id}
                                                                           value={e.id}>{e.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <DatePicker
                    label="Start"
                    value={data.playday.start}
                    onChange={(newValue) => changeField(newValue, 'start')}
                    renderInput={(params) => <TextField fullWidth {...params} InputLabelProps={{shrink: true}}/>}
                    mask={'__.__.____'}
                  />
                </Grid>
              </Grid>
              <Grid sx={{mt: '5px'}} container spacing={2} flexDirection={"row"}>
                <Grid item sm={6}>
                  <FormControl>
                    <FormControlLabel control={<Switch checked={data.playday.currentday}
                                                       onChange={(e) => changeField(e.target.checked, 'currentday')}/>}
                                      label="Current"/>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <DatePicker
                    label="Start"
                    value={data.playday.end}
                    onChange={(newValue) => changeField(newValue, 'end')}
                    renderInput={(params) => <TextField fullWidth {...params} InputLabelProps={{shrink: true}}/>}
                    mask={'__.__.____'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*games*/}
          {data.games ?
            <TableContainer sx={{mt: '20px'}} component={Paper}>
              <Table sx={{minWidth: 650}} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Datum</TableCell>
                    <TableCell colSpan={3} align="left">Heim</TableCell>
                    <TableCell align="left">Ergebnis</TableCell>
                    <TableCell colSpan={3} align="left">Auswärts</TableCell>
                    <TableCell/>
                    <TableCell align="left">
                      <IconButton aria-label="add" sx={{backgroundColor: '#00ba83'}}
                                  onClick={() => setModal({...newModal, modal: true})}>
                        <AddIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.games.map((row, i) => (
                    <TableRow key={'game' + row.id}>
                      <TableCell width={'200px'} align="left"><span>{row.datum}</span></TableCell>
                      <TableCell width={'30px'} sx={{pl: '5px', pr: '5px'}} align="left"><Box
                        sx={{width: '30px', height: '30px'}} component={'img'}
                        src={row.home.team_img}/></TableCell>
                      <TableCell width={'200px'} align="left">{row.home.team_name}</TableCell>
                      <TableCell width={'30px'} align="left">
                        <IconButton sx={{backgroundColor: '#da8a22'}}
                                    onClick={() => window.open(row.home.url, '_blank')} disabled={!row.hasdata}>
                          <GroupsIcon/>
                        </IconButton>
                      </TableCell>
                      <TableCell align="left" width={'200px'}>

                        <Box display={'flex'} flexDirection={'row'}>
                          <Box display={'flex'} flexDirection={'row'} width={'70px'}>
                            <TextField sx={{width: '50px'}} inputProps={{style: {textAlign: 'center'}}}
                                       value={row.home.goals}/>
                            <ToggleButtonGroup orientation="vertical" sx={{width: '10px', height: 'auto'}}>
                              <ToggleButton value={0} sx={{height: '28px'}} onClick={() => goals('inc', 'home', i)}>
                                <ArrowDropUpIcon/>
                              </ToggleButton>
                              <ToggleButton value={0} sx={{height: '28px'}} onClick={() => goals('dec', 'home', i)}>
                                <ArrowDropDownIcon/>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                          <Box sx={{width: '20px', textAlign: 'center', lineHeight: '50px'}}>&nbsp;:</Box>
                          <Box display={'flex'} flexDirection={'row'} width={'70px'}>
                            <TextField sx={{width: '50px'}} inputProps={{style: {textAlign: 'center'}}}
                                       value={row.away.goals}/>
                            <ToggleButtonGroup orientation="vertical" sx={{width: '10px', height: 'auto'}}>
                              <ToggleButton value={0} sx={{height: '28px'}} onClick={() => goals('inc', 'away', i)}>
                                <ArrowDropUpIcon/>
                              </ToggleButton>
                              <ToggleButton value={0} sx={{height: '28px'}} onClick={() => goals('dec', 'away', i)}>
                                <ArrowDropDownIcon/>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </Box>

                      </TableCell>
                      <TableCell width={'30px'} sx={{pl: '5px', pr: '5px'}} align="left"><Box
                        sx={{width: '30px', height: '30px'}} component={'img'}
                        src={row.away.team_img}/></TableCell>
                      <TableCell width={'200px'} align="left">{row.away.team_name}</TableCell>
                      <TableCell width={'30px'} align="left">
                        <IconButton sx={{backgroundColor: '#da8a22'}}
                                    onClick={() => window.open(row.away.url, '_blank')} disabled={!row.hasdata}>
                          <GroupsIcon/>
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {row.removable ?
                          <IconButton sx={{backgroundColor: '#da8a22'}} onClick={() => editModal(i)}>
                            <EditIcon/>
                          </IconButton>
                          : null}
                      </TableCell>
                      <TableCell>
                        {row.removable ?
                          <IconButton sx={{backgroundColor: '#af0909'}} onClick={() => removeGame(i)}>
                            <RemoveIcon/>
                          </IconButton>
                          :
                          <IconButton
                            sx={{backgroundColor: row.refreshed ? '#00ba83' : '#af0909', width: '40px', height: '40px'}}
                            onClick={typeof refresh[row.optauuid] != 'undefined' && refresh[row.optauuid] === true ? null : () => doRrefresh(row.optauuid, i)}>
                            {typeof refresh[row.optauuid] != 'undefined' && refresh[row.optauuid] === true ?
                              <CircularProgress size={14} sx={{color: 'black'}}/>
                              :
                              <CachedIcon/>
                            }
                          </IconButton>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            : null}
          <Box sx={{mt: '10px'}} display={restgames.length > 0 ? 'flex' : 'none'} flexDirection={'row'}
               justifyContent={'center'}>
            <Button onClick={showAll} variant={'contained'}>Mehr anzeigen</Button>
          </Box>
          {/*add game modal*/}
          <Modal
            open={modal.modal}
            onClose={() => setModal({...modal, modal: false})}
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}>
              <Box display={'flex'} flexDirection={'column'}>
                <Box sx={{margin: '10px'}}>
                  <Searchbar label={'Heim'} autofocus type={'team'}
                             value={{name: modal.home.name, id: modal.home.id, img: modal.home.img}}
                             onChange={addTeamHome}/>
                </Box>
                <Box sx={{margin: '10px'}}>
                  <Searchbar label={'Away'} autofocus type={'team'}
                             value={{name: modal.away.name, id: modal.away.id, img: modal.away.img}}
                             onChange={addTeamAway}/>
                </Box>
                <Box sx={{margin: '10px'}}>
                  <DateTimePicker
                    label="Datum"
                    value={modal.datum}
                    onChange={(newValue) => changeDatum(newValue)}
                    renderInput={(params) => <TextField fullWidth {...params} InputLabelProps={{shrink: true}}/>}
                    mask={'__.__.____'}
                  />
                </Box>
                {data.channels ? <Box sx={{margin: '10px'}}>
                  <FormControl fullWidth>
                    <InputLabel shrink>Kanal</InputLabel>
                    <Select
                      input={<OutlinedInput notched label={'Kanal'}/>}
                      fullWidth
                      value={modal.channel}
                      onChange={(e) => changeChannel(e.target.value)}
                    >
                      <MenuItem value={''}/>
                      {data.channels.map(e =>
                        <MenuItem value={e}>{e}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box> : null}
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                  <Button
                    color={'success'} variant="contained"
                    endIcon={<CheckIcon/>} onClick={addGame}>
                    {modal.index === '' ? 'Einfügen' : 'Ändern'}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    else
      return null;
  }

  if (loading)
    return loadingScreen();
  else if (data)
    return screen()

}

const loadingScreen = () => {
  return (
    <Box sx={{mt: 7, width: '100%'}}>
      <LinearProgress/>
    </Box>
  )
}

export default Playday;
