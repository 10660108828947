import { createSlice } from '@reduxjs/toolkit'

export const user = createSlice({
  name: 'user',
  initialState: {
    value: null
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload
      globalThis.user = action.payload
      window.localStorage.setItem('user', JSON.stringify(action.payload))
    }
  }
})

// Action creators are generated for each case reducer function
export const { set } = user.actions

export default user.reducer
