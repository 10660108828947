/*let user = window.localStorage.getItem('user')
if (user != null)
  user = JSON.parse(user)*/

var uri = ""

if (window.location.href.match('admin.ligainsider.de')) {
  uri = 'https://www.ligainsider.de/beapi/'
} else {
  //uri = 'https://www.ligainsider.de/beapi/'
  uri = 'https://hossidev.ligainsider.de/beapi/';
}

const attachCreds = (body) => {
  if (typeof globalThis.user != 'undefined')
    return { ...body, user_id: globalThis.user.id, session_token: globalThis.user.session_token }
  else
    return { ...body }
  /*let l = setInterval(() => {
    if(typeof user != 'undefined'){
      clearInterval(l)
      console.log({ ...body, user_id: user.id, session_token: user.session_token })
      return { ...body, user_id: user.id, session_token: user.session_token }
    }
  }, 50)*/
}

export function auth(type, body) {
  return fetch(uri + 'auth/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type: type,
      body: body
    }),
  });
}

export function search(searchString, type, meta) {
  return fetch(uri + 'search/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      searchString: searchString,
      type: type,
      meta: meta
    })),
  });
}

export function playerget(id = null) {
  return fetch(uri + 'playerget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      id: id
    })),
  });
}

export function playerset(body) {
  return fetch(uri + 'playerset/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function teamget(id, tmid = null) {
  return fetch(uri + 'teamget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      id: id,
      tmid: tmid
    })),
  });
}

export function teamset(body) {
  return fetch(uri + 'teamset/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function newsget(id) {
  return fetch(uri + 'newsget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      id: id
    })),
  });
}

export function newsset(body) {
  return fetch(uri + 'newsset/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function userget(id) {
  return fetch(uri + 'userget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      id: id
    })),
  });
}

export function userban(body) {
  return fetch(uri + 'userban/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function commentdelete(body) {
  return fetch(uri + 'commentdelete/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function groupset(body) {
  return fetch(uri + 'groupset/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function alertsget() {
  return fetch(uri + 'alertsget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({})),
  });
}

export function notificationsget() {
  return fetch(uri + 'notificationsget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({})),
  });
}

export function newslistget(page) {
  return fetch(uri + 'newslistget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      page: page
    })),
  });
}

export function commentloopget() {
  return fetch(uri + 'commentloopget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({})),
  });
}

export function reportset(body) {
  return fetch(uri + 'reportset/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function competitionget(id) {
  return fetch(uri + 'competitionget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      id: id
    })),
  });
}

export function postva(tmid) {
  return fetch(uri + 'postvanews/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      tmid: tmid
    })),
  });
}

export function tmimport(team_id, day_id = null, topelf = false, list = false) {
  return fetch(uri + 'tmimport/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      team_id: team_id,
      day_id: day_id,
      list: list,
      topelf: topelf
    })),
  });
}

export function seasonget(season_id) {
  return fetch(uri + 'seasonget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      id: season_id
    })),
  });
}

export function seasonset(body) {
  return fetch(uri + 'seasonset/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function playdayget(id, seasonid = '') {
  return fetch(uri + 'playdayget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      id: id,
      seasonid: seasonid
    })),
  });
}

export function playdayset(data) {
  return fetch(uri + 'playdayset/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(data)),
  });
}

export function gameimport(id) {
  return fetch(uri + 'gameimport/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      id: id
    })),
  });
}

export function statusget(id) {
  return fetch(uri + 'statusget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      id: id
    })),
  });
}

export function statusset(body) {
  return fetch(uri + 'statusset/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function imagesget(body) {
  return fetch(uri + 'imagesget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function imageset(body) {
  return fetch(uri + 'imageset/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function imagedelete(id) {
  return fetch(uri + 'imagedelete/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({ id: id })),
  });
}

export function startpageset(body) {
  return fetch(uri + 'startpageset/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function profileget(body) {
  return fetch(uri + 'profileget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function tagsget(body) {
  return fetch(uri + 'tagsget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function gamelist(body) {
  return fetch(uri + 'gamelist/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds(body)),
  });
}

export function newsloopget() {
  return fetch(uri + 'newsloopget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }, body: JSON.stringify(attachCreds({})),
  });
}

export function newssetstate(newsid, userid, datePublish, state) {
  return fetch(uri + 'newssetstate/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      newsid: newsid,
      userid: userid,
      date_created: datePublish,
      state: state
    })),
  });
}

export function newsdelete(newsid, userid) {
  return fetch(uri + 'newsdelete/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      newsid: newsid,
      userid: userid
    })),
  });
}

export function internalnewscommentset(newsid, userid, comment) {
  return fetch(uri + 'internalnewscommentset/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      newsid: newsid,
      userid: userid,
      comment: comment
    })),
  });
}

export function internalnewscommentdelete(userid, commentid) {
  return fetch(uri + 'internalnewscommentdelete/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      userid: userid,
      commentid: commentid
    })),
  });
}

export function playerpositiontableget(what, teamid) {
  return fetch(uri + 'playerpositiontableget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }, body: JSON.stringify(attachCreds({
      what: what,
      teamid: teamid
    })),
  });
}

export function playerpositiontableset(data) {
  return fetch(uri + 'playerpositiontableset/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }, body: JSON.stringify(attachCreds({
      data: data
    })),
  });
}

export function userget1(id) { //just  to use another url for live
  return fetch(uri + 'userget/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      id: id
    })),
  });
}

export function userdelete(id) {
  return fetch(uri + 'userdelete/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachCreds({
      deleteuserid: id
    })),
  });
}