import React, { useEffect, useRef, useState, Fragment } from 'react';
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import { TextField, Grid, Divider, Autocomplete, Avatar, Select, MenuItem, Icon } from "@mui/material";
import Searchbar from '../components/searchbar'
import {useDispatch, useSelector} from 'react-redux';
import {set as set_page_title} from "../reducers/page_title";
import {set as set_searchbar} from '../reducers/searchbar';
import store from '../store';

function Search() {

  const searchTypes = ['wettbewerb', 'spieler', 'team', 'nation', 'user']

  const navigate = useNavigate();

  const [search, setSearch] = useState({ name: '', id: '', img: '' });
  const [type, setType] = useState(searchTypes.join('-'));
  const page = useSelector(state => state.page.value)
  const searchbar = useSelector(state => state.searchbar.value)
  let location = useLocation();

  useEffect(() => {
    // useDispatch doesn't work here for some reason
    if(location.pathname === '/search' || location.pathname === '/'){
      store.dispatch(set_page_title('Suche'))
      setSearch({name: '', id: '', img: '', type: searchTypes.join('-')})
      setType(searchTypes.join('-'))
    }
  }, [page])

  useEffect(() => {
    setSearch(searchbar)
    if(typeof searchbar.type != 'undefined')
      setType(searchbar.type)
  }, [searchbar])

  const handleResult = (v) => {
    let temp = {name: '', id: '', img: ''};
    if(!(v == null || v === 0)){
      temp.name = v.name;
      temp.img = v.img;
      temp.id = v.id
    }
    setSearch(temp)
    if(v == null || v === 0){
      return false;
    }
    if(v.type === 'Spieler')
      navigate("/search/player/"+v.id)
    else if(v.type === 'Team')
      navigate("/search/team/"+v.id)
    else if(v.type === 'Wettbewerb')
      navigate("/search/competition/"+v.id)
    else if(v.type === 'User')
      navigate("/search/user/"+v.id)
  }

  const handleInput = (v) => {
    let s = { ...search };
    let temp = {name: '', id: null};
    if(!(v == null || v === 0)){
      temp.name = v.name;
    }
    s.name = temp.name;
    setSearch(s);
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Grid container justifyContent={'center'} flexDirection={'row'}>
        <Grid item sm={1}>
          <Select
            sx={{ fontSize: 12, height: '100%' }}
            fullWidth
            value={type}
            onChange={(e) => setType(e.target.value)}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
          >
            <MenuItem key={'type0'} value={searchTypes.join('-')}>Alle</MenuItem>
            {searchTypes.map((e, i) => <MenuItem key={'type'+(i+1)} value={e}>{e.charAt(0).toUpperCase() + e.slice(1)}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item sm={6}>
          <Searchbar showGroup={type === searchTypes.join('-')} type={type} onInputChange={handleInput} onChange={handleResult} value={{ name: search.name, id: search.id, img: search.img }} />
        </Grid>
      </Grid>
      <Box sx={{width: '70%', m: 'auto'}}>
        <Outlet/>
      </Box>
    </Box>
  )
    ;
}

export default Search;
