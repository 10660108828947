import React, {useEffect, useRef, useState} from 'react';
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams, usePrompt, useNavigate, useParams} from "react-router-dom";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Switch,
  OutlinedInput,
  Snackbar,
  Alert, CircularProgress
} from "@mui/material";
import * as apis from "../providers/apis";
import * as globals from "../providers/globals"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import DatePicker from '@mui/lab/DatePicker';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AddIcon from "@mui/icons-material/Add";
import Yesno from '../components/yesno';
import Info from '../components/info';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Searchbar from '../components/searchbar'
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Checkbox from '@mui/material/Checkbox';
import {set as set_page_title} from "../reducers/page_title";
import {set as set_searchbar} from "../reducers/searchbar";
import DateTimePicker from "@mui/lab/DateTimePicker";
import Iconlist from "../components/iconlist";

function Player() {

  const newModal = {
    id: '',
    index: '',
    icon: {name: '', id: '', img: ''},
    datum: null,
    competition : {name: '', id: ''},
    count: '',
    tag: {name: '', id: '', img: ''},
    user: {id: '', name: ''}, //TODO logged user
    type: 0,
    edited: false,
    modal: false
  };

  const competitions = [
    {id: '', name: ''},
    {id: 1, name: '1. Bundesliga'},
    {id: 11, name: '2. Bundesliga'},
    {id: 4, name: 'DFB Pokal'},
    {id: 2, name: 'Championsleague'},
  ];

  // GET params
  const params = useParams();
  const [data, setData] = useState(null);
  const [modal, setModal] = React.useState(newModal);
  const page = useSelector(state => state.page.value)
  const [loading, setLoading] = useState(true);
  const [dirty, setDirty] = useState(false);
  const [info, setInfo] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [deleted, setDeleted] = useState([]);
  const [working, setWorking] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changeField = (value, field) => {
    let m = {...modal};
    if(field !== 'competition')
      m[field] = value;
    else{
      competitions.forEach(e => {
        if(e.id === value){
          m.competition.id = value;
          m.competition.name = e.name;
          return false;
        }
      })
    }
    setModal(m);
  }

  const changeIcon = (icon) => {
    let m = {...modal}
    m.icon = icon;
    setModal(m)
  }

  const handleTag = (v) => {
    let m = {...modal};
    console.log(v, m);
    let temp = {name: '', id: '', img: ''};
    if (!(v == null || v === 0)) {
      temp.id = v.id;
      temp.name = v.name;
    }
    modal.tag.id = temp.id;
    modal.tag.name = temp.name;
    setModal(m);
  }

  const go = () => {
    window.scrollTo(0, 0)
    setDirty(false);
    let id = params.id;
    if (id != null) {
      setLoading(true);
      apis
        .statusget(id)
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          dispatch(set_page_title(json.player.name + ': Krankenakte'))
          dispatch(set_searchbar({name: json.player.name, id: json.player.id, img: json.player.img, type: 'spieler'}))
          setData(json)
          setLoading(false)
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  useEffect(() => {
    go()
  }, [page])

  const loadingScreen = () => {
    return (
      <Box sx={{mt: 7, width: '100%'}}>
        <LinearProgress/>
      </Box>
    )
  }

  const submit = () => {
    let d = {...data, deleted: deleted};
    //console.log(JSON.stringify(d));
    //return false;
    setWorking(true)
    apis.statusset(d)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.code === 1) {
          setSuccess(true);
          setDirty(false);
          setLoading(true)
          go()
        } else {
          setInfo(true);
          setMessage(json.msg);
        }
        setWorking(false)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  usePrompt(
    "Änderungen wirklich verwerfen?",
    dirty
  );

  const deleteStatus = (i) => {
    setDirty(true)
    let d = {...data};
    setDeleted([...deleted, d.status[i].id]);
    d.status.splice(i, 1);
    setData(d)
  }

  const addStatus = () => {
    setDirty(true)
    let d = {...data};
    if(modal.index !== ''){
      d.status[modal.index] = modal;
    }else{
      d.status.unshift(modal);
    }
    setData(d)
    setModal({...newModal, modal: false})
  }

  const editStatus = (i) => {
    setModal({
      ...data.status[i],
      edited: true,
      index: i,
      modal: true
    })
  }

  const expandImg = (img, type = 'small') => {
    return 'https://cdn.ligainsider.de/images/icons/new/'+type+'/'+img+'.png';
  }

  const screen = () => {
    return (
      <>
        <Info message={"Server meldet: " + message} open={info}
              cancel={() => setInfo(false)}/>
        <Snackbar
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          open={success}
          onClose={() => setSuccess(false)}
          autoHideDuration={4000}
          key={'playersnack'}>
          <Alert onClose={() => setSuccess(false)} severity="success" sx={{width: '100%'}}>
            Speichern erfolgreich!
          </Alert>
        </Snackbar>
        {working ? <Button sx={{position: 'fixed', bottom: 10, right: 10, visibility: dirty ? 'visible' : 'hidden'}}
                           color={'success'} variant="contained">
          <CircularProgress size={14} sx={{color: 'black'}}/>
        </Button> : <Button sx={{position: 'fixed', bottom: 10, right: 10, visibility: dirty ? 'visible' : 'hidden'}}
                            color={'success'} variant="contained"
                            endIcon={<CheckIcon/>} onClick={() => submit()}>
          Speichern
        </Button>}
        <Box sx={{mt: '20px', maxWidth: 1000, display: 'flex', flexDirection: 'row', justifyContent: 'right'}}>
          <Button onClick={() => navigate("/search/player/" + data.player.id)}>Profil</Button>
        </Box>
        <TableContainer sx={{mt: '20px', maxWidth: 1000, overflow: 'hidden'}} component={Paper}>
          <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>Icon & Art</TableCell>
                <TableCell align="center">Tag</TableCell>
                <TableCell align="center">Wettbewerb</TableCell>
                <TableCell align="center">Datum</TableCell>
                <TableCell align="center">Melder</TableCell>
                <TableCell colspan={2}>
                  <IconButton aria-label="add" sx={{backgroundColor: '#00ba83'}}
                              onClick={() => setModal({...newModal, datum: globals.getNow(), modal: true})}>
                    <AddIcon/>
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.status.map((row, i) => (
                <TableRow
                  key={'status' + row.id}
                  sx={{'td': {borderRight: 1}}}
                >
                  <TableCell align="right">
                    <Box component={'img'} src={row.icon.img}/>
                  </TableCell>
                  <TableCell align="right">{row.type === 0 ? 'Status' : 'News'}</TableCell>
                  <TableCell align="right">{row.tag.name}</TableCell>
                  <TableCell align="right">{row.competition.name}</TableCell>
                  <TableCell align="right">{globals.datetimeToGerman(row.datum)}</TableCell>
                  <TableCell align="right">{row.user.name}</TableCell>

                  <TableCell width={30} align="right">
                    <IconButton aria-label="delete" sx={{backgroundColor: '#da8a22'}}
                                onClick={() => {
                                  if(row.type === 0)
                                    editStatus(i)
                                  else
                                    navigate("/search/news/" + row.news_id)
                                }}>
                      <EditIcon/>
                    </IconButton>
                  </TableCell>
                  <TableCell width={30} align="right">
                    {row.type === 0 ?
                      <IconButton aria-label="delete" sx={{backgroundColor: '#ba0000'}}
                                  onClick={() => deleteStatus(i)}>
                        <DeleteIcon/>
                      </IconButton>
                      : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Info message={"Server meldet: " + message} open={info}
              cancel={() => setInfo(false)}/>
        <Modal
          open={modal.modal}
          onClose={() => setModal({...modal, modal: false})}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 900,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}>
            <Grid container alignItems={'stretch'} direction={'column'} spacing={2}>
              <Grid item sm>
                <Iconlist onChange={changeIcon} id={modal.icon.id} conf={{
                  rowcount: 3,
                  imgsize: 'icon',
                  filter: [22, 23, 12, 2, 10, 15, 5, 6, 7, 11, 13]
                }}/>
              </Grid>
              <Grid item sm>
                <Box display={'flex'} flexDirection={'row'}>
                  <DateTimePicker
                    label="Datum"
                    value={modal.datum}
                    onChange={(newValue) => changeField(newValue, 'datum')}
                    renderInput={(params) => <TextField fullWidth {...params} InputLabelProps={{shrink: true}}/>}
                    mask={'__.__.____'}
                  />
                  <FormControl fullWidth sx={{ml: 1}}>
                    <InputLabel shrink>Wettbewerb</InputLabel>
                    <Select
                      disabled={[5, 6, 7].indexOf(modal.icon.id) === -1}
                      input={<OutlinedInput notched label={'Wettbewerb'}/>}
                      fullWidth
                      value={modal.competition.id}
                      onChange={(e) => changeField(e.target.value, 'competition')}
                    >
                      {competitions.map(e => <MenuItem value={e.id}>{e.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item sm>
                <Box display={'flex'} flexDirection={'row'}>
                  <TextField fullWidth sx={{mr: 1}} label="Verpasste Spiele" variant="outlined"
                             disabled={[5, 6, 7].indexOf(modal.icon.id) === -1}
                             InputLabelProps={{shrink: true}}
                             value={modal.count} onChange={(e) => changeField(e.target.value, 'count')}/>
                  <Searchbar fullWidth label={'Tag'} type={'tag'} onChange={handleTag} noImg value={{id: modal.tag.id, name: modal.tag.name}}/>
                </Box>
              </Grid>
              <Grid item sm display={'flex'} justifyContent={'center'}>
                <Button color={'success'} variant="contained"
                        endIcon={<CheckIcon/>} onClick={addStatus}>
                  Speichern
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </>
    )
  }

  if (loading)
    return loadingScreen();
  else
    return screen();
}

export default Player;
