import { configureStore } from '@reduxjs/toolkit'
import user from './reducers/user'
import page from './reducers/page'
import page_title from "./reducers/page_title";
import searchbar from "./reducers/searchbar";
import notifications from "./reducers/notifications";

export default configureStore({
  reducer: {
    user: user,
    page: page,
    page_title: page_title,
    searchbar: searchbar,
    notifications: notifications
}
})
