import React, { useEffect, useRef, useState, Fragment } from 'react';
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, usePrompt, useSearchParams } from "react-router-dom";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import StarIcon from '@mui/icons-material/Star';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RemoveIcon from '@mui/icons-material/Remove';
import PublishIcon from '@mui/icons-material/Publish';
import DownloadIcon from '@mui/icons-material/Download';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SaveIcon from '@mui/icons-material/Save';
import {
  TextField,
  Grid,
  Divider,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput, Snackbar, Alert,
  Paper,
  Modal,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  ButtonGroup,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  CircularProgress, Typography, Link, useTheme
} from "@mui/material";
import * as apis from "../providers/apis";
import * as globals from "../providers/globals"
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { set as set_page_title } from "../reducers/page_title";
import { set as set_searchbar } from "../reducers/searchbar";
import Searchbar from "../components/searchbar";
import LinearProgress from "@mui/material/LinearProgress";
import CheckIcon from "@mui/icons-material/Check";
import Info from "../components/info";
import Yesno from "../components/yesno";

import { FilePond, File, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const LoadingScreen = () => {
  return (
    <Box sx={{ mt: 7, width: '100%' }}>
      <LinearProgress />
    </Box>
  )
}

function Images() {

  const [files, setFiles] = useState([])
  const [settings, setSettings] = useState({ provider: 'imagoimages', copyright: '' })
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [info, setInfo] = useState(false);
  const [images, setImages] = useState([]);
  const [searching, setSearching] = useState(false);
  const [tagsearch, setTagsearch] = useState({ name: '', id: '', img: '' });
  const loading = useRef(false);
  const page = useRef(1);
  const searchstr = useRef('')
  const [loadmoref, setLoadmoref] = useState(true)
  const [selected, setSelected] = useState({ img: '', index: 0, open: false, prompt: false });
  const dispatch = useDispatch();
  let pond;

  useEffect(() => {
    search()
    dispatch(set_page_title('Bilder'))
  }, [])

  const search = (e) => {
    page.current = 1;
    setSearching(true)
    let search = e ? e.target.value : '';
    setImages([]);
    apis.imagesget({ search: search, limit: 50 })
      .then((response) => response.json())
      .then((json) => {
        setSearching(false)
        setImages(json.images)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const loadmore = () => {
    if (loading.current)
      return false;
    page.current++
    loading.current = true;
    apis.imagesget({ search: searchstr.current.value, page: page.current, limit: 50 })
      .then((response) => response.json())
      .then((json) => {
        loading.current = false
        setSearching(false)
        setImages([...images, ...json.images])
        if (json.images.length === 0)
          setLoadmoref(false)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const openImg = (img, i) => {
    setSelected({ img: img, index: i, open: true })
  }

  const addTag = (v) => {
    setTagsearch({ name: '', id: '', img: '' })
    if (v == null || v.id === '') {
      return false
    }
    let d = [ ...selected.img.tags ];
    let c = false;
    d.forEach(e => {
      if (e.value === v.id && e.type === v.type)
        c = true;
    })
    if (c) return false;
    let x = { id: v.id, name: v.name, type: v.type }
    d.push(x);
    setSelected({...selected, img: {...selected.img, tags: d}});
  }

  const removeTag = (id) => {
    let d = [ ...selected.img.tags ];
    d.forEach((e, i) => {
      if (e.id === id)
        d.splice(i, 1)
    })
    setSelected({...selected, img: {...selected.img, tags: d}});
  }

  const confirmDel = () => {
    let d = [...images]
    d.splice(selected.index, 1)
    setImages(d)
    setSelected({ ...selected, open: false, prompt: false })
    apis.imagedelete(selected.img.id)
      .then((response) => response.json())
      .then((json) => {
        if (json.code === 0) {
          setMessage(json.msg)
          setInfo(true)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <Yesno message={"Bild wirklich löschen?"} open={selected.prompt} accept={confirmDel}
        cancel={() => setSelected({ ...selected, prompt: false })} />
      <Modal
        open={selected.open}
        onClose={() => setSelected({ ...selected, open: false })}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Box component={'img'} src={selected.img.src}/>
            <Box sx={{display: 'flex', flexDirection: 'row', mt: '20px'}}>
              <FormControl sx={{ minWidth: '200px', mr: '20px' }}>
                <InputLabel shrink>Provider</InputLabel>
                <Select
                    input={<OutlinedInput notched label={'Provider'} />}
                    fullWidth
                    id="art"
                    value={selected.img.provider}
                    onChange={(e) => setSelected({ ...selected, img: {...selected.img, provider: e.target.value} })}
                >
                  <MenuItem value={'imagoimages'}>imagoimages</MenuItem>
                  <MenuItem value={'gettyimages'}>gettyimages</MenuItem>
                  <MenuItem value={'LigaInsider'}>LigaInsider</MenuItem>
                </Select>
              </FormControl>
              <TextField label="Copyright" variant="outlined"
                         InputLabelProps={{shrink: true}} inputProps={{autoComplete: 'off'}}
                         value={selected.img.copyright} onChange={(e) => setSelected({ ...selected, img: {...selected.img, copyright: e.target.value} })}/>
              <Box sx={{ml: '20px'}}>
                <IconButton sx={{backgroundColor: '#ba0000'}}
                            onClick={() => setSelected({ ...selected, prompt: true })}>
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box sx={{ml: '20px'}}>
                <IconButton sx={{backgroundColor: '#00ba83'}}
                            onClick={() => {
                              apis.imageset(selected.img)
                              setSelected({...selected, open: false})
                              let d = {...images[selected.index]}
                              d.tags = [...selected.img.tags]
                              d.provider = selected.img.provider
                              d.copyright = selected.img.copyright
                              let n = [...images]
                              n[selected.index] = d
                              setImages(n)
                            }}>
                  <SaveIcon />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', mt: '20px'}}>
              <Searchbar value={tagsearch} noImg label={'Tags'} type={'spieler-team-tag'}
                         customInputProps={{ style: { padding: 0 } }} sx={{ width: '300px' }}
                         onChange={addTag} />
              <Box sx={{ mt: '20px' }}>
                {selected.img.tags && selected.img.tags.map(e => (
                    <Box
                        sx={{ display: 'inline-block', borderRadius: '14px', backgroundColor: 'gray', pl: '10px', m: '5px' }}>
                      <Box sx={{ display: 'inline-block' }} component={'span'}>{e.name}</Box>
                      <IconButton size={'small'} sx={{ position: 'inline-block', top: 0, right: 0 }} aria-label="close"
                                  onClick={() => removeTag(e.id)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Info message={"Server meldet: " + message} open={info}
        cancel={() => setInfo(false)} />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={success}
        onClose={() => setSuccess(false)}
        autoHideDuration={4000}
        key={'playersnack'}>
        <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
          Hochladen erfolgreich!
        </Alert>
      </Snackbar>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', m: '20px' }}>
        <Box sx={{ width: '100%' }}>
          <FilePond
            server={{
              url: 'https://hossidev.ligainsider.de/beapi/imageupload/',
              process: {
                url: './',
                ondata: (formData) => {
                  formData.append('settings', JSON.stringify(settings));
                  return formData;
                },
                onload: (data) => {
                  const d = JSON.parse(data)
                  if (d.code === 1) {
                    setSuccess(true);
                    setFiles([])
                  } else {
                    setMessage(d.msg)
                    setInfo(true)
                  }
                },
                onerror: () => {
                  setMessage('Unbekannter Fehler')
                  setInfo(true)
                }
              },
              method: 'POST'
            }}
            ref={(ref) => (pond = ref)}
            files={files}
            onupdatefiles={setFiles}
            acceptedFileTypes={["image/png", "image/jpeg"]}
            allowMultiple={true}
            instantUpload={false}
            allowProcess={false}
            name="filepond"
            labelIdle='Drag & Drop die Bilder oder <span class="filepond--label-action">Browse</span>'
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <FormControl sx={{ mr: '20px' }}>
            <InputLabel shrink>Provider</InputLabel>
            <Select
              input={<OutlinedInput notched label={'Provider'} />}
              fullWidth
              id="art"
              value={settings.provider}
              onChange={(e) => setSettings({ ...settings, provider: e.target.value })}
            >
              <MenuItem value={'imagoimages'}>imagoimages</MenuItem>
              <MenuItem value={'gettyimages'}>gettyimages</MenuItem>
              <MenuItem value={'LigaInsider'}>LigaInsider</MenuItem>
            </Select>
          </FormControl>
          <TextField label="Copyright" variant="outlined" sx={{ mr: '20px' }}
            InputLabelProps={{ shrink: true }}
            inputProps={{ autoComplete: 'off' }}
            value={settings.copyright}
            onChange={(e) => setSettings({ ...settings, copyright: e.target.value })} />
          <Button variant={'contained'} onClick={() => pond.processFiles()}>Hochladen</Button>
        </Box>
        <Divider sx={{ p: '20px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', p: '10px', alignItems: 'center' }}>
            <LibraryBooksIcon sx={{ mr: '10px' }} />
            <Typography variant={'h5'}>Bibliothek</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <TextField label="Suche" variant="outlined" sx={{ minWidth: '400px' }}
              InputLabelProps={{ shrink: true }}
              inputProps={{ autoComplete: 'off' }}
              inputRef={searchstr}
              onChange={search} />
          </Box>

          {searching ?
            <Box sx={{ width: '100%', minHeight: '400px' }}>
              <LinearProgress />
            </Box>
            :
            <Box sx={{ '>*': { m: '10px' }, minHeight: '400px', overflow: 'auto' }}>
              {images.map((img, i) => (
                <Box sx={{ width: '200px', height: '150px', overflow: 'auto', display: 'inline-block', cursor: 'pointer', border: "2px solid black", borderRadius: "5px", boxShadow: 20 }} onClick={() => openImg(img, i)}>
                  <Box key={img.vid} component={'img'} src={img.src} sx={{ maxWidth: '100%', height: '60%' }} />
                  <Typography sx={{ ml: 1, wordWrap: "break-word", fontSize: "12px", fontWeight: 600 }} >{img.src.substr(img.src.lastIndexOf('/') + 1)}</Typography>
                </Box>
              ))}
            </Box>
          }
          {loadmoref ? <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button sx={{ width: '200px' }} variant={'contained'} onClick={loadmore}>Mehr laden</Button>
          </Box> : null}

        </Box>
      </Box>
    </>
  )
}
//
export default Images;
