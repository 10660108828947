import React, { useEffect, useRef, useState } from 'react';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { set as set_user } from '../reducers/user'
import { TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import * as apis from "../providers/apis";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux'
import { set as set_page_title } from "../reducers/page_title";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

function Administration() {
    const dispatch = useDispatch();
    dispatch(set_page_title('User löschen - KEINE DEV-SEITE - ÄNDERUNGEN SIND LIVE'))

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState();
    const [deleteUser, setDeleteUser] = useState({ id: '', username: '', name: '', type: '' });
    const [openSnack, setOpenSnack] = useState();
    const [alertText, setAlertText] = useState('');
    const [snackSuccess, setSnackSuccess] = useState('success');

    const handleDeleteUser = () => {
        setOpenModalDelete(false);
        if (deleteUserId == deleteUser.id) {
            apis
                .userdelete(deleteUser.id)
                .then((response) => response.json())
                .then((json) => {
                    if (json.result == 1) {
                        setAlertText('User: ' + deleteUser.id + ' gelöscht.');
                        setSnackSuccess('success');
                        setOpenSnack(true);
                    } else {
                        setAlertText('Fehler: ' + deleteUser.id + ' nicht gelöscht.');
                        setSnackSuccess('error');
                        setOpenSnack(true);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            setAlertText('Fehler: ' + deleteUser.id + ' nicht gelöscht.');
            setSnackSuccess('error');
            setOpenSnack(true);
        }
    };

    const openModal = () => {
        if (deleteUserId != null) {
            apis
                .userget1(deleteUserId)
                .then((response) => response.json())
                .then((json) => {
                    if (json.id != null) {
                        setDeleteUser(json)
                        setOpenModalDelete(true)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    const closeModal = () => {
        setOpenModalDelete(false)
    }

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: '10px' }}>
                <Typography variant={'h5'}>User löschen:</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: '10px' }}>
                    <TextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onChange={(e) => setDeleteUserId(e.target.value)} placeholder="UserID eingeben" />
                    <Button variant={'contained'} onClick={openModal} color={'error'}>Löschen</Button>
                </Box>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={openSnack}
                onClose={() => setOpenSnack(false)}
                autoHideDuration={2000}
                key={'playersnack'}>
                <Alert onClose={() => setOpenSnack(false)} severity={snackSuccess} sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>
            <Modal
                open={openModalDelete}
                onClose={closeModal}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '80vh',
                    overflow: 'auto'
                }}>
                    <Box display={'flex'} flexDirection={'column'} sx={{ '>*': { m: '10px' } }}>
                        <Box>
                            <Box>ID: {deleteUser.id}</Box>
                            <Box>Name: {deleteUser.name}</Box>
                            <Box>Username: {deleteUser.username != null ? deleteUser.username : <Box sx={{ fontStyle: 'italic' }}>(unbekannt)</Box>}
                            </Box>
                            <Box>Type: {deleteUser.type}</Box>
                            <Typography variant={'h5'}>{'Diesen User unwiderruflich löschen?'}</Typography>
                        </Box>
                        <Button variant={'contained'} onClick={handleDeleteUser} color={'error'}>Löschen</Button>
                    </Box>
                </Box>
            </Modal>


        </Box>
    );
}

export default Administration;
