import React, {useEffect, useRef, useState, Fragment} from 'react';
import {
  Box,
  Paper,
  TextField,
  Button,
  Divider,
  Autocomplete,
  Avatar,
  Select,
  MenuItem,
  Icon,
  InputLabel,
  FormControl,
  FormGroup,
  Switch,
  OutlinedInput,
  Snackbar,
  Alert, CircularProgress
} from "@mui/material";
import FacebookLogin from 'react-facebook-login';
import * as apis from "../providers/apis";
import * as globals from "../providers/globals"
import {set as set_user} from "../reducers/user";
import {useDispatch} from "react-redux";
import {set as set_page} from "../reducers/page";

function Auth() {

  const urlParams = new URLSearchParams(window.location.search);
  const [login, setLogin] = useState({username: '', password: ''});
  const token = urlParams.get('token')
  const sso = urlParams.get('sso')
  const dispatch = useDispatch();

  if (sso != null) {
    apis
      .auth('kickbase', {sso: sso})
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.code === 1) {
          globalThis.user = json.data.user
          setTimeout(() => {
            dispatch(set_user(json.data.user))
          }, 50)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const fbCallback = (v) => {
    apis
      .auth('facebook', {fb_token: v.accessToken})
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.code === 1) {
          globalThis.user = json.data.user
          setTimeout(() => {
            dispatch(set_user(json.data.user))
          }, 50)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const doLogin = () => {
    apis
      .auth('ligainsider', {username: login.username, password: login.password})
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.code === 1) {
          globalThis.user = json.data.user
          setTimeout(() => {
            dispatch(set_user(json.data.user))
          }, 50)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <Box sx={{height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Paper elevation={10}
             sx={{width: '500px', p: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <TextField InputLabelProps={{shrink: true}} label={'Username'} fullWidth value={login.username} onChange={(e) => setLogin({...login, username: e.target.value})}/>
        <TextField type={'password'} InputLabelProps={{shrink: true}} label={'Passwort'} sx={{mt: '20px'}} fullWidth value={login.password}
                   onChange={(e) => setLogin({...login, password: e.target.value})}/>
        <Button sx={{mt: '20px', mb: '20px'}} color={'info'} variant={'contained'} fullWidth onClick={doLogin}>Login</Button>
        <Divider sx={{width: '100%'}}/>
        <Box sx={{mt: '20px'}}>
          <FacebookLogin
            render={renderProps => (
              <Button onClick={renderProps.onClick}>This is my custom FB button</Button>
            )}
            appId="1881674728765353"
            fields="name,email,picture"
            callback={fbCallback}
            cssClass="my-facebook-button-class"
            icon="fa-facebook"
          />
        </Box>
        <Button sx={{mt: '20px'}} color={'success'} variant={'contained'} fullWidth
                onClick={() => window.location.href = 'https://play.kickbase.com/connect/ligainsider/?ref=newbackend&callback=hossidev'}>Kickbase</Button>
      </Paper>
    </Box>
  )
}

export default Auth;
