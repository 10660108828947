import { createSlice } from '@reduxjs/toolkit'

export const notifications = createSlice({
  name: 'notifications',
  initialState: {
    value: 0
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { set } = notifications.actions

export default notifications.reducer
