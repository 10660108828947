export const dateToUS = (value) => {
  let date = new Date(value)
  return (date.getMonth() + 1).toString().padStart(2, 0) + '-' + date.getDate().toString().padStart(2, 0) + '-' + date.getFullYear()
}

export const datetimeToUS = (value) => {
  let date = new Date(value)
  return (date.getMonth() + 1).toString().padStart(2, 0) + '-' + date.getDate().toString().padStart(2, 0) + '-' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
}

export const dateToGerman = (value) => {
  let date = new Date(value)
  return date.getDate().toString().padStart(2, 0) + '.' + (date.getMonth() + 1).toString().padStart(2, 0) + '.' + date.getFullYear()
}

export const datetimeToGerman = (value) => {
  let date = new Date(value)
  return date.getDate().toString().padStart(2, 0) + '.' + (date.getMonth() + 1).toString().padStart(2, 0) + '.' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
}

export const datetimeToServerString = (value) => {
  let date = new Date(value);
  return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, 0) + '-' + date.getDate().toString().padStart(2, 0) + ' ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ':00'
}

export const getNow = () => {
  let date = new Date()
  return (date.getMonth() + 1).toString().padStart(2, 0) + '-' + date.getDate().toString().padStart(2, 0) + '-' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
}

export const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export const weekday = (value) => {
  let date = new Date(value)
  let day = date.getDay();
  switch (day) {
    case 1: return 'Mo.'
    case 2: return 'Di.'
    case 3: return 'Mi.'
    case 4: return 'Do.'
    case 5: return 'Fr.'
    case 6: return 'Sa.'
    case 7: return 'So.'
    default: return '';
  }
}

// globals
export var IMG_URL = 'https://cdn.ligainsider.de/';
