import React, { useEffect, useState, useRef } from 'react';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import LinearProgress from "@mui/material/LinearProgress";
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { useDispatch } from 'react-redux'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Rating from '@mui/material/Rating';

import { set as set_page_title } from "../reducers/page_title";
import * as apis from "../providers/apis";
import * as globals from "../providers/globals"

function PlayerPositionTable() {
    const dispatch = useDispatch();
    dispatch(set_page_title('Spielerpositionen - KEINE DEV-SEITE - ÄNDERUNGEN SIND LIVE'))

    const [rows, setRows] = useState(0)
    const data = useRef(0);
    const [teams, setTeams] = useState(0)
    const [teamSelected, setTeamSelected] = useState(14)
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        apis.
            playerpositiontableget("teams", 14)
            .then((response) => response.json())
            .then((json) => {
                setTeams(json);
            })

        apis.
            playerpositiontableget("teamplayer", 14)
            .then((response) => response.json())
            .then((json) => {
                setRows(json);
                data.current = json;
            })
    }, [])

    const handleChangeTeam = (event) => {
        setTeamSelected(event.target.value);
        apis.
            playerpositiontableget("teamplayer", event.target.value)
            .then((response) => response.json())
            .then((json) => {
                setRows(json);
                data.current = json;
            })
    };

    const updateRef = (row, index) => {
        data.current[index] = row;
    }

    const handleSubmit = () => {
        if (data.current.length > 0) {
            apis.
                playerpositiontableset(data.current)
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                    setSuccess(true)
                })
        }
    }

    const TablePositionMinutesRow = (props) => {
        const [row, setRow] = useState(0);

        useEffect(() => {
            setRow(props.row)
        }, [props.row])

        const handleChangeSlider = (value) => {
            let copy = { ...row };
            copy.share = value;
            setRow(copy);;
            props.onChange(copy);
        };

        const handleCheckbox = (position) => {
            let copy = { ...row };
            copy[position] = copy[position] == 1 ? 0 : 1;
            setRow(copy);
            props.onChange(copy);
        };

        const handleChangeMain = (event) => {
            let copy = { ...row };
            copy.main = event.target.value;
            setRow(copy);
            props.onChange(copy);
        };

        if (row !== 0)
            return (
                <TableRow>
                    <TableCell>{row.displayname}</TableCell>
                    <TableCell align="center">
                        <Box width="200px">
                            <Slider
                                width="200px"
                                valueLabelDisplay="auto"
                                defaultValue={row.share}
                                marks
                                step={10}
                                min={0}
                                max={90}
                                onChangeCommitted={(event, value) => handleChangeSlider(value)}
                            />
                        </Box>
                    </TableCell>
                    <TableCell align="center">
                        <Select
                            align="center"
                            labelId="team-select-1"
                            id="team-select"
                            size="small"
                            value={row.main}
                            onChange={event => handleChangeMain(event)}
                        >
                            <MenuItem value={"NA"}>{"NA"}</MenuItem>
                            <MenuItem value={"TH"}>{"TH"}</MenuItem>
                            <MenuItem value={"RV"}>{"RV"}</MenuItem>
                            <MenuItem value={"IVR"}>{"IVR"}</MenuItem>
                            <MenuItem value={"IVM"}>{"IVM"}</MenuItem>
                            <MenuItem value={"IVL"}>{"IVL"}</MenuItem>
                            <MenuItem value={"LV"}>{"LV"}</MenuItem>
                            <MenuItem value={"RS"}>{"RS"}</MenuItem>
                            <MenuItem value={"LS"}>{"LS"}</MenuItem>
                            <MenuItem value={"DM"}>{"DM"}</MenuItem>
                            <MenuItem value={"ZM"}>{"ZM"}</MenuItem>
                            <MenuItem value={"RA"}>{"RA"}</MenuItem>
                            <MenuItem value={"OM"}>{"OM"}</MenuItem>
                            <MenuItem value={"LA"}>{"LA"}</MenuItem>
                            <MenuItem value={"ST"}>{"ST"}</MenuItem>
                        </Select>
                    </TableCell>
                    <TableCell align="center"><Checkbox checked={row.TH === 1} onChange={() => handleCheckbox('TH')} /> </TableCell>
                    <TableCell align="center"><Checkbox checked={row.RV === 1} onChange={() => handleCheckbox('RV')} /> </TableCell>
                    <TableCell align="center"><Checkbox checked={row.IVR === 1} onChange={() => handleCheckbox('IVR')} /> </TableCell>
                    <TableCell align="center"><Checkbox checked={row.IVM === 1} onChange={() => handleCheckbox('IVM')} /> </TableCell>
                    <TableCell align="center"><Checkbox checked={row.IVL === 1} onChange={() => handleCheckbox('IVL')} /> </TableCell>
                    <TableCell align="center"><Checkbox checked={row.LV === 1} onChange={() => handleCheckbox('LV')} /> </TableCell>
                    <TableCell align="center"><Checkbox checked={row.RS === 1} onChange={() => handleCheckbox('RS')} /> </TableCell>
                    <TableCell align="center"><Checkbox checked={row.LS === 1} onChange={() => handleCheckbox('LS')} /> </TableCell>
                    <TableCell align="center"><Checkbox checked={row.DM === 1} onChange={() => handleCheckbox('DM')} /> </TableCell>
                    <TableCell align="center"><Checkbox checked={row.ZM === 1} onChange={() => handleCheckbox('ZM')} /> </TableCell>
                    <TableCell align="center"><Checkbox checked={row.RA === 1} onChange={() => handleCheckbox('RA')} /> </TableCell>
                    <TableCell align="center"><Checkbox checked={row.OM === 1} onChange={() => handleCheckbox('OM')} /> </TableCell>
                    <TableCell align="center"><Checkbox checked={row.LA === 1} onChange={() => handleCheckbox('LA')} /> </TableCell>
                    <TableCell align="center"><Checkbox checked={row.ST === 1} onChange={() => handleCheckbox('ST')} /> </TableCell>
                </TableRow>
            )
        else return null;
    }


    if (teams !== 0) {
        return (
            <Box sx={{ width: '100%' }}>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={success}
                    onClose={() => setSuccess(false)}
                    autoHideDuration={2000}
                    key={'playersnack'}>
                    <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                        Gespeichert
                    </Alert>
                </Snackbar>
                <Box display={'flex'} flexDirection={'column'} sx={{ m: '10px' }}>
                    <Box display={'flex'} flexDirection={'row'} sx={{ p: 1 }} alignItems="center">
                        <Box width="300px">
                            <FormControl fullWidth>
                                <InputLabel id="label-team-select">Team:</InputLabel>
                                <Select
                                    labelId="team-select-1"
                                    id="team-select"
                                    label="Team:"
                                    value={teamSelected}
                                    onChange={handleChangeTeam}
                                >
                                    {teams.map((value, index) => {
                                        return (
                                            <MenuItem key={index + 'teamselect'} value={value.id}>{value.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ pl: 2 }}>
                            <Button variant={'contained'} onClick={handleSubmit} color={'success'}>Speichern</Button>
                        </Box>
                    </Box>
                    {teamSelected != 0 && rows.length > 0 ?
                        <TableContainer component={Paper} sx={{ overflowX: 'auto', maxHeight: 900 }}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Spieler</TableCell>
                                        <TableCell align="center">Anteil</TableCell>
                                        <TableCell align="center">Main</TableCell>
                                        <TableCell align="center" >TH</TableCell>
                                        <TableCell align="center">RV</TableCell>
                                        <TableCell align="center">IVR</TableCell>
                                        <TableCell align="center">IVM</TableCell>
                                        <TableCell align="center">IVL</TableCell>
                                        <TableCell align="center">LV</TableCell>
                                        <TableCell align="center">RS</TableCell>
                                        <TableCell align="center">LS</TableCell>
                                        <TableCell align="center">DM</TableCell>
                                        <TableCell align="center">ZM</TableCell>
                                        <TableCell align="center">RA</TableCell>
                                        <TableCell align="center">OM</TableCell>
                                        <TableCell align="center">LA</TableCell>
                                        <TableCell align="center">ST</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TablePositionMinutesRow key={row.playerid} row={row} onChange={(row) => updateRef(row, index)} />
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer> : <Box />}

                </Box>
            </Box >
        )
    }
    else return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>
    );
}

export default PlayerPositionTable;














