import React, {useEffect, useRef, useState, Fragment} from 'react';
import {
  TextField,
  Grid,
  Divider,
  Autocomplete,
  Avatar,
  Select,
  MenuItem,
  Icon,
  InputLabel,
  FormControl,
  OutlinedInput, Snackbar, Alert,
  Paper,
  Modal,
  IconButton,
  Box
} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import TableBody from "@mui/material/TableBody";
import * as globals from "../providers/globals";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";
import * as apis from "../providers/apis";
import {set as set_page_title} from "../reducers/page_title";
import {set as set_searchbar} from "../reducers/searchbar";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";

function Competition() {

  const params = useParams();
  const dispatch = useDispatch();
  const page = useSelector(state => state.page.value)
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let id = params.id;
    if (id != null) {
      setLoading(true);
      apis
        .competitionget(id)
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          dispatch(set_page_title(json.competition.name))
          dispatch(set_searchbar({
            name: json.competition.name,
            id: json.competition.id,
            img: json.competition.img,
            type: 'wettbewerb'
          }))
          setData(json)
          setLoading(false)
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setLoading(false)
      dispatch(set_page_title(''))
      dispatch(set_searchbar({name: '', id: '', img: '', type: 'wettbewerb'}))
    }
  }, [page])

  const font = {fontSize: 20, fontWeight: 'bold'};

  const screen = () => {
    return (
      <Box sx={{flexGrow: 1, p: 3, width: 'auto', overflow: 'hidden'}}>
        <TableContainer sx={{maxWidth: 1000, maxHeight: '700px'}} component={Paper}>
          <Table stickyHeader sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Saison</TableCell>
                <TableCell align="center">Beginn</TableCell>
                <TableCell align="center">Ende</TableCell>
                <TableCell align="center">
                  <IconButton aria-label="add" sx={{backgroundColor: '#00ba83'}} onClick={() => navigate("/search/competition/"+data.competition.id+"/season")}>
                    <AddIcon/>
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.seasons && data.seasons.map((row, i) => (
                <TableRow
                  key={'season' + row.id}
                  sx={{'td': {borderRight: 1}}}
                >
                  <TableCell sx={font} align="center">
                    {row.name}
                  </TableCell>
                  <TableCell sx={font} align="center">{row.start}</TableCell>
                  <TableCell sx={font} align="center">{row.end}</TableCell>
                  <TableCell width={30} align="center">
                    <IconButton aria-label="delete" sx={{backgroundColor: '#da8a22'}} onClick={() => navigate("/search/competition/"+data.competition.id+"/season/" + row.id)}>
                      <EditIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  if (loading)
    return loadingScreen();
  else
    return screen()
}

const loadingScreen = () => {
  return (
    <Box sx={{mt: 7, width: '100%'}}>
      <LinearProgress/>
    </Box>
  )
}

export default Competition;
