import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Box, Switch} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import React, {useEffect, useState} from "react";

const icons_all = [
  {id: 1, name: 'Einsatzchancen', img: 'startelf'},
  {id: 2, name: 'Verletzung', img: 'verletzung'},
  {id: 3, name: 'Nationalmannschaft', img: 'nationalmannschaft'},
  {id: 4, name: 'Pokal', img: 'pokalwettbewerb'},
  {id: 5, name: 'Gelb-Rote Karte', img: 'gelb-rote-karte'},
  {id: 6, name: '5. Gelbe Karte', img: 'gelbe-karte'},
  {id: 7, name: 'Rote Karte', img: 'rote-karte'},
  {id: 8, name: 'Verträge', img: 'vertrag'},
  {id: 9, name: 'Auszeichnungen', img: 'auszeichnung'},
  {id: 10, name: 'Aufbautraining', img: 'aufbautraining'},
  {id: 11, name: 'Abwesend', img: 'abwesend'},
  {id: 12, name: 'Schwerer angeschlagen', img: 'angeschlagen-down'},
  {id: 13, name: 'Nicht im Kader', img: 'verbannung'},
  {id: 14, name: 'Raus aus der Liga', img: 'exbundesliga'},
  {id: 15, name: 'Fit', img: 'fit'},
  {id: 16, name: 'Ärger', img: 'aerger'},
  {id: 17, name: 'Sonstiges', img: 'boulevard'},
  {id: 18, name: 'Gerüchte', img: 'geruechte'},
  {id: 19, name: 'Interviews', img: 'interview'},
  {id: 21, name: 'Testspiele', img: 'testspiele'},
  {id: 22, name: 'Leicht angeschlagen', img: 'angeschlagen-up'},
  {id: 23, name: 'Angeschlagen', img: 'angeschlagen-unsure'},
  {id: 25, name: 'Nicht berücksichtigt', img: 'notinvolved'},
  {id: 26, name: 'Trainingssteuerung', img: 'paused'},
]

const expandImg = (list, type = 'small') => {
  return list.map(item => {
    return {...item, img: 'https://cdn.ligainsider.de/images/icons/new/'+type + '/' + item.img + '.png'};
  })
}

const filter = (f) => {
  let r = [];
  f.forEach(e => {
    icons_all.forEach(x => {
      if(x.id === e)
        r.push(x)
    })
  })
  return r
}

export default function Iconlist(props) {
  let conf = props.conf ?? {};
  conf.filter = conf.filter ?? [15];
  conf.rowcount = conf.rowcount ?? 3;
  conf.imgsize = conf.imgsize ?? 'icon';
  const id = props.id ?? null;
  const onChange = props.onChange ?? null;
  const [res, setRes] = useState([]);

  const icons = expandImg(conf.filter ? filter(conf.filter) : icons_all, conf.imgsize)

  useEffect(() => {
    let temp2 = [];
    let temp = [];
    let list = [...icons];
    while(list.length){
      if(temp.length < conf.rowcount)
        temp.push(list.shift())
      else{
        temp2.push(temp)
        temp = []
      }
    }
    if(temp.length)
      temp2.push(temp)
    setRes(temp2);
  }, [conf.rowcount])

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {res.map((row, i) => (
            <TableRow
              key={'icons' + i}
              sx={{'td': {borderRight: 1}}}
            >
              {row.map((col, j) => (
                <TableCell align="left" sx={{padding: 0}}>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Switch checked={id === col.id}
                            onChange={onChange ? () => onChange(col) : null}/>
                    <Box sx={{mr: '8px', ml: '8px'}} height={'20px'} component={'img'} src={col.img}/>
                    <span>{col.name}</span>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )

}
