import React, {useEffect, useRef, useState, Fragment} from 'react';
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams, usePrompt, useSearchParams} from "react-router-dom";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import StarIcon from '@mui/icons-material/Star';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RemoveIcon from '@mui/icons-material/Remove';
import PublishIcon from '@mui/icons-material/Publish';
import DownloadIcon from '@mui/icons-material/Download';
import {
  TextField,
  Grid,
  Divider,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput, Snackbar, Alert,
  Paper,
  Modal,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  ButtonGroup,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  CircularProgress, Typography, Link, useTheme
} from "@mui/material";
import * as apis from "../providers/apis";
import * as globals from "../providers/globals"
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import {set as set_page_title} from "../reducers/page_title";
import {set as set_searchbar} from "../reducers/searchbar";
import Searchbar from "../components/searchbar";
import LinearProgress from "@mui/material/LinearProgress";
import CheckIcon from "@mui/icons-material/Check";
import Info from "../components/info";
import Yesno from "../components/yesno";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const LoadingScreen = () => {
  return (
    <Box sx={{mt: 7, width: '100%'}}>
      <LinearProgress/>
    </Box>
  )
}

function Newslist() {

  const [data, setData] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [loading, setLoading] = useState(true);
  const working = useRef(false)
  const dispatch = useDispatch();
  const [error, setError] = useState({msg: '', open: false})

  const [dirty, setDirty] = useState(false)
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const p = useRef(1)
  const theme = useTheme();

  useEffect(() => {
    dispatch(set_page_title('News'))
    load()
  }, [])

  const load = () => {
    if (working.current)
      return false;
    setLoading(true)
    working.current = true;
    apis
      .newslistget(p.current)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setData([...data, ...json.news])
        setFeatured([...featured, ...json.featured])
        p.current++;
        setLoading(false)
        working.current = false;
      })
      .catch((error) => {
        console.error(error);
        working.current = false;
      });
  }

  const check = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 200;
    if (bottom) {
      load()
    }
  }

  const handleFeatured = (news) => {
    if (featured.length >= 3) {
      setError({msg: 'Es sind bereits 3 News ausgewählt', open: true})
      return false;
    }
    let found = false
    featured.forEach(e => {
      if (e.id === news.id) {
        found = true
        return false;
      }
    })
    if (found)
      return false
    setFeatured([...featured, news]);
    setDirty(true)
  }

  const submit = () => {
    setSubmitting(true)
    apis
      .startpageset({news: featured})
      .then((response) => response.json())
      .then(() => {
        setSubmitting(false)
        setSuccess(true)
        setDirty(false)
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false)
      });
  }

  usePrompt(
    "Änderungen wirklich verwerfen?",
    dirty
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        open={success}
        onClose={() => setSuccess(false)}
        autoHideDuration={4000}>
        <Alert onClose={() => setSuccess(false)} severity="success" sx={{width: '100%'}}>
          Speichern erfolgreich!
        </Alert>
      </Snackbar>
      {submitting ? <Button sx={{position: 'fixed', bottom: 10, right: 10, visibility: dirty ? 'visible' : 'hidden'}}
                         color={'success'} variant="contained">
        <CircularProgress size={14} sx={{color: 'black'}} />
      </Button> : <Button sx={{position: 'fixed', bottom: 10, right: 10, visibility: dirty ? 'visible' : 'hidden'}}
                          color={'success'} variant="contained"
                          endIcon={<CheckIcon/>} onClick={submit}>
        Speichern
      </Button>}

      <Dialog
        open={error.open}
        onClose={() => setError({msg: '', open: false})}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{backgroundColor: 'darkblue'}} onClick={() => setError({msg: '', open: false})} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{height: 'calc(100vh - 64px)', width: '100%', overflow: 'scroll'}} onScroll={check}>
        <Box sx={{width: '90%', mt: '40px', pb: '200px'}}>
          <Divider sx={{m: '20px'}} textAlign={'left'}>Featured</Divider>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            {featured.map((news, i) => (
              <>
                <Box key={news.id} sx={{display: 'flex', flexDirection: 'row'}}>
                  <a href={'/search/news/' + news.id}
                     style={{
                       color: theme.palette.text.primary,
                       textDecoration: 'initial',
                       cursor: 'pointer',
                       flexGrow: 1
                     }}>
                    <Box sx={{flexDirection: 'row', display: 'flex', mb: '20px'}}>
                      <Box component={'img'} sx={{width: '300px'}} src={news.image}/>
                      <Box sx={{display: 'flex', flexDirection: 'column', ml: '20px', flexGrow: 1}}>
                        <Typography variant={'h5'}>{news.title}</Typography>
                        <Box dangerouslySetInnerHTML={{__html: news.text}}/>
                      </Box>
                    </Box>
                  </a>
                  <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <IconButton sx={{backgroundColor: '#f51a1a'}}
                                onClick={() => {
                                  let f = [...featured]
                                  f.splice(i, 1)
                                  setFeatured(f)
                                  setDirty(true)
                                }}>
                      <RemoveIcon/>
                    </IconButton>
                  </Box>
                </Box>
                <Divider sx={{mb: '20px'}}/>
              </>
            ))}
          </Box>
          <Divider sx={{m: '20px'}}/>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            {data.map(news => (
              <>
                <Box key={news.id} sx={{display: 'flex', flexDirection: 'row'}}>
                  <a href={'/search/news/' + news.id}
                     style={{
                       color: theme.palette.text.primary,
                       textDecoration: 'initial',
                       cursor: 'pointer',
                       flexGrow: 1
                     }}>
                    <Box sx={{flexDirection: 'row', display: 'flex', mb: '20px'}}>
                      <Box component={'img'} sx={{width: '300px'}} src={news.image}/>
                      <Box sx={{display: 'flex', flexDirection: 'column', ml: '20px', flexGrow: 1}}>
                        <Typography variant={'h5'}>{news.title}</Typography>
                        <Box dangerouslySetInnerHTML={{__html: news.text}}/>
                      </Box>
                    </Box>
                  </a>
                  <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <IconButton sx={{backgroundColor: '#00ba83'}}
                                onClick={() => handleFeatured(news)}>
                      <AddIcon/>
                    </IconButton>
                  </Box>
                </Box>
                <Divider sx={{mb: '20px'}}/>
              </>
            ))}
          </Box>
          {loading ? <LoadingScreen/> : null}
        </Box>
      </Box>
    </>
  )

}

export default Newslist;
