import React, {useEffect, useRef, useState, Fragment} from 'react';
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams, usePrompt, useSearchParams} from "react-router-dom";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import StarIcon from '@mui/icons-material/Star';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RemoveIcon from '@mui/icons-material/Remove';
import PublishIcon from '@mui/icons-material/Publish';
import DownloadIcon from '@mui/icons-material/Download';
import {
  TextField,
  Grid,
  Divider,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput, Snackbar, Alert,
  Paper,
  Modal,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  ButtonGroup,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  CircularProgress, Typography, Link, useTheme
} from "@mui/material";
import * as apis from "../providers/apis";
import * as globals from "../providers/globals"
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import {set as set_page_title} from "../reducers/page_title";
import {set as set_searchbar} from "../reducers/searchbar";
import Searchbar from "../components/searchbar";
import LinearProgress from "@mui/material/LinearProgress";
import CheckIcon from "@mui/icons-material/Check";
import Info from "../components/info";
import Yesno from "../components/yesno";

const LoadingScreen = () => {
  return (
    <Box sx={{mt: 7, width: '100%'}}>
      <LinearProgress/>
    </Box>
  )
}

function Alerts() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    dispatch(set_page_title('Alerts'))
    load()
  }, [])

  const load = () => {
    setLoading(true)
    apis
      .alertsget()
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setData(json)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
        <Box sx={{width: '90%', mt: '40px', pb: '200px'}}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            {data.map((alert, i) => (
              <>
                <a key={i} href={alert.url}
                   style={{color: theme.palette.text.primary, textDecoration: 'initial', cursor: 'pointer'}}>
                  <Box sx={{flexDirection: 'row', display: 'flex', pt: '5px', pb: '5px'}}>
                    <Box sx={{flex: 3, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <Avatar sx={{mr: '5px', height: '60px', width: '60px'}} src={alert.team.image}/>
                      <Typography variant={'h5'}>{alert.team.name}</Typography>
                    </Box>
                    <Box sx={{flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      {alert.player != null ?
                        <>
                          <Avatar sx={{mr: '5px', height: '60px', width: '60px'}} src={alert.player.image}/>
                          <Box sx={{display: 'flex', flexDirection: 'column'}}>
                          <span style={{fontSize: 12}}>{alert.player.firstname}</span>
                            <span>{alert.player.lastname}</span>
                          </Box>
                        </>
                        : null}
                    </Box>
                    <Box sx={{flex: 6, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      {alert.player != null ? <Avatar sx={{mr: '5px', height: '60px', width: '60px'}} src={alert.player.icon}/> : null}
                      <Typography variant={'h5'}>{alert.message}</Typography>
                    </Box>
                  </Box>
                </a>
              </>
            ))}
          </Box>
          {loading ? <LoadingScreen/> : null}
        </Box>
    </>
  )

}

export default Alerts;
