import { createSlice } from '@reduxjs/toolkit'

export const page = createSlice({
  name: 'page',
  initialState: {
    value: 0
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { set } = page.actions

export default page.reducer
