import * as apis from "../providers/apis";
import React, {useEffect, useState} from "react";
import {FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";

export default function Gamelist(props) {

  const newState = {selectboxes: {competitions: [], seasons: [], tms: []},
    selected: {competitions: '', seasons: '', tms: ''}}
  const [data, setData] = useState({...newState})
  const [disabled, setDisabled] = React.useState({seasons: false, tms: false});
  const id = props.teamid ?? null;
  const tmid = props.tmid ?? null;

  const onChange = props.onChange;

  useEffect(() => {
    if(id === '' && tmid == null)
      setData({...newState})
    else
      apis
        .gamelist({id: id, tmid: tmid})
        .then((response) => response.json())
        .then((json) => {
          setDisabled({seasons: false, tms: false});
          setData(json)
          if(tmid != null) {
            onChange(tmid);
          }else
            onChange(json.selectboxes.competitions[0].goto.tmid)
        })
        .catch((error) => {
          console.error(error);
        });
  }, [tmid, id])

  const redirectVA = (v, type) => {
    let d = {...data};
    let t_tmid = null;
    if (type === 'competition') {
      setDisabled({seasons: true, tms: true});
      data.selectboxes.competitions.forEach((e, i) => {
        if (v === e.id) {
          d.selected.competitions = e.id;
          d.selectboxes.seasons = [{name: e.goto.season, id: e.goto.seasonid}]
          d.selectboxes.tms = [{name: e.goto.playday, id: e.goto.tmid}]
          d.selected.seasons = e.goto.seasonid;
          d.selected.tms = e.goto.tmid;
          t_tmid = e.goto.tmid;
        }
      })
    } else if (type === 'season') {
      setDisabled({seasons: false, tms: true});
      data.selectboxes.seasons.forEach((e, i) => {
        if (v === e.id) {
          d.selected.seasons = e.id;
          d.selectboxes.tms = [{name: e.goto.playday, id: e.goto.tmid}]
          d.selected.tms = e.goto.tmid;
          t_tmid = e.goto.tmid;
        }
      })
    } else if (type === 'tm') {
      d.selected.tms = v;
      t_tmid = v;
    }
    onChange(t_tmid)
    setData(d)
    apis
      .gamelist({id: id, tmid: t_tmid})
      .then((response) => response.json())
      .then((json) => {
        setDisabled({seasons: false, tms: false});
        setData(json)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <Grid container spacing={2} flexDirection={"row"}>
      <Grid item sm={4}>
        <FormControl fullWidth>
          <InputLabel shrink>Wettbewerb</InputLabel>
          <Select
            input={<OutlinedInput notched label={'Wettbewerb'}/>}
            fullWidth
            label={'Wettbewerb'}
            value={data.selected.competitions}
            onChange={(e) => redirectVA(e.target.value, 'competition')}
          >
            {data.selectboxes.competitions.map((e, i) => (
              <MenuItem key={'competition'+e.id} value={e.id}>{e.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={4}>
        <FormControl fullWidth>
          <InputLabel shrink>Saison</InputLabel>
          <Select
            input={<OutlinedInput notched label={'Saison'}/>}
            fullWidth
            label={'Saison'}
            value={data.selected.seasons}
            onChange={(e) => redirectVA(e.target.value, 'season')}
            disabled={disabled.seasons}
          >
            {data.selectboxes.seasons.map((e, i) => (
              <MenuItem key={'season'+e.id} value={e.id}>{e.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={4}>
        <FormControl fullWidth>
          <InputLabel shrink>Spieltag</InputLabel>
          <Select
            input={<OutlinedInput notched label={'Spieltag'}/>}
            fullWidth
            label={'Spieltag'}
            value={data.selected.tms}
            onChange={(e) => redirectVA(e.target.value, 'tm')}
            disabled={disabled.tms}
          >
            {data.selectboxes.tms.map((e, i) => (
              <MenuItem key={'playday'+e.id} value={e.id}>{e.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
