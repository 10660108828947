import { createSlice } from '@reduxjs/toolkit'

export const page_title = createSlice({
  name: 'page_title',
  initialState: {
    value: ''
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { set } = page_title.actions

export default page_title.reducer
