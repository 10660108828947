import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export default function Yesno(props) {

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{backgroundColor: 'darkblue'}} onClick={props.accept} autoFocus>
            Ja
          </Button>
          <Button onClick={props.cancel}>Nein</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
