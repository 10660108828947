import React, {useEffect, useRef, useState, Fragment} from 'react';
import * as apis from "../providers/apis";
import {set as set_page_title} from "../reducers/page_title";
import {set as set_searchbar} from "../reducers/searchbar";
import {useNavigate, useParams, usePrompt} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Box, CircularProgress, Grid, IconButton, Modal, Paper, Snackbar, TextField} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import DatePicker from "@mui/lab/DatePicker";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {Draggable, Droppable, DragDropContext} from 'react-beautiful-dnd';
import Searchbar from "../components/searchbar";
import Info from "../components/info";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import * as globals from "../providers/globals";

function Season() {

  const newData = {
    season: {id: '', name: '', start: null, end: null},
    teams: []
  }

  const params = useParams();
  const dispatch = useDispatch();
  const page = useSelector(state => state.page.value)
  const [disabled, setDisabled] = React.useState({seasons: false, tms: false});
  const [data, setData] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [working, setWorking] = useState(false);
  const [addteam, setAddteam] = useState(false);
  const [info, setInfo] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setDirty(false);
    let id = params.seasonid;
    if (id != null) {
      setLoading(true);
      apis
        .seasonget(id)
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          dispatch(set_page_title(json.season.name + ': Bearbeiten'))
          dispatch(set_searchbar({
            name: json.competition.name,
            id: json.competition.id,
            img: json.competition.img,
            type: 'wettbewerb'
          }))
          setData(json)
          setLoading(false)
        })
        .catch((error) => {
          console.error(error);
        });
    }else{
      setLoading(true);
      apis
        .competitionget(params.competitionid)
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          dispatch(set_page_title(json.competition.name + ': Saison anlegen'))
          dispatch(set_searchbar({
            name: json.competition.name,
            id: json.competition.id,
            img: json.competition.img,
            type: 'wettbewerb'
          }))
          setLoading(false)
        })
        .catch((error) => {
          console.error(error);
        });
      setData(globals.clone(newData));
    }
  }, [page])

  const changeField = (v, field) => {
    setDirty(true);
    let d = {...data};
    d.season[field] = v;
    setData(d);
  }

  const removeTeam = (i) => {
    setDirty(true)
    let d = {...data};
    d.teams.splice(i, 1);
    setData(d)
  }

  const submit = () => {
    if(working) return false;
    let d = {season: data.season, teams: data.teams, competition_id: params.competitionid};
    d.season.start = globals.dateToUS(d.season.start)
    d.season.end = globals.dateToUS(d.season.end)
    console.log(d);
    apis.seasonset(d)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setWorking(false)
        if (json.code === 1) {
          setSuccess(true);
          setDirty(false);
          setTimeout(() => {
            if (d.season.id === '')
              navigate("/search/competition/" + params.competitionid + "/season/" + json.id);
          }, 200)
        } else {
          setInfo(true);
          setMessage(json.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  usePrompt(
    "Änderungen wirklich verwerfen?",
    dirty
  );

  const font = {fontSize: 20, fontWeight: 'bold'};

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      data.teams,
      result.source.index,
      result.destination.index
    );

    setData({...data, teams: items});
    setDirty(true);
  }

  const addTeam = (v) => {
    if (v == null || v.id === '')
      return false;
    setDirty(true);
    let d = {...data};
    d.teams.push({id: v.id, name: v.name, img: v.img})
    setData(d)
  }

  const screen = () => {
    if (data.season)
      return (
        <Box sx={{mt: '10px'}}>
          <Info message={"Server meldet: " + message} open={info}
                cancel={() => setInfo(false)}/>
          <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            open={success}
            onClose={() => setSuccess(false)}
            autoHideDuration={4000}
            key={'playersnack'}>
            <Alert onClose={() => setSuccess(false)} severity="success" sx={{width: '100%'}}>
              Speichern erfolgreich!
            </Alert>
          </Snackbar>
          {working ? <Button sx={{position: 'fixed', bottom: 10, right: 10, visibility: dirty ? 'visible' : 'hidden'}}
                             color={'success'} variant="contained">
            <CircularProgress size={14} sx={{color: 'black'}} />
          </Button> : <Button sx={{position: 'fixed', bottom: 10, right: 10, visibility: dirty ? 'visible' : 'hidden'}}
                              color={'success'} variant="contained"
                              endIcon={<CheckIcon/>} onClick={() => submit()}>
            Speichern
          </Button>}
          <Grid container spacing={2} sx={{mt: 0}} flexDirection={"column"}>
            <Grid item sm={12}>
              <Grid container spacing={2} flexDirection={"row"}>
                <Grid item sm={6}>
                  <TextField fullWidth label="Bezeichnung" variant="outlined"
                             InputLabelProps={{shrink: true}} inputProps={{autoComplete: 'off'}}
                             value={data.season.description}
                             onChange={(e) => changeField(e.target.value, 'description')}/>
                </Grid>
                <Grid item sm={6}>
                  <DatePicker
                    label="Start"
                    value={data.season.start}
                    onChange={(newValue) => changeField(newValue, 'start')}
                    renderInput={(params) => <TextField fullWidth {...params} InputLabelProps={{shrink: true}}/>}
                    mask={'__.__.____'}
                  />
                </Grid>
              </Grid>
              <Grid sx={{mt: '5px'}} container spacing={2} flexDirection={"row"}>
                <Grid item sm={6}>
                  <TextField fullWidth label="Filename" variant="outlined"
                             InputLabelProps={{shrink: true}} inputProps={{autoComplete: 'off'}}
                             value={data.season.filename} onChange={(e) => changeField(e.target.value, 'filename')}/>
                </Grid>
                <Grid item sm={6}>
                  <DatePicker
                    label="Start"
                    value={data.season.end}
                    onChange={(newValue) => changeField(newValue, 'end')}
                    renderInput={(params) => <TextField fullWidth {...params} InputLabelProps={{shrink: true}}/>}
                    mask={'__.__.____'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*playdays*/}
          <Box sx={{mt: '15px', overflow: 'hidden', display: typeof data.playdays != 'undefined' ? 'block' : 'none'}}>
            <TableContainer sx={{maxHeight: '700px'}} component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Spieltag</TableCell>
                    <TableCell align="center">Beginn</TableCell>
                    <TableCell align="center">Ende</TableCell>
                    <TableCell align="center">
                      <IconButton aria-label="add" sx={{backgroundColor: '#00ba83'}}
                                  onClick={() => navigate("/search/competition/" + params.competitionid + "/season/" + data.season.id + "/playday/")}>
                        <AddIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.playdays && data.playdays.map((row, i) => (
                    <TableRow
                      key={'playday' + row.id}
                      sx={{'td': {borderRight: 1}}}
                    >
                      <TableCell sx={font} align="center">
                        {row.name}
                      </TableCell>
                      <TableCell sx={font} align="center">{row.start}</TableCell>
                      <TableCell sx={font} align="center">{row.end}</TableCell>
                      <TableCell width={30} align="center">
                        <IconButton sx={{backgroundColor: '#da8a22'}}
                                    onClick={() => navigate("/search/competition/" + params.competitionid + "/season/" + data.season.id + "/playday/" + row.id)}>
                          <EditIcon/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {/*teams*/}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <TableContainer sx={{mt: '40px'}} component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left"/>
                          <TableCell align="left">Team</TableCell>
                          <TableCell align="center">
                            <IconButton aria-label="add" sx={{backgroundColor: '#00ba83'}} onClick={() => setAddteam(true)}>
                              <AddIcon/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.teams && data.teams.map((row, i) => (
                          <Draggable key={row.id} draggableId={row.id.toString()} index={i}>
                            {(provided, snapshot) => (
                              <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                key={'team' + row.id}
                                sx={{'td': {borderRight: 1}}}
                              >
                                <TableCell width={30} align="center">
                                  <IconButton {...provided.dragHandleProps}>
                                    <DragHandleIcon/>
                                  </IconButton>
                                </TableCell>
                                <TableCell sx={font} align="left">
                                  {row.name}
                                </TableCell>
                                <TableCell width={30} align="center" sx={{display: snapshot.isDragging ? 'none' : 'table-cell'}}>
                                  <IconButton aria-label="delete" sx={{backgroundColor: '#ba0000'}}
                                              onClick={() => removeTeam(i)}>
                                    <RemoveIcon/>
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          {/*add team modal*/}
          <Modal
            open={addteam}
            onClose={() => setAddteam(false)}
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}>
              <Searchbar autofocus type={'team'} onChange={addTeam}/>
            </Box>
          </Modal>
        </Box>
      )
    else
      return null;
  }

  if (loading)
    return loadingScreen();
  else if (data)
    return screen()

}

const loadingScreen = () => {
  return (
    <Box sx={{mt: 7, width: '100%'}}>
      <LinearProgress/>
    </Box>
  )
}

export default Season;
