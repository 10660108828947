import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import LinearProgress from "@mui/material/LinearProgress";
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import SkateboardingIcon from '@mui/icons-material/Skateboarding';

import { useDispatch } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import WarteSchleifeCard from "../components/WarteschleifeCard";

import { set as set_page_title } from "../reducers/page_title";
import * as apis from "../providers/apis";
import * as globals from "../providers/globals"

function Warteschleife() {
    const dispatch = useDispatch();
    dispatch(set_page_title('Warteschleife'))

    const [news, setNews] = useState(0)
    const [success, setSuccess] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [longestColN, setLongestColN] = useState(0);


    let user = window.localStorage.getItem('user')
    if (user != null)
        user = JSON.parse(user)

    function findLongestCol(news) {
        let n = 0;
        for (const key in news) {
            n = news[key].length > n ? news[key].length : n;
        }
        return n
    }

    useEffect(() => {
        apis.
            newsloopget()
            .then((response) => response.json())
            .then((json) => {
                setNews(json);
                setLongestColN(findLongestCol(json));
            })
    }, [])

    function handleOnDragEnd(result) {
        const { source, destination } = result;

        if (destination === null) { //drop out of nowhere
            return;
        }

        if (destination.index === source.index && destination.droppableId === source.droppableId) { //drop in same place
            return;
        }

        if (destination.droppableId === source.droppableId) { //drop same column
            const newsClone = news;
            const sourceClone = news[source.droppableId];
            const movedItem = sourceClone[source.index]
            movedItem.username = user.displayname;
            movedItem.created_string = "heute " + new Date().toLocaleTimeString() + " Uhr";
            movedItem.userimage = user.avatar;

            sourceClone.splice(source.index, 1) //remove item
            sourceClone.splice(destination.index, 0, movedItem); //add item
            newsClone[source.droppableId] = sourceClone;
            setNews(newsClone)
            return;
        }

        // all ok
        const newsClone = news;
        const sourceClone = news[source.droppableId];
        const destinationClone = news[destination.droppableId];
        const movedItem = sourceClone[source.index]
        movedItem.username = user.displayname;
        movedItem.created_string = "heute " + new Date().toLocaleTimeString() + " Uhr";
        movedItem.userimage = user.avatar;

        if (destination.droppableId !== "newsReady") {
            movedItem.approved = 0;
        }

        destinationClone.splice(destination.index, 0, movedItem); //add item
        sourceClone.splice(source.index, 1);// remove item

        newsClone[destination.droppableId] = destinationClone;
        newsClone[source.droppableId] = sourceClone;
        let alertText;
        switch (destination.droppableId) {
            case 'newsNew':
                alertText = "neue News";
                break;
            case "newsEdit":
                alertText = "Bearbeitung";
                break;
            case "newsReady":
                alertText = "bereit zur Veröffentlichung";
                break;
            default:
                alertText = "neue News"
        }
        apis
            .newssetstate(movedItem.newsid, user.id, new Date(), destination.droppableId) //check if usage of user is correct
            .then((response) => response.json())
            .then((json) => {
                setAlertText(destination.droppableId);
                setNews(newsClone)
                setLongestColN(findLongestCol(newsClone));
                setAlertText('News in ' + alertText + ' verschoben.');
                setSuccess(true);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    //visually remove news
    function handleVisualRemoveItem(data) {
        const newsClone = { ...news };
        const sourceClone = news[data.position.list];
        sourceClone.splice(data.position.row.index, 1);// remove item
        newsClone[data.position.list] = sourceClone
        setNews(newsClone);
    }

    // remove news on server
    function handleRemoveItem(data, deleteOptions) {
        apis
            .newsset({ action: 'delete', del: deleteOptions, news: { id: data.content.newsid, melder: { id: data.content.userid } } })
            .then((response) => response.json())
            .then((json) => {
                if (json.code === 1) {
                    setAlertText("News gelöscht");
                    handleVisualRemoveItem(data);
                    setSuccess(true);
                } else {
                    setAlertText("Fehler beim Löschen der News");
                    setSuccess(true)
                }
                console.log(json);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function handlePublishItem(data, datePublish) {
        console.log(datePublish, globals.datetimeToServerString(datePublish))
        apis
            .newssetstate(data.content.newsid, user.id, globals.datetimeToServerString(datePublish), "publish") //check if usage of user is correct
            .then((response) => response.json())
            .then((json) => {
                if (json == 1) {
                    setAlertText('News veröffentlicht.');
                    if (datePublish <= new Date()) {
                        handleVisualRemoveItem(data);
                    } else if (data.position.list === "newsReady") {
                        const newsClone = { ...news };
                        newsClone[data.position.list][data.position.row.index].approved = 1;
                        newsClone[data.position.list][data.position.row.index].date_created = datePublish / 1000;
                        setNews(newsClone);
                    } else {
                        const newsClone = { ...news };
                        newsClone[data.position.list][data.position.row.index].approved = 1;
                        newsClone[data.position.list][data.position.row.index].date_created = datePublish / 1000;
                        const movedItem = newsClone[data.position.list][data.position.row.index];
                        newsClone["newsReady"].splice(0, 0, movedItem); //add item
                        newsClone[data.position.list].splice(data.position.row.index, 1);// remove item
                        setNews(newsClone);
                    };
                    setSuccess(true);
                } else {
                    console.log(json);
                    setAlertText("Fehler beim Veröffentlichen der News");
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.error(error);
                setAlertText("Fehler beim Veröffentlichen der News");
            });
    }

    function handleRemoveTimer(data) {
        const newsClone = { ...news };
        const movedItem = newsClone["newsReady"][data.position.row.index];
        newsClone["newsReady"][data.position.row.index].approved = 0;
        setNews(newsClone);
        apis
            .newssetstate(movedItem.newsid, user.id, new Date(), "newsReady")
            .then((response) => response.json())
            .then((json) => {
                setAlertText('Timer gelöscht.');
                setSuccess(true);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    if (news !== 0)
        return (
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={success}
                    onClose={() => setSuccess(false)}
                    autoHideDuration={2000}
                    key={'playersnack'}>
                    <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                        {alertText}
                    </Alert>
                </Snackbar>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Grid container justifyContent="space-evenly" flexDirection={"row"} spacing={2} mt={3} alignItems="stretch">
                        <Grid item sm={6}>
                            <Paper variant="outlined" display="inline-block">
                                <Grid container flexDirection={"column"} alignItems="center">
                                    <Grid container>
                                        <Grid item container sm={6} justifyContent="center" justifyItems="center">
                                            <Chip icon={<EmojiPeopleIcon />} label="Neu User" color="info" variant="filled" sx={{ position: "absolute", top: 112 }} />
                                        </Grid>
                                        <Grid item container sm={6} justifyContent="center" justifyItems="center">
                                            <Chip icon={<SkateboardingIcon />} label="Neu Redaktion" color="info" variant="filled" sx={{ position: "absolute", top: 112 }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container flexDirection={"row"}>
                                        <Grid item sm={6}>
                                            <Grid container justifyContent="center">
                                                <Droppable droppableId="newsNew" >
                                                    {(provided) => (
                                                        <Grid item mt={5}
                                                            style={{ minHeight: longestColN * 435 + "px", minWidth: "250px" }}
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {news["newsNew"]?.map((item, index) => (
                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <Grid item mb={2}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <WarteSchleifeCard content={item}
                                                                                position={{ list: "newsNew", row: { index } }}
                                                                                removeItem={(data, deleteOptions) => { handleRemoveItem(data, deleteOptions) }}
                                                                                publishItem={(data, datePublish) => { handlePublishItem(data, datePublish) }}
                                                                                removeTimer={(data) => { handleRemoveTimer(data) }}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </Grid>
                                                    )}
                                                </Droppable>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Grid container justifyContent="center">
                                                <Droppable droppableId="newsNew2">
                                                    {(provided) => (
                                                        <Grid item mt={5}
                                                            style={{ minHeight: longestColN * 435 + "px", minWidth: "250px" }}
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {news["newsNew2"]?.map((item, index) => (
                                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <Grid item mb={2}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <WarteSchleifeCard content={item}
                                                                                position={{ list: "newsNew2", row: { index } }}
                                                                                removeItem={(data, deleteOptions) => { handleRemoveItem(data, deleteOptions) }}
                                                                                publishItem={(data, datePublish) => { handlePublishItem(data, datePublish) }}
                                                                                removeTimer={(data) => { handleRemoveTimer(data) }}
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </Grid>
                                                    )}
                                                </Droppable>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item sm={3}>
                            <Paper variant="outlined" display="inline-block">
                                <Grid container flexDirection={"column"} alignItems="center">
                                    <Chip icon={<EditIcon />} label="In Bearbeitung" color="secondary" variant="filled" sx={{ position: "absolute", top: 112 }} />
                                    <Droppable droppableId="newsEdit">
                                        {(provided) => (
                                            <Grid item mt={5}
                                                style={{ minHeight: longestColN * 435 + "px", minWidth: "250px" }}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {news["newsEdit"]?.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                        {(provided, snapshot) => (
                                                            <Grid item mb={2}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <WarteSchleifeCard content={item}
                                                                    position={{ list: "newsEdit", row: { index } }}
                                                                    removeItem={(data, deleteOptions) => { handleRemoveItem(data, deleteOptions) }}
                                                                    publishItem={(data, datePublish) => { handlePublishItem(data, datePublish) }}
                                                                    removeTimer={(data) => { handleRemoveTimer(data) }}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </Grid>
                                        )}
                                    </Droppable>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item sm={3}>
                            <Paper variant="outlined" display="inline-block" style={{ borderColor: "#66bb6a" }}>
                                <Grid container flexDirection={"column"} alignItems="center">
                                    <Chip icon={<CheckIcon />} label="Ready!" color="success" variant="filled" sx={{ position: "absolute", top: 112 }} />
                                    <Droppable droppableId="newsReady">
                                        {(provided) => (
                                            <Grid item mt={5}
                                                style={{ minHeight: longestColN * 435 + "px", minWidth: "250px" }}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {news["newsReady"]?.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id} index={index} >
                                                        {(provided, snapshot) => (
                                                            <Grid item mb={2}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <WarteSchleifeCard content={item}
                                                                    position={{ list: "newsReady", row: { index } }}
                                                                    removeItem={(data, deleteOptions) => { handleRemoveItem(data, deleteOptions) }}
                                                                    publishItem={(data, datePublish) => { handlePublishItem(data, datePublish) }}
                                                                    removeTimer={(data) => { handleRemoveTimer(data) }}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </Grid>
                                        )}
                                    </Droppable>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid >
                </DragDropContext >
            </Box >
        )
    else return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>
    );
}

export default Warteschleife;