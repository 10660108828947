import React, {useEffect, useRef, useState} from 'react';
import {
  TextField,
  Box,
  Divider,
  Switch,
  Modal,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Avatar,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Snackbar,
  Alert,
  CircularProgress,
  Typography,
  Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import RemoveIcon from '@mui/icons-material/Remove';
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Iconlist from "../components/iconlist";
import Searchbar from "../components/searchbar";
import DateTimePicker from "@mui/lab/DateTimePicker";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import * as apis from "../providers/apis";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import {Tweet} from 'react-twitter-widgets'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InstagramEmbed from 'react-instagram-embed';
import {set as set_page_title} from "../reducers/page_title";
import {set as set_searchbar} from "../reducers/searchbar";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import Gamelist from "../components/gamelist";
import Info from "../components/info";
import * as globals from "../providers/globals"
import VisibilityIcon from "@mui/icons-material/Visibility";
import Yesno from "../components/yesno";

const LoadingScreen = () => {
  return (
    <Box sx={{mt: 7, width: '100%'}}>
      <LinearProgress/>
    </Box>
  )
}

export default function User(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [penalty, setPenalty] = useState({duration: 0, open: false})
  const [del, setDel] = useState({id: 0, index: 0, open: false})
  const page = useSelector(state => state.page.value)
  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    let id = params.id;
    if (id != null) {
      setLoading(true);
      apis
        .userget(id)
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          if (json.id != null) {
            dispatch(set_page_title('User: ' + json.name))
            dispatch(set_searchbar({id: json.id, name: json.name, img: json.img, type: 'user'}))
            setData(json)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [page])

  if (loading)
    return <LoadingScreen/>
  else if (data && data.id)
    return (
      <>
        <Yesno message={"Kommentar wirklich löschen?"} open={del.open} accept={() => {
          apis.commentdelete({id: del.id})
          let d = {...data}
          d.comments.live.splice(del.index, 1);
          setDel({...del, open: false})
          setData(d)
        }}
               cancel={() => setDel({...del, open: false})}/>
        <Modal
          open={penalty.open}
          onClose={() => setPenalty({duration: 0, open: false})}
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}>
            <Typography variant={'h5'}>User sperren</Typography>
            <FormControl sx={{mt: '20px'}} fullWidth>
              <InputLabel shrink>Dauer</InputLabel>
              <Select
                fullWidth
                input={<OutlinedInput notched label={'Dauer'}/>}
                value={penalty.duration}
                onChange={(e) => setPenalty({...penalty, duration: e.target.value})}
              >
                <MenuItem value={0}>1 Woche</MenuItem>
                <MenuItem value={1}>2 Wochen</MenuItem>
                <MenuItem value={2}>1 Monat</MenuItem>
                <MenuItem value={3}>6 Monate</MenuItem>
                <MenuItem value={4}>1 Jahr</MenuItem>
              </Select>
            </FormControl>
            <Button sx={{mt: '20px', width: '100px'}} variant={'contained'} onClick={() => {
              setPenalty({...penalty, open: false})
              apis.userban({id: data.id, duration: penalty.duration}).then(() => window.location.reload())
            }} color={'success'}>Sperren</Button>
          </Box>
        </Modal>
        <Box sx={{display: 'flex', flexDirection: 'column', mt: '30px'}}>
          <Divider sx={{mb: '20px'}}/>
          <Box>ID: {data.id}</Box>
          <Box>Name: {data.name}</Box>
          <Box>Username: {data.username != null ? data.username : <Box sx={{fontStyle: 'italic'}}>(unbekannt)</Box>}
            <Box component={'small'} sx={{fontStyle: 'italic'}}> ({data.type})</Box>
          </Box>
          <Divider sx={{mt: '20px'}}/>
          <Box sx={{display: 'flex', flexDirection: 'row', mt: '20px'}}>
            <FormControl fullWidth>
              <InputLabel shrink>Gruppe</InputLabel>
              <Select
                fullWidth
                input={<OutlinedInput notched label={'Gruppe'}/>}
                value={data.groupid}
                onChange={(e) => setData({...data, groupid: e.target.value})}
              >
                <MenuItem value={1}>Unregistriert</MenuItem>
                <MenuItem value={2}>Nicht aktiviert</MenuItem>
                <MenuItem value={4}>Redakteur</MenuItem>
                <MenuItem value={5}>Chefredakteur</MenuItem>
                <MenuItem value={6}>Administrator</MenuItem>
                <MenuItem value={7}>Datenguru</MenuItem>
                <MenuItem value={9}>VAManager</MenuItem>
                <MenuItem value={10}>GI Manager</MenuItem>
                <MenuItem value={12}>Autor</MenuItem>
                <MenuItem value={13}>Chefredaktion</MenuItem>
                <MenuItem value={100}>Gesperrt</MenuItem>
                <MenuItem value={200}>Neuzugang</MenuItem>
                <MenuItem value={300}>Mitspieler</MenuItem>
                <MenuItem value={400}>Stammspieler</MenuItem>
              </Select>
            </FormControl>
            <Button variant={'contained'}
                    onClick={() => apis.groupset({id: data.id, groupid: data.groupid})}>Ändern</Button>
          </Box>
          <Box sx={{mt: '20px'}}>
            <Accordion disabled={data.denied.length === 0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
              >
                <Typography>Leichte Verstöße: {data.denied.length}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <Paper>Kommentar gelöscht am</Paper>
                  {data.denied.map(e =>
                    <Box>{e.datum}</Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{mt: '20px'}}>
            <Accordion disabled={data.warning.length === 0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
              >
                <Typography>Gelbe Karte | Verwarnung: {data.warning.length}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <Paper>Kommentar gelöscht am</Paper>
                  {data.warning.map(e =>
                    <Box>{e.datum}</Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{mt: '20px', position: 'relative'}}>
            <IconButton sx={{backgroundColor: '#f60f0f', position: 'absolute', right: '54px', top: '3px', zIndex: 2}}
                        onClick={() => setPenalty({...penalty, open: true})}>
              <AddIcon/>
            </IconButton>
            <Accordion disabled={data.blocked.length === 0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
              >
                <Typography>Rote Karte | Temporäre Sperre: {data.blocked.length}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <Paper>Kommentar gelöscht am</Paper>
                  {data.blocked.map(e =>
                    <Box>{e.datum}</Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', m: '20px', maxWidth: '50%'}}>
              <Typography variant={'h5'}>Öffentliche Kommentare</Typography>
              {data.comments.live.map((e, i) =>
                <Box key={e.id} sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: '10px',
                  border: '1px solid',
                  borderColor: 'text.primary'
                }}>
                  <Paper
                    sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Box>{e.datum}</Box>
                    <Box>
                      <IconButton sx={{backgroundColor: '#d20101', mr: '20px'}}
                                  onClick={() => setDel({id: e.id, index: i, open: true})}>
                        <RemoveIcon/>
                      </IconButton>
                      <IconButton sx={{backgroundColor: '#baa100', mr: '20px'}}
                                  onClick={() => window.open('https://hossidev.ligainsider.de/' + e.url, '_blank')}>
                        <VisibilityIcon/>
                      </IconButton>
                    </Box>
                  </Paper>
                  <Box>{e.body}</Box>
                </Box>
              )}
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', m: '20px', maxWidth: '50%'}}>
              <Typography variant={'h5'}>Gelöschte Kommentare</Typography>
              {data.comments.deleted.map(e =>
                <Box key={e.id} sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: '10px',
                  border: '1px solid',
                  borderColor: 'text.primary'
                }}>
                  <Paper
                    sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Box>{e.datum}</Box>
                    <Box>
                      <IconButton sx={{backgroundColor: '#baa100', mr: '20px'}}
                                  onClick={() => window.open('https://hossidev.ligainsider.de/' + e.url, '_blank')}>
                        <VisibilityIcon/>
                      </IconButton>
                    </Box>
                  </Paper>
                  <Box>{e.body}</Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </>
    )
  else return null

}
