import React, {useEffect, useRef, useState, Fragment} from 'react';
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams, usePrompt, useNavigate, useParams} from "react-router-dom";
import {
  TextField,
  Grid,
  Divider,
  Autocomplete,
  Avatar,
  Select,
  MenuItem,
  Icon,
  InputLabel,
  FormControl,
  FormGroup,
  Switch,
  OutlinedInput,
  Snackbar,
  Alert
} from "@mui/material";
import * as apis from "../providers/apis";
import * as globals from "../providers/globals"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import DatePicker from '@mui/lab/DatePicker';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AddIcon from "@mui/icons-material/Add";
import Yesno from '../components/yesno';
import Info from '../components/info';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Searchbar from '../components/searchbar'
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Checkbox from '@mui/material/Checkbox';
import {set as set_page_title} from "../reducers/page_title";
import {set as set_searchbar} from "../reducers/searchbar";
import AddCircleIcon from "@mui/icons-material/AddCircle";

function Player() {

  const newModal = {
    id: '',
    team_id: '',
    team_name: '',
    team_img: '',
    startdatum: null,
    enddatum: null,
    showstart: true,
    showend: true,
    type: 'Normal',
    sum: '',
    abloesefrei: false
  };

  const newData = {
    id: '',
    birthday: null,
    name: '',
    firstname: '',
    lastname: '',
    nickname: '',
    displayname: '',
    height: '',
    img: '',
    team_id: '',
    team_name: '',
    team_img: '',
    nation_id: '',
    nation_name: '',
    nation_img: '',
    position: 815,
    positions: {},
    positions_seasons: {},
    coach: false,
    kapitaen: false,
    transfers: [],
    contract_until: '',
    shirtnumber: '',
    facebook: '',
    wikipedia: '',
    instagram: '',
    twitter: '',
    kickbase_position: 0,
    spitch_position: 0,
    kickbase_playerid: '',
    tch_playerid: '',
    copyright: '',
    foot: 'null',
    opta_playeruuid: '',
    provider: 'null',
    filename: ''
  };

  let year = new Date().getFullYear();
  var season_description = [];
  for (let x = year - 30; x <= year; x++) {
    season_description.push(x + '/' + (x + 1).toString().substr(2, 2))
  }
  season_description = season_description.reverse();

  // GET params
  const params = useParams();
  const [confirm, setConfirm] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [transferIndex, setTransferIndex] = React.useState(0);
  const [modal, setModal] = React.useState(newModal);
  const page = useSelector(state => state.page.value)
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [info, setInfo] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState({...newData});

  const dispatch = useDispatch();

  const changeField = (value, field) => {
    setDirty(true);
    let d = {...data};
    d[field] = value;
    setData(d);
  }

  const handlePos = (e, pos) => {
    setDirty(true);
    let d = {...data};
    if (typeof d.positions[pos] == 'undefined')
      d.positions[pos] = {hn: null};
    if (d.positions[pos].hn === null) {
      d.positions[pos].hn = 1;
    } else if (d.positions[pos].hn === 1) {
      Object.keys(d.positions).forEach(function (i) {
        if (d.positions[i].hn === 2) {
          delete (d.positions[i]);
        }
      });
      d.positions[pos].hn = 2;
    } else if (d.positions[pos].hn === 2) {
      delete (d.positions[pos]);
    }
    setData(d);
  }

  const handleTeam = (v) => {
    setDirty(true);
    let d = {...data};
    let temp = {name: '', id: '', img: ''};
    if (!(v == null || v === 0)) {
      temp.id = v.id;
      temp.name = v.name;
      temp.img = v.img
    }
    d.team_id = temp.id;
    d.team_name = temp.name;
    d.team_img = temp.img
    setData(d);
  }

  const handleTeamInput = (v) => {
    let d = {...data};
    d.team_name = v.name;
    setData(d);
  }

  const handleNation = (v) => {
    setDirty(true);
    let d = {...data};
    let temp = {name: '', id: '', img: ''};
    if (!(v == null || v === 0)) {
      temp.id = v.id;
      temp.name = v.name;
      temp.img = v.img
    }
    d.nation_id = temp.id;
    d.nation_name = temp.name;
    d.nation_img = temp.img;
    setData(d);
  }

  const handleNationInput = (v) => {
    let d = {...data};
    d.nation_name = v.name;
    setData(d);
  }

  const handleTransferTeam = (v) => {
    let d = {...modal};
    let temp = {name: '', id: '', img: ''};
    if (!(v == null || v === 0)) {
      temp.id = v.id;
      temp.name = v.name;
      temp.img = v.img
    }
    d.team_id = temp.id;
    d.team_name = temp.name;
    d.team_img = temp.img;
    setModal(d);
  }

  const handleTransferTeamInput = (v) => {
    let d = {...modal};
    d.team_name = v.name;
    setModal(d);
  }

  const Positionguy = (props) => {
    const pos = props.pos;

    return (
      <Box sx={{position: 'relative'}} {...props}>
        {pos === null ? (<AddCircleIcon color={'success'} fontSize={'large'}/>) : null}
        {pos === 2 ? (<Box component="img" sx={{position: 'absolute', bottom: 10}} width={35}
                           src={globals.IMG_URL + '/images/misc/player-big.png'}/>) : null}
        {pos === 1 ? (<Box component="img" sx={{position: 'absolute', bottom: 10, left: 5}} width={25}
                           src={globals.IMG_URL + '/images/misc/player-small.png'}/>) : null}
      </Box>
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setDirty(false);
    setData(globals.clone(newData));
    let id = params.id;
    if (id != null) {
      setLoading(true);
      apis
        .playerget(id)
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          dispatch(set_page_title(json.name + ': Bearbeiten'))
          dispatch(set_searchbar({name: json.name, id: json.id, img: json.img, type: 'spieler'}))
          json.team_name = json.team_name ?? '';
          setData(json)
          setLoading(false)
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setLoading(false)
      dispatch(set_page_title('Spieler anlegen'))
      dispatch(set_searchbar({name: '', id: '', img: '', type: 'spieler'}))
    }
  }, [page])

  const deleteTransfer = (index) => {
    setDirty(true);
    let d = {...data};
    d.transfers.splice(index, 1);
    setData(d);
    setConfirm(false);
  }

  const handleModalField = (value, field) => {
    let m = {...modal};
    m[field] = value;
    setModal(m);
  }

  const openTransferModal = (i) => {
    setTransferIndex(i);
    setModal(data.transfers[i]);
    setEdit(true);
  }

  const addTransfer = () => {
    setTransferIndex('');
    setModal(newModal);
    setEdit(true);
  }

  const saveTransfer = () => {
    setDirty(true);
    modal.startdatum = globals.dateToUS(modal.startdatum)
    modal.enddatum = globals.dateToUS(modal.enddatum)
    let d = {...data};
    if (transferIndex !== '')
      d.transfers[transferIndex] = modal;
    else
      d.transfers.unshift(modal);
    setData(d);
    setEdit(false);
  }

  const loadingScreen = () => {
    return (
      <Box sx={{mt: 7, width: '100%'}}>
        <LinearProgress/>
      </Box>
    )
  }

  const submit = () => {
    let d = {...data};
    d.birthday = globals.dateToUS(d.birthday)
    console.log(d);
    console.log(JSON.stringify(d));
    apis.playerset(d)
      .then((response) => response.json())
      .then((json) => {
        if (json.code === 1) {
          setSuccess(true);
          setDirty(false);
          setTimeout(() => {
            if (d.id === '')
              navigate("/search/player/" + json.id, {replace: true});
          }, 200)
        } else {
          setInfo(true);
          setMessage(json.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  usePrompt(
    "Änderungen wirklich verwerfen?",
    dirty
  );

  const screen = () => {
    return (
      <Box component="main" sx={{flexGrow: 1, p: 3}}>
        <Snackbar
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          open={success}
          onClose={() => setSuccess(false)}
          autoHideDuration={4000}
          key={'playersnack'}>
          <Alert onClose={() => setSuccess(false)} severity="success" sx={{width: '100%'}}>
            Speichern erfolgreich!
          </Alert>
        </Snackbar>
        <Button sx={{position: 'fixed', bottom: 10, right: 10, visibility: dirty ? 'visible' : 'hidden'}}
                color={'success'} variant="contained"
                endIcon={<CheckIcon/>} onClick={() => submit()}>
          Speichern
        </Button>
        {data && data.id ?
          <Box sx={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'right'}}>
            <Button onClick={() => navigate("/search/player/" + data.id + '/status')}>Krankenakte</Button>
            <Button onClick={() => navigate("/search/news/?value=" + data.id + '&type=player')}>+News</Button>
          </Box> : null}
        <Divider textAlign={"left"} sx={{pt: 3}}>Stammdaten:</Divider>
        <Grid container spacing={2} sx={{mt: 0}} flexDirection={"column"}>
          <Grid item sm={12}>
            <Grid container spacing={2} flexDirection={"row"}>
              <Grid item sm={4}>
                <TextField fullWidth id="playername" label="Spielername*" variant="outlined"
                           InputLabelProps={{shrink: true}} inputProps={{autoComplete: 'off'}}
                           value={data.name} onChange={(e) => changeField(e.target.value, 'name')}/>
              </Grid>
              <Grid item sm={4}>
                <TextField fullWidth id="firstname" label="Vorname" variant="outlined" InputLabelProps={{shrink: true}}
                           value={data.firstname} onChange={(e) => changeField(e.target.value, 'firstname')}/>
              </Grid>
              <Grid item sm={4}>
                <TextField fullWidth id="lastname" label="Nachname" variant="outlined" InputLabelProps={{shrink: true}}
                           value={data.lastname} onChange={(e) => changeField(e.target.value, 'lastname')}/>
              </Grid>
            </Grid>
            <Grid container paddingTop={2} spacing={2} flexDirection={"row"}>
              <Grid item sm={4}>
                <TextField fullWidth id="nickname" label="Nickname" variant="outlined" InputLabelProps={{shrink: true}}
                           value={data.nickname} onChange={(e) => changeField(e.target.value, 'nickname')}/>
              </Grid>
              <Grid item sm={4}>
                <TextField fullWidth id="displayname" label="Display Name" variant="outlined"
                           InputLabelProps={{shrink: true}}
                           value={data.displayname} onChange={(e) => changeField(e.target.value, 'displayname')}/>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel shrink id="PositionLabel">Position*</InputLabel>
                  <Select
                    input={<OutlinedInput notched label={'Position*'}/>}
                    fullWidth
                    labelId="PositionLabel"
                    id="position"
                    label="Position*"
                    value={data.position}
                    onChange={(e) => changeField(e.target.value, 'position')}
                  >
                    <MenuItem value={815}>Torhüter</MenuItem>
                    <MenuItem value={816}>Abwehrspieler</MenuItem>
                    <MenuItem value={817}>Mittelfeldspieler</MenuItem>
                    <MenuItem value={818}>Stürmer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container paddingTop={2} spacing={2} flexDirection={"row"}>
              <Grid item sm={4}>
                <Searchbar label={'Nation'} type={'nation'} onInputChange={handleNationInput} onChange={handleNation}
                           value={{name: data.nation_name, id: data.nation_id, img: data.nation_img}}/>
              </Grid>
              <Grid item sm={4}>
                <DatePicker
                  label="Geburtsdatum"
                  value={data.birthday}
                  onChange={(newValue) => changeField(newValue, 'birthday')}
                  renderInput={(params) => <TextField fullWidth {...params} InputLabelProps={{shrink: true}}/>}
                  mask={'__.__.____'}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField fullWidth id="height" label="Größe" variant="outlined"
                           InputLabelProps={{shrink: true}}
                           value={data.height} onChange={(e) => changeField(e.target.value, 'height')}/>
              </Grid>
            </Grid>
            <Grid container paddingTop={2} spacing={2} flexDirection={"row"}>
              <Grid item sm={4}>
                <TextField fullWidth id="shirtnumber" label="Rückennummer" variant="outlined"
                           InputLabelProps={{shrink: true}}
                           value={data.shirtnumber} onChange={(e) => changeField(e.target.value, 'shirtnumber')}/>
              </Grid>
              <Grid item sm={4}>
                <TextField fullWidth id="contract_until" label="Vertrag bis" variant="outlined"
                           InputLabelProps={{shrink: true}}
                           value={data.contract_until} onChange={(e) => changeField(e.target.value, 'contract_until')}/>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth sx={{top: 10}}>
                  <FormControlLabel
                    control={<Switch checked={data.coach} onChange={(e) => changeField(e.target.checked, 'coach')}/>}
                    label="Trainer"/>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container paddingTop={2} spacing={2} flexDirection={"row"}>
              <Grid item sm={4}>
                <FormControl fullWidth sx={{top: 10}}>
                  <FormControlLabel control={<Switch checked={data.kapitaen}
                                                     onChange={(e) => changeField(e.target.checked, 'kapitaen')}/>}
                                    label="Kapitän"/>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <TextField fullWidth id="facebook" label="Facebook" variant="outlined"
                           InputLabelProps={{shrink: true}}
                           value={data.facebook} onChange={(e) => changeField(e.target.value, 'facebook')}/>
              </Grid>
              <Grid item sm={4}>
                <TextField fullWidth id="wikipedia" label="Wikipedia" variant="outlined"
                           InputLabelProps={{shrink: true}}
                           value={data.wikipedia} onChange={(e) => changeField(e.target.value, 'wikipedia')}/>
              </Grid>
            </Grid>
            <Grid container paddingTop={2} spacing={2} flexDirection={"row"}>
              <Grid item sm={4}>
                <TextField fullWidth id="instagram" label="Instagram" variant="outlined"
                           InputLabelProps={{shrink: true}}
                           value={data.instagram} onChange={(e) => changeField(e.target.value, 'instagram')}/>
              </Grid>
              <Grid item sm={4}>
                <TextField fullWidth id="twitter" label="Twitter" variant="outlined"
                           InputLabelProps={{shrink: true}}
                           value={data.twitter} onChange={(e) => changeField(e.target.value, 'twitter')}/>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel shrink id="KBPositionLabel">Kickbase Position</InputLabel>
                  <Select
                    input={<OutlinedInput notched label={'Kickbase Position'}/>}
                    fullWidth
                    labelId="KBPositionLabel"
                    id="KBPosition"
                    label="Kickbase Position"
                    value={data.kickbase_position}
                    onChange={(e) => changeField(e.target.value, 'kickbase_position')}
                  >
                    <MenuItem value={0}>Unbekannt</MenuItem>
                    <MenuItem value={1}>Torhüter</MenuItem>
                    <MenuItem value={2}>Abwehrspieler</MenuItem>
                    <MenuItem value={3}>Mittelfeldspieler</MenuItem>
                    <MenuItem value={4}>Stürmer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container paddingTop={2} spacing={2} flexDirection={"row"}>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel shrink id="SPPositionLabel">Spitch Position</InputLabel>
                  <Select
                    input={<OutlinedInput notched label={'Spitch Position'}/>}
                    fullWidth
                    labelId="SPPositionLabel"
                    id="SPPosition"
                    label="Spitch Position"
                    value={data.spitch_position}
                    onChange={(e) => changeField(e.target.value, 'spitch_position')}
                  >
                    <MenuItem value={0}>Unbekannt</MenuItem>
                    <MenuItem value={1}>Torhüter</MenuItem>
                    <MenuItem value={2}>Abwehrspieler</MenuItem>
                    <MenuItem value={3}>Mittelfeldspieler</MenuItem>
                    <MenuItem value={4}>Stürmer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <TextField fullWidth id="kickbase_playerid" label="Kickbase ID" variant="outlined"
                           InputLabelProps={{shrink: true}}
                           value={data.kickbase_playerid}
                           onChange={(e) => changeField(e.target.value, 'kickbase_playerid')}/>
              </Grid>
              <Grid item sm={4}>
                <TextField fullWidth id="spitch_playerid" label="Spitch ID" variant="outlined"
                           InputLabelProps={{shrink: true}}
                           value={data.spitch_playerid}
                           onChange={(e) => changeField(e.target.value, 'spitch_playerid')}/>
              </Grid>
            </Grid>
            <Grid container paddingTop={2} spacing={2} flexDirection={"row"}>
              <Grid item sm={4}>
                <TextField fullWidth id="copyright" label="Copyright" variant="outlined"
                           InputLabelProps={{shrink: true}}
                           value={data.copyright} onChange={(e) => changeField(e.target.value, 'copyright')}/>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel shrink id="providerLabel">Provider</InputLabel>
                  <Select
                    input={<OutlinedInput notched label={'Provider'}/>}
                    fullWidth
                    labelId="providerLabel"
                    id="provider"
                    label="Provider"
                    value={data.provider}
                    onChange={(e) => changeField(e.target.value, 'provider')}
                  >
                    <MenuItem value={'null'}>Unbekannt</MenuItem>
                    <MenuItem value={'imagoimages'}>imagoimages</MenuItem>
                    <MenuItem value={'gettyimages'}>gettyimages</MenuItem>
                    <MenuItem value={'LigaInsider'}>LigaInsider</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel shrink id="footLabel">Starker Fuß</InputLabel>
                  <Select
                    input={<OutlinedInput notched label={'Starker Fuß'}/>}
                    fullWidth
                    labelId="footLabel"
                    id="foot"
                    label="Starker Fuß"
                    value={data.foot}
                    onChange={(e) => changeField(e.target.value, 'foot')}
                  >
                    <MenuItem value={'null'}>Unbekannt</MenuItem>
                    <MenuItem value={'Links'}>Links</MenuItem>
                    <MenuItem value={'Rechts'}>Rechts</MenuItem>
                    <MenuItem value={'Beidfüßig'}>Beidfüßig</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container paddingTop={2} spacing={2} flexDirection={"row"}>
              <Grid item sm={4}>
                <TextField fullWidth id="optaplayeruuid" label="Opta UUID" variant="outlined"
                           InputLabelProps={{shrink: true}}
                           value={data.opta_playeruuid}
                           onChange={(e) => changeField(e.target.value, 'opta_playeruuid')}/>
              </Grid>
              <Grid item sm={4}>
                <TextField fullWidth id="filename" label="Dateiname" variant="outlined"
                           InputLabelProps={{shrink: true}}
                           value={data.filename} onChange={(e) => changeField(e.target.value, 'filename')}/>
              </Grid>
              <Grid item sm={4}>

              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Divider textAlign={"left"} sx={{pt: 5}}>Team:</Divider>
            <Grid container paddingTop={2} spacing={2} flexDirection={"row"}>
              <Grid item sm={12}>
                <Searchbar label="Team" type={'team'} onInputChange={handleTeamInput} onChange={handleTeam}
                           value={{name: data.team_name, id: data.team_id, img: data.team_img}}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Divider textAlign={"left"} sx={{pt: 5}}>Positionen:</Divider>
            <Grid container padding={2} spacing={2} flexDirection={"row"}>
              <Grid item sm={6} border={1} sx={{position: 'relative'}}>
                <Box component="img" width={500} src={globals.IMG_URL + '/images/misc/small_stadium.png'}/>
                <Box sx={{left: 123, bottom: 73, position: 'absolute'}} onClick={(e) => handlePos(e, 'TH')}>
                  <Positionguy
                    pos={typeof data.positions.TH != 'undefined' ? data.positions.TH.hn : null}/>
                </Box>
                <Box sx={{left: 115, bottom: 128, position: 'absolute'}} onClick={(e) => handlePos(e, 'LV')}>
                  <Positionguy
                    pos={typeof data.positions.LV != 'undefined' ? data.positions.LV.hn : null}/>
                </Box>
                <Box sx={{left: 173, bottom: 98, position: 'absolute'}} onClick={(e) => handlePos(e, 'IV')}>
                  <Positionguy
                    pos={typeof data.positions.IV != 'undefined' ? data.positions.IV.hn : null}/>
                </Box>
                <Box sx={{left: 251, bottom: 55, position: 'absolute'}} onClick={(e) => handlePos(e, 'RV')}>
                  <Positionguy
                    pos={typeof data.positions.RV != 'undefined' ? data.positions.RV.hn : null}/>
                </Box>
                <Box sx={{left: 230, bottom: 115, position: 'absolute'}} onClick={(e) => handlePos(e, 'DM')}>
                  <Positionguy
                    pos={typeof data.positions.DM != 'undefined' ? data.positions.DM.hn : null}/>
                </Box>
                <Box sx={{left: 270, bottom: 135, position: 'absolute'}} onClick={(e) => handlePos(e, 'ZM')}>
                  <Positionguy
                    pos={typeof data.positions.ZM != 'undefined' ? data.positions.ZM.hn : null}/>
                </Box>
                <Box sx={{left: 250, bottom: 180, position: 'absolute'}} onClick={(e) => handlePos(e, 'LA')}>
                  <Positionguy
                    pos={typeof data.positions.LA != 'undefined' ? data.positions.LA.hn : null}/>
                </Box>
                <Box sx={{left: 250, bottom: 180, position: 'absolute'}} onClick={(e) => handlePos(e, 'LA')}>
                  <Positionguy
                    pos={typeof data.positions.LA != 'undefined' ? data.positions.LA.hn : null}/>
                </Box>
                <Box sx={{left: 320, bottom: 155, position: 'absolute'}} onClick={(e) => handlePos(e, 'OM')}>
                  <Positionguy
                    pos={typeof data.positions.OM != 'undefined' ? data.positions.OM.hn : null}/>
                </Box>
                <Box sx={{left: 380, bottom: 125, position: 'absolute'}} onClick={(e) => handlePos(e, 'RA')}>
                  <Positionguy
                    pos={typeof data.positions.RA != 'undefined' ? data.positions.RA.hn : null}/>
                </Box>
                <Box sx={{left: 360, bottom: 175, position: 'absolute'}} onClick={(e) => handlePos(e, 'ST')}>
                  <Positionguy
                    pos={typeof data.positions.ST != 'undefined' ? data.positions.ST.hn : null}/>
                </Box>
              </Grid>
              <Grid item sm={6} border={1}>

              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Divider textAlign={"left"} sx={{pt: 5}}>Transfers:</Divider>
            <TableContainer sx={{maxWidth: 1000, overflow: 'hidden'}} component={Paper}>
              <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Saison</TableCell>
                    <TableCell align="center">Beginn</TableCell>
                    <TableCell align="center">Ende</TableCell>
                    <TableCell align="center">Verein</TableCell>
                    <TableCell align="center">Art</TableCell>
                    <TableCell align="center">Ablösesumme</TableCell>
                    <TableCell align="center"/>
                    <TableCell align="center">
                      <IconButton aria-label="add" sx={{backgroundColor: '#00ba83'}}
                                  onClick={addTransfer}>
                        <AddIcon/>
                      </IconButton></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.transfers.map((row, i) => (
                    <TableRow
                      key={'transfers' + row.id}
                      sx={{'td': {borderRight: 1}}}
                    >
                      <TableCell align="right">
                        {row.season}
                      </TableCell>
                      <TableCell align="right">{row.showstart ? globals.dateToGerman(row.startdatum) : ''}</TableCell>
                      <TableCell align="right">{row.showend ? globals.dateToGerman(row.enddatum) : ''}</TableCell>
                      <TableCell align="right">
                        <Grid container flexDirection={row}>
                          <Avatar src={row.team_img} sx={{width: 20, height: 20, mr: 1}}/>{row.team_name}
                        </Grid>
                      </TableCell>
                      <TableCell align="right">{row.type === 'Leihe' ? 'L' : ''}</TableCell>
                      <TableCell align="right">{row.abloesefrei ? 'Ablösefrei' : row.sum}</TableCell>
                      <TableCell width={30} align="right">
                        <IconButton aria-label="delete" sx={{backgroundColor: '#da8a22'}}
                                    onClick={() => openTransferModal(i)}>
                          <EditIcon/>
                        </IconButton>
                      </TableCell>
                      <TableCell width={30} align="right">
                        <IconButton aria-label="delete" sx={{backgroundColor: '#ba0000'}} onClick={() => {
                          setConfirm(true);
                          setTransferIndex(i)
                        }}>
                          <DeleteIcon/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Yesno message={"Transfer wirklich löschen?"} open={confirm} accept={() => deleteTransfer(transferIndex)}
                   cancel={() => setConfirm(false)}/>
            <Info message={"Server meldet: " + message} open={info}
                  cancel={() => setInfo(false)}/>
            <Modal
              open={edit}
              onClose={() => setEdit(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 700,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}>
                <Grid container alignItems={'stretch'} direction={'column'} spacing={2}>
                  <Grid item sm>
                    <Grid container direction={'row'} justifyContent={'center'}>
                      <Grid item sm={10}>
                        <Searchbar type={'team'} onInputChange={handleTransferTeamInput} onChange={handleTransferTeam}
                                   value={{name: modal.team_name, id: modal.team_id, img: modal.team_img}}/>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm>
                    <Grid container justifyContent={'center'} spacing={2} flexDirection={'row'}>
                      <Grid item sm={5}>
                        <DatePicker
                          label="Start"
                          value={modal.startdatum}
                          onChange={(newValue) => handleModalField(newValue, 'startdatum')}
                          renderInput={(params) => <TextField {...params} />}
                          mask={'__.__.____'}
                        />
                      </Grid>
                      <Grid item sm={5}>
                        <DatePicker
                          label="Ende"
                          value={modal.enddatum}
                          onChange={(newValue) => handleModalField(newValue, 'enddatum')}
                          renderInput={(params) => <TextField {...params} />}
                          mask={'__.__.____'}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm>
                    <Grid container justifyContent={'center'} spacing={2} flexDirection={'row'}>
                      <Grid item sm={5}>
                        <FormControlLabel control={<Checkbox checked={modal.showstart}
                                                             onChange={(e) => handleModalField(e.target.checked, 'showstart')}/>}
                                          label="Startdatum anzeigen"/>
                      </Grid>
                      <Grid item sm={5}>
                        <FormControlLabel control={<Checkbox checked={modal.showend}
                                                             onChange={(e) => handleModalField(e.target.checked, 'showend')}/>}
                                          label="Enddatum anzeigen"/>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm>
                    <Grid container direction={'row'} justifyContent={'center'} spacing={2}>
                      <Grid item sm={5}>
                        <Grid container direction={'row'} justifyContent={'right'}>
                          <TextField fullWidth id="sum" label="Summe" variant="outlined"
                                     value={modal.sum} onChange={(e) => handleModalField(e.target.value, 'sum')}/>
                        </Grid>
                      </Grid>
                      <Grid item sm={5}>
                        <Grid container direction={'row'} justifyContent={'left'}>
                          <FormControl fullWidth>
                            <InputLabel id="ModalArtLabel">Art</InputLabel>
                            <Select
                              input={<OutlinedInput notched label={'Art'}/>}
                              fullWidth
                              labelId="ModalArtLabel"
                              label={'Art'}
                              id="art"
                              value={modal.type}
                              onChange={(e) => handleModalField(e.target.value, 'type')}
                            >
                              <MenuItem value={'Normal'}>Normal</MenuItem>
                              <MenuItem value={'Leihe'}>Leihe</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={5}>
                    <Grid container direction={'row'} justifyContent={'center'} spacing={2}>
                      <Grid item sm={5}>
                        <Grid container direction={'row'} justifyContent={'center'}>
                          <FormControlLabel control={<Checkbox checked={modal.abloesefrei}
                                                               onChange={(e) => handleModalField(e.target.checked, 'abloesefrei')}/>}
                                            label="Ablösefrei"/>
                        </Grid>
                      </Grid>
                      <Grid item sm={5}>
                        <Grid container direction={'row'} justifyContent={'center'}>
                          <FormControl fullWidth>
                            <InputLabel id="ModalSaisonLabel">Saison</InputLabel>
                            <Select
                              input={<OutlinedInput notched label={'Saison'}/>}
                              fullWidth
                              labelId="ModalSaisonLabel"
                              id="season"
                              label="Saison"
                              value={modal.season}
                              onChange={(e) => handleModalField(e.target.value, 'season')}
                            >
                              {season_description.map((item) => {
                                return (<MenuItem key={'tfseason' + item.id} value={item}>{item}</MenuItem>)
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm>
                    <Grid container direction={'row'} justifyContent={'center'} spacing={2}>
                      <Grid item sm={6}>
                        <Grid container direction={'row'} justifyContent={'right'}>
                          <Button variant="contained" color={'success'} endIcon={<CheckIcon/>}
                                  onClick={() => saveTransfer()}>
                            {modal.id === '' ? 'Anlegen' : 'Ändern'}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item sm={6}>
                        <Grid container direction={'row'} justifyContent={'left'}>
                          <Button variant="contained" color={'error'} endIcon={<CloseIcon/>}
                                  onClick={() => setEdit(false)}>
                            Abbrechen
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Box>
    )
  }

  if (loading)
    return loadingScreen();
  else
    return screen();
}

export default Player;
