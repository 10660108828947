import './App.css';
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import RadarIcon from '@mui/icons-material/Radar';
import HomeIcon from '@mui/icons-material/Home';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import MailIcon from '@mui/icons-material/Mail';
import EditIcon from '@mui/icons-material/Edit';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Button, ThemeProvider, createTheme, Avatar } from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Collapse from '@mui/material/Collapse';
import StarBorder from '@mui/icons-material/StarBorder';
import darkScrollbar from "@mui/material/darkScrollbar";
import Badge from '@mui/material/Badge';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import store from './store';
import { Provider, useSelector } from 'react-redux'
import ListIcon from '@mui/icons-material/List';
import CommentIcon from '@mui/icons-material/Comment';
import * as globals from './providers/globals';

import { BrowserRouter, Routes, Route, Link, useLocation } from "react-router-dom";
import Team from "./pages/Team";
import Player from "./pages/Player";
import Dashboard from "./pages/Dashboard";
import Warteschleife from './pages/Warteschleife';
import PlayerPositionTable from './pages/PlayerPositionTable';
import Search from './pages/Search'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import deLocale from 'date-fns/locale/de';
import { useDispatch } from "react-redux";
import { set as set_page } from './reducers/page'
import { useEffect, useState } from "react";
import Competition from "./pages/Competition";
import Season from "./pages/Season";
import Playday from "./pages/Playday";
import Status from "./pages/Status";
import Auth from './pages/Auth';
import { set as set_user } from "./reducers/user";
import { set as set_notifications } from "./reducers/notifications";
import News from "./pages/News";
import Newslist from "./pages/Newslist";
import Commentloop from "./pages/Commentloop";
import WarningIcon from '@mui/icons-material/Warning';
import Alerts from "./pages/Alerts";
import * as apis from "./providers/apis";
import ImageIcon from '@mui/icons-material/Image';
import Images from "./pages/Images";
import User from "./pages/User";
import Administration from "./pages/Administration";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function App() {

  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.value)
  const notifications = useSelector(state => state.notifications.value)

  useEffect(() => {
    let u = window.localStorage.getItem('user')
    if (u == null && user != null) {
      window.localStorage.setItem('user', JSON.stringify(user))
    } else if (u != null && user == null) {
      dispatch(set_user(JSON.parse(u)))
    }
  }, [])

  const logout = () => {
    window.localStorage.removeItem('user');
    dispatch(set_user(null))
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            ...darkScrollbar(),
            overflowY: 'scroll'
          }
        }
      }
    }
  });

  const [addOpen, setAddOpen] = React.useState(true);
  const [profileMenu, setProfileMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const openProfileMenu = (e) => {
    setAnchorEl(e.currentTarget);
    setProfileMenu(true);
  }

  const handleClickAdd = () => {
    setAddOpen(!addOpen);
  };

  const page_title = useSelector(state => state.page_title.value)

  const TrackLocation = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    const page = useSelector(state => state.page.value)

    useEffect(() => {
      if (location !== page) {
        dispatch(set_page(location))
      }

      if (user != null)
        apis
          .notificationsget()
          .then((response) => response.json())
          .then((json) => {
            let n = window.localStorage.getItem('notifications')
            if (n == null) {
              window.localStorage.setItem('notifications', json.alerts)
            }
            if (n !== json.alerts) {
              window.localStorage.setItem('notifications', json.alerts)
              dispatch(set_notifications(json.alerts))
            }
          })
          .catch((error) => {

          });
    }, [location]);
    return null;
  }

  if (user === null || [4, 5, 6, 7, 9, 10, 12, 13].indexOf(user.usergroup.id) === -1)
    return <Auth />
  else
    return (
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
          <BrowserRouter>
            <TrackLocation />
            <DrawerHeader />
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <AppBar position="fixed" open={open}>
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                      marginRight: '36px',
                      ...(open && { display: 'none' }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" noWrap component="div">
                    {page_title}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Box sx={{ position: 'relative' }}>
                    <Avatar sx={{ mr: '10px' }} component={Link} to={'/alerts'}>
                      <WarningIcon />
                    </Avatar>
                    {notifications > 0 ? <Box sx={{
                      lineHeight: '20px',
                      textAlign: 'center',
                      position: 'absolute',
                      bottom: '-8px',
                      right: '10px',
                      height: '20px',
                      width: '20px',
                      fontSize: 10,
                      backgroundColor: 'red',
                      borderRadius: '50%',
                      border: '1px solid darkred'
                    }}>
                      {notifications}
                    </Box> : null}
                  </Box>
                  <Box onClick={openProfileMenu} sx={{ display: { md: 'flex', cursor: 'pointer' } }}>
                    <Avatar src={user.avatar} />
                  </Box>
                  <Menu
                    anchorEl={anchorEl}
                    open={profileMenu}
                    onClose={() => {
                      setProfileMenu(false);
                      setAnchorEl(null)
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={() => {
                      setProfileMenu(false);
                      setAnchorEl(null);
                      logout();
                    }}>Logout</MenuItem>
                  </Menu>
                </Toolbar>
              </AppBar>
              <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                  <ListItem button component={Link} to={"/search"}>
                    <ListItemIcon>
                      <SearchOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Suche" />
                  </ListItem>
                  <ListItem button component={Link} to={"/warteschleife"}>
                    <ListItemIcon>
                      <QueryBuilderIcon />
                    </ListItemIcon>
                    <ListItemText primary="Warteschleife" />
                  </ListItem>
                  <ListItem button component={Link} to={"/newslist"}>
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary="News Liste" />
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListItem button onClick={handleClickAdd}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText primary="Anlegen" />
                    {addOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={addOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem button component={Link} to={"/search/player"} sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <StarBorder />
                        </ListItemIcon>
                        <ListItemText primary="Spieler" />
                      </ListItem>
                      <ListItem button component={Link} to={"/search/team"} sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <StarBorder />
                        </ListItemIcon>
                        <ListItemText primary="Team" />
                      </ListItem>
                      <ListItem button component={Link} to={"/search/news"} sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <StarBorder />
                        </ListItemIcon>
                        <ListItemText primary="News" />
                      </ListItem>
                    </List>
                  </Collapse>
                </List>
                <Divider />
                <List>
                  <ListItem button component={Link} to={"/commentloop"}>
                    <ListItemIcon>
                      <CommentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Kommentarschleife" />
                  </ListItem>
                  <ListItem button component={Link} to={"/images"}>
                    <ListItemIcon>
                      <ImageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Bilder" />
                  </ListItem>
                  <ListItem button component={Link} to={"/playerpositiontable"}>
                    <ListItemIcon>
                      <RadarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Spielerpositionen" />
                  </ListItem>
                  <ListItem button component={Link} to={"/administration"}>
                    <ListItemIcon>
                      <RadarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Administration" />
                  </ListItem>
                </List>
              </Drawer>
              {/* hier routing, geht auch in index.js dann ist der inhalt aber nicht responsive ob der drawer klein oder groß ist imho */}
              <Routes>
                <Route index element={<Search />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="search" element={<Search />}>
                  <Route path={'player'} element={<Player />} />
                  <Route path={'player/:id'} element={<Player />} />
                  <Route path={'player/:id/status'} element={<Status />} />
                  <Route path={'team'} element={<Team />} />
                  <Route path={'team/:id'} element={<Team />} />
                  <Route path={'team/:id/:tmid'} element={<Team />} />
                  <Route path={'competition/:id'} element={<Competition />} />
                  <Route path={'competition/:competitionid/season'} element={<Season />} />
                  <Route path={'competition/:competitionid/season/:seasonid'} element={<Season />} />
                  <Route path={'competition/:competitionid/season/:seasonid/playday'} element={<Playday />} />
                  <Route path={'competition/:competitionid/season/:seasonid/playday/:dayid'} element={<Playday />} />
                  <Route path={'news'} element={<News />} />
                  <Route path={'news/:id'} element={<News />} />
                  <Route path={'user/:id'} element={<User />} />
                </Route>
                <Route path="warteschleife" element={<Warteschleife />} />
                <Route path="newslist" element={<Newslist />} />
                <Route path="commentloop" element={<Commentloop />} />
                <Route path="images" element={<Images />} />
                <Route path="alerts" element={<Alerts />} />
                <Route path="playerpositiontable" element={<PlayerPositionTable />} />
                <Route path="administration" element={<Administration />} />
              </Routes>
            </Box>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    );
}

export default App;
