import React, { useEffect, useRef, useState } from 'react';
import {
  TextField,
  Box,
  Divider,
  Switch,
  Modal,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem, Avatar, ButtonGroup, ToggleButtonGroup, ToggleButton, Snackbar, Alert, CircularProgress, Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Iconlist from "../components/iconlist";
import Searchbar from "../components/searchbar";
import DateTimePicker from "@mui/lab/DateTimePicker";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import LinkIcon from '@mui/icons-material/Link';
import * as apis from "../providers/apis";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import { Tweet } from 'react-twitter-widgets'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import InstagramEmbed from 'react-instagram-embed';
import { set as set_page_title } from "../reducers/page_title";
import { set as set_searchbar } from "../reducers/searchbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Gamelist from "../components/gamelist";
import Info from "../components/info";
import * as globals from "../providers/globals"
import VisibilityIcon from "@mui/icons-material/Visibility";

const LoadingScreen = () => {
  return (
    <Box sx={{ mt: 7, width: '100%' }}>
      <LinearProgress />
    </Box>
  )
}

const Data = (props) => {

  const settings = props.settings
  const setSettings = props.setSettings;

  if (typeof setSettings == 'undefined')
    return null

  const setter = (place, data) => {
    let s = { ...settings }
    s.data = {}
    s.data[place] = data
    setSettings(s)
  }

  const setImg = (img) => {
    let s = { ...settings }
    s.data = { url: '', id: null }
    if (img != null) {
      s.data.url = img.src
      s.data.id = img.id
    }
    setSettings(s)
  }

  const setTm = (v) => {
    let s = { ...settings }
    s.data.tmid = v
    setSettings(s)
  }

  const setTeamHandler = (v) => {
    let x;
    if (v == null)
      x = { id: '', name: '', img: '' }
    else
      x = v
    let s = { ...settings }
    s.data = { ...s.data }
    s.data.team = x
    setSettings(s)
  }

  const changeDatum = (v) => {
    let s = { ...settings };
    s.data.enddate = v
    setSettings(s)
  }

  const addEntry = () => {
    let s = { ...settings };
    s.data.entries.push({ id: '', name: '' })
    setSettings(s)
  }

  const changeEntry = (e, i) => {
    let s = { ...settings };
    s.data.entries[i].name = e.target.value
    setSettings(s)
  }

  const removeEntry = (i) => {
    let s = { ...settings };
    s.data.entries.splice(i, 1)
    setSettings(s)
  }

  if (settings.type === 'textbild')
    return <Imagesearch onChange={setImg} image={settings.data ?? null} />
  else if (settings.type === 'youtube')
    return <TextField fullWidth label="Video ID" variant="outlined"
      InputLabelProps={{ shrink: true }}
      inputProps={{ autoComplete: 'off', style: { fontFamily: 'Consolas' } }}
      placeholder={'Video ID'}
      value={settings.data.id}
      onChange={(e) => setter('id', e.target.value)} />
  else if (['twitter'].indexOf(settings.type) !== -1)
    return <TextField fullWidth label="Einbettcode" variant="outlined"
      InputLabelProps={{ shrink: true }}
      inputProps={{ autoComplete: 'off', style: { fontFamily: 'Consolas' } }}
      placeholder={'Einbettcode eingeben'}
      multiline
      rows={6}
      value={settings.data.code}
      onChange={(e) => setter('code', e.target.value)} />
  else if (['instagram'].indexOf(settings.type) !== -1)
    return <TextField fullWidth label="Einbettcode" variant="outlined"
      InputLabelProps={{ shrink: true }}
      inputProps={{ autoComplete: 'off', style: { fontFamily: 'Consolas' } }}
      placeholder={'Einbettcode eingeben'}
      multiline
      rows={6}
      value={settings.data.code}
      onChange={(e) => setter('code', e.target.value)} />
  else if (['va'].indexOf(settings.type) !== -1)
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Searchbar label={'Team'} type={'team'} onChange={setTeamHandler} value={settings.data.team} />
        <Box sx={{ mt: '10px' }}>
          <Gamelist teamid={settings.data.team.id} onChange={setTm} tmid={settings.data.tmid} />
        </Box>
      </Box>
    )
  else if (['poll'].indexOf(settings.type) !== -1)
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <DateTimePicker
          label="Enddatum"
          value={settings.data.enddate ?? new Date()}
          onChange={(newValue) => changeDatum(newValue)}
          renderInput={(params) => <TextField fullWidth {...params} InputLabelProps={{ shrink: true }} />}
        />
        {settings.data.entries.map((e, i) => (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <TextField fullWidth onChange={(e) => changeEntry(e, i)} value={e.name} />
            <IconButton size={'small'} onClick={() => removeEntry(i)}>
              <CloseIcon />
            </IconButton>
          </Box>
        ))}
        <IconButton sx={{ width: '40px' }} size={'small'} onClick={addEntry}>
          <AddIcon />
        </IconButton>
      </Box>
    )
  else if (['js'].indexOf(settings.type) !== -1)
    return <TextField fullWidth label="Einbettcode" variant="outlined"
      InputLabelProps={{ shrink: true }}
      inputProps={{ autoComplete: 'off', style: { fontFamily: 'Consolas' } }}
      placeholder={'Einbettcode eingeben'}
      multiline
      rows={6}
      value={settings.data.code}
      onChange={(e) => setter('code', e.target.value)} />
  else if (['liste'].indexOf(settings.type) !== -1)
    return <TextField fullWidth label="Einbettcode" variant="outlined"
      InputLabelProps={{ shrink: true }}
      inputProps={{ autoComplete: 'off', style: { fontFamily: 'Consolas' } }}
      placeholder={'Einbettcode eingeben <li>..</li>'}
      multiline
      rows={6}
      value={settings.data.code}
      onChange={(e) => setter('code', e.target.value)} />
  else return null
}

const PContent = (props) => {
  const settings = props.settings;

  const Empty = () => {
    return (
      <Box sx={{ p: '15px', bgcolor: 'gray', width: '100%', height: '100%' }}>
        <Box sx={{
          height: '100%',
          width: '100%',
          border: '1px dashed black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          userSelect: 'none'
        }}>
          Leer
        </Box>
      </Box>
    )
  }

  if (settings.type === 'textbild')
    if (settings.data !== {})
      return (
        <Box component={'img'} sx={{ width: '100%' }} src={settings.data.url} />
      )
    else return (<Empty />)
  else if (settings.type === 'youtube')
    if (settings.data !== {})
      return (
        <iframe
          src={'https://www.youtube.com/embed/' + settings.data.id}
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
          title="video"
        />
      )
    else return (<Empty />)
  else if (settings.type === 'twitter')
    if (settings.data !== {})
      return (
        <Box dangerouslySetInnerHTML={{ __html: settings.data.code }} />
      )
    else return (<Empty />)
  else if (settings.type === 'instagram')
    if (settings.data !== {})
      /*return (
        <InstagramEmbed
          url={settings.data.url}
          clientAccessToken={'1881674728765353|f6igl4Szr4ksYes8luQOvtVInB8'}
          maxWidth={320}
          hideCaption={false}
          containerTagName='div'
          protocol=''
          injectScript
          onLoading={() => {
          }}
          onSuccess={() => {
          }}
          onAfterRender={() => {
          }}
          onFailure={() => {
          }}
        />)*/
      return <Box dangerouslySetInnerHTML={{ __html: settings.data.code }} />
    else return (<Empty />)
  else if (settings.type === 'va')
    return <Box>Begegnungscode: {settings.data.tmid}</Box>
  else if (settings.type === 'poll')
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <Box>Enddatum: {globals.datetimeToGerman(settings.data.enddate)}</Box>
        {settings.data.entries.map(e =>
          <Box>{e.name}</Box>
        )}
      </Box>
    )
  else if (settings.type === 'js')
    return <Box dangerouslySetInnerHTML={{ __html: settings.data.code }} />
  else if (settings.type === 'liste')
    return <Box>
      <ul dangerouslySetInnerHTML={{ __html: settings.data.code }} />
    </Box>
  else return null
}

const newP = {
  type: 'textbild',
  title: '',
  data: { url: '', id: null },
  align: 'center',
  wrap: false,
  width: 200,
  height: 200,
  open: true,
  text: ''
}

const dataConf = {
  'textbild': { url: '', id: null },
  'youtube': { id: null },
  'twitter': { code: null },
  'instagram': { code: null },
  'va': { team: { id: '', name: '', img: '' }, tmid: '' },
  'poll': { enddate: null, entries: [] },
  'js': { code: '' },
  'liste': { code: '' }
}

const Paragraph = (props) => {

  const [settings, setSettings] = useState({ ...newP });
  const [temp, setTemp] = useState({ ...newP });
  const [news, setNews] = [props.news, props.setNews];
  const caret = { start: useRef(0), end: useRef(0) }

  const index = props.index;

  useEffect(() => {
    setSettings({ ...news.sections[index] })
  }, [news.sections])

  const changeType = (v) => {
    setTemp({ ...temp, type: v, data: dataConf[v] })
  }

  const changeAlign = (_, v) => {
    setTemp({ ...temp, align: v })
  }

  const save = () => {
    setSettings({ ...temp, open: false })
    let s = [...news.sections]
    s[index] = { ...temp, open: false }
    setNews({ ...news, sections: s })
    props.setDirty(true)
  }

  const remove = () => {
    let s = [...news.sections]
    s.splice(index, 1);
    setNews({ ...news, sections: s })
    props.setDirty(true)
  }

  const move = (direction) => {
    let s = [...news.sections]
    var e = s[index];
    s.splice(index, 1);
    s.splice(index + direction, 0, e);
    setNews({ ...news, sections: s })
    props.setDirty(true)
  }

  const updateCaret = (e) => {
    caret.start.current = e.target.selectionStart;
    caret.end.current = e.target.selectionEnd;
  }

  const insertText = (text) => {
    setTemp({
      ...temp,
      text: temp.text.substring(0, caret.start.current) + text + temp.text.substring(caret.end.current)
    })
  }

  return (
    <Box sx={{ border: '1px solid', borderColor: 'text.primary' }}>
      <Modal
        open={settings.open}
        onClose={() => setSettings({ ...settings, open: false })}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          maxHeight: '80vh',
          overflow: 'auto'
        }}>
          <Box display={'flex'} flexDirection={'column'} sx={{ '>*': { m: '10px' } }}>
            {/*<Box sx={{display: 'flex', flexDirection: 'row'}}>
              <Box>
                <ToggleButtonGroup
                  value={settings.align}
                  exclusive
                  onChange={changeAlign}
                  aria-label="text alignment"
                >
                  <ToggleButton value="left" aria-label="left aligned">
                    <FormatAlignLeftIcon/>
                  </ToggleButton>
                  <ToggleButton value="center" aria-label="centered">
                    <FormatAlignCenterIcon/>
                  </ToggleButton>
                  <ToggleButton value="right" aria-label="right aligned">
                    <FormatAlignRightIcon/>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Switch checked={settings.wrap}
                        onChange={(e) => setSettings({...settings, wrap: e.target.checked})}/>
                <span>Wrap</span>
              </Box>
            </Box>*/}
            <FormControl sx={{ mt: '20px' }} fullWidth>
              <InputLabel shrink>Type</InputLabel>
              <Select
                fullWidth
                input={<OutlinedInput notched label={'Type'} />}
                value={temp.type}
                onChange={(e) => changeType(e.target.value)}
              >
                <MenuItem value={'textbild'}>Image</MenuItem>
                <MenuItem value={'youtube'}>Youtube</MenuItem>
                <MenuItem value={'twitter'}>Twitter</MenuItem>
                <MenuItem value={'instagram'}>Instagram</MenuItem>
                <MenuItem value={'va'}>VA</MenuItem>
                <MenuItem value={'poll'}>Poll</MenuItem>
                <MenuItem value={'js'}>HTML</MenuItem>
                <MenuItem value={'liste'}>Liste</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label={'Title'}
              InputLabelProps={{ shrink: true }}
              value={temp.title}
              onChange={(e) => setTemp({ ...temp, title: e.target.value })} />
            <Box>
              <Data settings={temp} setSettings={setTemp} />
            </Box>
            <Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                    <Box>
                      <Button sx={{
                        backgroundColor: 'gray',
                        border: '1px solid black',
                        pl: '6px',
                        pr: '6px',
                        minHeight: 0,
                        minWidth: 0
                      }} variant={'contained'} onClick={() => insertText('<b></b>')}>
                        <FormatBoldIcon />
                      </Button>
                      <Button sx={{
                        backgroundColor: 'gray',
                        border: '1px solid black',
                        pl: '6px',
                        pr: '6px',
                        minHeight: 0,
                        minWidth: 0
                      }} variant={'contained'} onClick={() => insertText('<i></i>')}>
                        <FormatItalicIcon />
                      </Button>
                      <Button sx={{
                        backgroundColor: 'gray',
                        border: '1px solid black',
                        pl: '6px',
                        pr: '6px',
                        minHeight: 0,
                        minWidth: 0
                      }} variant={'contained'} onClick={() => insertText('<i></i>')}>
                        <FormatUnderlinedIcon />
                      </Button>
                      <Button sx={{
                        backgroundColor: 'gray',
                        border: '1px solid black',
                        pl: '6px',
                        pr: '6px',
                        minHeight: 0,
                        minWidth: 0
                      }} variant={'contained'} onClick={() => insertText('<a href="LINK"></a>')}>
                        <LinkIcon />
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  multiline
                  value={temp.text}
                  rows={6}
                  onChange={(e) => {
                    setTemp({ ...temp, text: e.target.value })
                    updateCaret(e)
                  }} onMouseUp={(e) => updateCaret(e)} onKeyUp={(e) => updateCaret(e)} />
              </Box>
            </Box>
            <Button variant={'contained'} onClick={save}>Speichern</Button>
          </Box>
        </Box>
      </Modal>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ width: '100%', height: '40px', borderBottom: '1px solid', borderColor: 'text.primary' }}>
          <Box sx={{ float: 'left', height: '100%', lineHeight: '40px', paddingLeft: '10px' }}>{settings.type}</Box>
          <IconButton sx={{ float: 'right' }} aria-label="" onClick={remove}>
            <CloseIcon />
          </IconButton>
          <IconButton sx={{ float: 'right' }} aria-label="" onClick={() => {
            setTemp({ ...settings })
            setSettings({ ...settings, open: true })
          }}>
            <EditIcon />
          </IconButton>
          <IconButton sx={{ float: 'right' }} aria-label="" onClick={() => move(1)}>
            <ArrowDropDownIcon />
          </IconButton>
          <IconButton sx={{ float: 'right' }} aria-label="" onClick={() => move(-1)}>
            <ArrowDropUpIcon />
          </IconButton>
        </Box>
        <Typography variant={'h5'}>{settings.title}</Typography>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: settings.align
          }}>
          {settings.type !== '' ?
            <Box sx={{
              pt: '10px',
              pb: '10px',
              //width: settings.width + 'px',
              //height: settings.height + 'px',
              //resize: 'both',
              overflow: 'auto'
            }}
            /*onMouseUp={(e) => setSettings({
              ...settings,
              height: e.currentTarget.offsetHeight,
              width: e.currentTarget.offsetWidth
            })}*/
            >
              <PContent settings={settings} />
            </Box> : null}
        </Box>
        <Box>{settings.text}</Box>
      </Box>
    </Box>
  )
}

const Imagesearch = (props) => {

  const [modal, setModal] = useState(false)
  const [images, setImages] = useState('');
  const [image, setImage] = useState(props.image ?? { id: null, url: '' });
  const [searching, setSearching] = useState(true);
  const [loadmoref, loadmorefSet] = useState(true);
  const page = useRef(1);
  const currentsearch = useRef('');

  const onChange = props.onChange ?? null;

  useEffect(() => {
    go()
  }, [])

  useEffect(() => {
    let img = props.image;
    if (props.image == null) {
      img = { id: null, url: '' }
    }
    setImage(img)
  }, [props.image])

  useEffect(() => {
    go()
  }, [props.type, props.id])

  const go = (e = null) => {
    page.current = 1;
    setSearching(true)
    let search = e ? e.target.value : '';
    currentsearch.current = search;
    setImages([]);
    apis.imagesget({ search: search, type: props.type ?? null, id: props.id ?? null })
      .then((response) => response.json())
      .then((json) => {
        setSearching(false)
        setImages(json)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const selectImg = (img) => {
    setModal(false)
    setImage({ id: img.id, url: img.src })
    if (onChange != null)
      onChange(img)
    if (props.setDirty != null)
      props.setDirty(true)
  }

  const remove = () => {
    setImage({ url: '', id: null })
    if (onChange != null)
      onChange(null)
    if (props.setDirty != null)
      props.setDirty(true)
  }

  const loadmore = () => {
    page.current++;
    apis.imagesget({ page: page.current, search: currentsearch.current })
      .then((response) => response.json())
      .then((json) => {
        setImages({ ...images, images: [...images.images, ...json.images] })
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <Box>
      <Modal
        open={modal}
        onClose={() => setModal(false)}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Box display={'flex'} flexDirection={'column'}>
            <Box>
              <TextField sx={{ float: 'right', width: '200px' }} label="Suchen" variant="outlined"
                InputLabelProps={{ shrink: true }}
                inputProps={{ autoComplete: 'off', sx: { height: '30px', p: '8px' } }} onChange={(e) => go(e)} />
            </Box>
            <Divider sx={{ m: '10px' }} />
            {searching ?
              <Box sx={{ width: '100%', minHeight: '400px' }}>
                <LinearProgress />
              </Box>
              :
              <Box sx={{ minHeight: '400px', maxHeight: '400px', overflow: 'auto' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Box sx={{ '>*': { m: '10px' } }}>
                    {images.images && images.images.map(img => (
                      <Box key={img.vid} component={'img'} src={img.src} onClick={() => selectImg(img)}
                        sx={{ maxWidth: '200px', display: 'inline-block', cursor: 'pointer' }} />
                    ))}
                  </Box>
                  {loadmoref ?
                    <Button variant={'contained'} sx={{ width: '150px' }} onClick={loadmore}>mehr laden</Button>
                    : null}
                </Box>
              </Box>
            }
          </Box>
        </Box>
      </Modal>
      <InputLabel shrink>Artikelbild</InputLabel>
      <Box sx={{ border: '1px solid', borderColor: 'text.primary' }}>
        {image.url !== '' ?
          <Box sx={{ position: 'relative' }}>
            <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} aria-label="close" onClick={remove}
              color={'error'}>
              <CloseIcon />
            </IconButton>
            <Box component={'img'} src={image.url} sx={{ maxWidth: '100%' }} />
          </Box>
          :
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70px' }}>
            <Button variant={'contained'} onClick={() => {
              go()
              setModal(true)
            }}>Suchen</Button>
          </Box>}
      </Box>
    </Box>
  )
}

const newSource = {
  type: 'ONLINE',
  source: '',
}

const newNews = {
  id: '',
  date: globals.getNow(),
  image: { url: '', id: null },
  seo: { title: '', description: '' },
  tags: [],
  status: 'new',
  type: 4,
  autor: { id: '', name: '' },
  melder: { id: '', name: '' },
  approved: false,
  api: false,
  banned_games_count: 1,
  sections: [],
  source: { ...newSource },
  additional_sources: []
}

export default function News(props) {

  const [news, setNews] = useState({ ...newNews });
  const texts = { text: useRef(), title: useRef(), seo_title: useRef(), seo_description: useRef() }
  const count = { words: useRef(), chars: useRef() }
  const seotitle_count = { preview: useRef(), chars: useRef(0), pixels: useRef(0) }
  const seodesc_count = { chars: useRef(0) }
  const caret = { start: useRef(0), end: useRef(0) }
  const [tagsearch, setTagsearch] = useState({ name: '', id: '', img: '' });
  const [main, setMain] = useState(null);
  const [profile, setProfile] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [working, setWorking] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [suppress, setSuppress] = useState(false);
  const [info, setInfo] = useState(false);
  const dispatch = useDispatch()
  const params = useParams();
  const page = useSelector(state => state.page.value)
  const [del, setDel] = useState({ open: false, type: 5, text: '' })
  const navigate = useNavigate();
  const [checks, setChecks] = useState({ tags: false, title: false, icon: false, text: false, source: false, all: false })

  useEffect(() => {
    checkTags()
  }, [news.tags])

  useEffect(() => {
    setTimeout(() => {
      validate()
    }, 50)
  }, [news])

  useEffect(() => {

    let p = new URLSearchParams(document.location.search).get('value');
    let t = new URLSearchParams(document.location.search).get('type');

    if (suppress) {
      setSuppress(false)
      return false;
    }
    window.scrollTo(0, 0)
    setDirty(false);
    let id = params.id;
    if (id != null) {
      setLoading(true);
      apis
        .newsget(id)
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          if (json.id != null) {
            dispatch(set_page_title('News: Bearbeiten'))
            setNews(json)
            setTimeout(() => {
              texts.text.current.value = json.text;
              texts.title.current.value = json.title;
              texts.seo_title.current.value = json.seo.title;
              texts.seo_description.current.value = json.seo.description;
              count.words.current.innerHTML = 'Wörter: ' + (json.text.length === 0 ? '0' : json.text.trim().split(' ').length)
              count.chars.current.innerHTML = 'Zeichen: ' + json.text.length;
              setSeoCount()
            }, 50)
          } else {
            setMessage('News nicht gefunden!')
            setInfo(true)
          }
          setLoading(false)
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setLoading(false)
      setProfile(null)
      setNews(globals.clone(newNews))
      setTimeout(() => {
        texts.text.current.value = '';
        texts.title.current.value = '';
        texts.seo_title.current.value = '';
        texts.seo_description.current.value = '';
        count.words.current.innerHTML = 'Wörter: 0';
        count.chars.current.innerHTML = 'Zeichen: 0';
        setSeoCount()
      }, 50)
      dispatch(set_page_title('News: NEU'))
      dispatch(set_searchbar({ name: '', id: '', img: '', type: 'spieler' }))
      // add tags
      if (p != null && t != null) {
        apis
          .tagsget({ type: t, value: p })
          .then((response) => response.json())
          .then((j) => {
            console.log(j);
            let n = { ...news }
            n.tags = [...j]
            setNews(n)
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [page])

  const checkTags = () => {
    let pfound = false;
    let tfound = false;
    let p;
    news.tags.forEach(e => {
      if (pfound)
        return false;
      if (e.type === 'Spieler') {
        pfound = true;
        p = e;
      }
      if (!tfound && e.type === 'Team') {
        tfound = true;
        p = e;
      }
    })
    if (typeof p != 'undefined') {
      setMain(p)
      apis.profileget(p)
        .then((response) => response.json())
        .then((json) => {
          if (json !== []) {
            setProfile(json)
            dispatch(set_searchbar({ name: json.name, id: json.id, img: json.photo, type: json.type }))
          } else {
            dispatch(set_searchbar({ name: '', id: '', img: '', type: 'spieler' }))
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setMain(null)
      setProfile(null)
      dispatch(set_searchbar({ name: '', id: '', img: '', type: 'spieler' }))
    }
  }

  const addTag = (v) => {
    setTagsearch({ name: '', id: '', img: '' })
    if (v == null || v.id === '') {
      return false
    }
    let d = { ...news };
    let c = false;
    d.tags.forEach(e => {
      if (e.value === v.id && e.type === v.type)
        c = true;
    })
    if (c) return false;
    //const conf = {Spieler: 'player', Team: 'team', Wettbewerb: 'wettbewerb', Tag: 'tag'};
    let x = { value: v.id, name: v.name, id: '', type: v.type }
    d.tags.push(x);
    setNews(d);
    checkTags();
    setDirty(true)
  }

  const removeTag = (id) => {
    let d = { ...news };
    d.tags.forEach((e, i) => {
      if (e.id === id)
        d.tags.splice(i, 1)
    })
    setNews(d);
    checkTags();
    setDirty(true)
  }

  const addParagraph = () => {
    let n = { ...news }
    n.sections.push({ ...newP })
    setNews(n)
    setDirty(true)
  }

  const setAutor = (v) => {
    if (v == null)
      setNews({ ...news, autor: { name: '', id: '' } })
    else
      setNews({ ...news, autor: v })
    setDirty(true)
  }

  const setMelder = (v) => {
    if (v == null)
      setNews({ ...news, melder: { name: '', id: '' } })
    else
      setNews({ ...news, melder: v })
    setDirty(true)
  }

  const addSource = () => {
    let n = { ...news }
    n.additional_sources.push({ ...newSource })
    setNews(n)
    setDirty(true)
  }

  const changeIcon = (v) => {
    setNews({ ...news, icon: v.id })
    setDirty(true)
  }

  const changeStatus = (_, v) => {
    setNews({ ...news, status: v })
    setDirty(true)
  }

  const setImg = (img) => {
    let n = { ...news }
    let t = { id: '', url: '' }
    if (img != null)
      t = { id: img.id, url: img.src }
    n.image = { ...t }
    if (!dirty) setDirty(true)
    setNews(n)
  }

  const changeDatum = (v) => {
    let n = { ...news };
    n.date = v
    if (!dirty) setDirty(true)
    setNews(n)
  }

  const changeType = (v) => {
    let n = { ...news }
    n.type = v
    if (!dirty) setDirty(true)
    setNews(n)
  }

  const changeBanned = (e) => {
    const test = news.banned_games_count;
    if (isNaN(e.target.value)) {
      setNews({ ...news, banned_games_count: test ?? '' })
    } else {
      setNews({ ...news, banned_games_count: e.target.value })
    }
    if (!dirty) setDirty(true)
  }

  const goDel = () => {
    apis
      .newsset({ action: 'delete', del: del, news: news })
      .then((response) => response.json())
      .then((json) => {
        if (json.code === 1) {
          navigate("/search/news/", { replace: true })
          dispatch(set_page_title('News: NEU'))
          setDirty(false)
          setNews({ ...newNews })
          setDel({ ...del, open: false })
        } else {
          setMessage(json.msg)
          setInfo(true)
        }
        console.log(json);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const updateCaret = (e) => {
    caret.start.current = e.target.selectionStart;
    caret.end.current = e.target.selectionEnd;
  }

  const insertText = (text) => {
    texts.text.current.value = texts.text.current.value.substring(0, caret.start.current) + text + texts.text.current.value.substring(caret.end.current);
  }

  const validate = () => {

    let image = true;
    if ((news.image.id == null || news.image.id === '') && news.image.url === '')
      image = false;

    let count1 = false
    if (texts.seo_title.current != null)
      count1 = texts.seo_title.current.value.length <= 68;
    let count2 = false
    if (texts.seo_description.current != null)
      count2 = texts.seo_description.current.value.length <= 140;

    //Quelle Type ermitteln
    var sourceType = news.source.type
    //Title ermitteln
    var newsTitle = texts.title.current != null ? texts.title.current.value : ''
    //Text Counter ermitteln
    var newsTextCounter = texts.text.current != null ? texts.text.current.value.length : ''

    var newsTitleSuccess;
    var tagSuccess;
    var statusIconSuccess;
    var newsTextSuccess;
    var newsSourceSuccess;

    // PRÜFUNG SOURCE
    if (sourceType === 'OFFLINE') {
      let magazinName = news.source.source

      newsSourceSuccess = magazinName.length > 0;
    } else if (sourceType === 'ONLINE' || sourceType === 'VA' || sourceType === 'RESULT') {
      var sourceUrl = news.source.source
      newsSourceSuccess = sourceUrl.length >= 4;
    } else {
      newsSourceSuccess = true;
    }

    // PRÜFUNG TITLE LÄNGE
    newsTitleSuccess = newsTitle.length >= 5 && newsTitle.length <= 80;

    // PRÜFUNG TEXT LÄNGE
    newsTextSuccess = newsTextCounter >= -1;

    // PRÜFUNG TAGS
    var existTags = '';

    news.tags.forEach(e => {

      if (e.type === 'Spieler') {
        existTags += 'player';
      } else if (e.type === 'Team') {
        existTags += 'team';
      } else if (e.type === 'Wettbewerb') {
        existTags += 'competition';
      }
    });

    tagSuccess = (existTags.indexOf('player') >= 0 || existTags.indexOf('team') >= 0) && existTags.indexOf('competition') >= 0;

    // PRÜFUNG ICONS
    statusIconSuccess = typeof news.icon != 'undefined';

    // FINAL STEP: if all steps are ready, enable post-news button
    const all = tagSuccess === true &&
      newsTitleSuccess === true &&
      statusIconSuccess === true &&
      newsTextSuccess === true &&
      image === true &&
      count1 && count2 &&
      newsSourceSuccess === true;

    const final = {
      tags: tagSuccess,
      title: newsTitleSuccess,
      icon: statusIconSuccess,
      text: newsTextSuccess,
      source: newsSourceSuccess,
      all: all
    }

    if (JSON.stringify(final) !== JSON.stringify(checks))
      setChecks({ ...final })
  }

  const setSeoCount = () => {
    let color = 'green';
    let count = 0;
    let pixels = 0;

    // seo title pixels
    seotitle_count.preview.current.innerHTML = texts.seo_title.current.value
    pixels = Math.ceil(seotitle_count.preview.current.clientWidth)
    if (pixels > 545)
      color = 'red'
    seotitle_count.pixels.current.innerHTML = pixels;
    seotitle_count.pixels.current.style.color = color;
    // seo title chars
    color = 'green';
    count = texts.seo_title.current.value.length;
    if (count > 68)
      color = 'red';
    seotitle_count.chars.current.innerHTML = count;
    seotitle_count.chars.current.style.color = color
    // seo desc chars
    color = 'green';
    count = texts.seo_description.current.value.length;
    if (count > 140)
      color = 'red';
    seodesc_count.chars.current.innerHTML = count;
    seodesc_count.chars.current.style.color = color
  }

  const submit = () => {
    let n = { ...news };
    // set text of news
    n.text = texts.text.current.value;
    n.title = texts.title.current.value;
    n.seo.title = texts.seo_title.current.value;
    n.seo.description = texts.seo_description.current.value;
    console.log(n);
    setWorking(true)
    apis
      .newsset(n)
      .then((response) => response.json())
      .then((json) => {
        if (json.code === 1) {
          setSuccess(true)
          setWorking(false)
          setNews({ ...news, id: json.newsid })
          setSuppress(true)
          navigate("/search/news/" + json.newsid, { replace: true })
          dispatch(set_page_title('News: Bearbeiten'))
          setDirty(false)
        } else {
          setMessage(json.msg)
          setInfo(true)
          setWorking(false)
        }
        console.log(json);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const Source = (props) => {

    const index = props.index ?? null

    let obj;
    if (props.removable)
      obj = news.additional_sources[index]
    else
      obj = news.source

    const remove = () => {
      let n = { ...news }
      n.additional_sources.splice(index, 1);
      setNews(n)
      if (!dirty)
        setDirty(true)
    }

    const changeType = (e) => {
      let n = { ...news }
      if (props.removable)
        n.additional_sources[index].type = e.target.value
      else
        n.source.type = e.target.value
      setNews(n)
      if (!dirty)
        setDirty(true)
    }

    const changeSource = (e) => {
      let n = { ...news }
      if (props.removable)
        n.additional_sources[index].source = e.target.value
      else
        n.source.source = e.target.value
      setNews(n)
      if (!dirty)
        setDirty(true)
    }

    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '20px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <FormControl fullWidth>
              <InputLabel shrink>Art</InputLabel>
              <Select
                fullWidth
                input={<OutlinedInput notched label={'Art'} />}
                value={obj.type}
                onChange={changeType}
              >
                <MenuItem value={'ONLINE'}>Online</MenuItem>
                <MenuItem value={'OFFLINE'}>Offline</MenuItem>
                <MenuItem value={'SELF'}>Eigene News</MenuItem>
                <MenuItem value={'CONF'}>Pressekonferenz</MenuItem>
                <MenuItem value={'VA'}>Aufstellung</MenuItem>
                <MenuItem value={'RESULT'}>Ergebnis</MenuItem>
              </Select>
            </FormControl>
            {props.removable ?
              <IconButton size={'small'} aria-label="close"
                onClick={remove}>
                <CloseIcon />
              </IconButton>
              : <Box />}
          </Box>
          {['SELF', 'CONF'].indexOf(obj.type) === -1 ?
            <Box sx={{ mt: '10px' }}>
              <TextField InputLabelProps={{ shrink: true }} label={'Quelle'} fullWidth
                value={props.removable ? news.additional_sources[index].source : news.source.source}
                onChange={changeSource} />
            </Box>
            : null}
        </Box>
      </>
    )
  }

  if (loading)
    return <LoadingScreen />
  else
    return (
      <>
        <Modal
          open={del.open}
          onClose={() => setDel({ ...del, open: false })}
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            maxHeight: '80vh',
            overflow: 'auto'
          }}>
            <Box display={'flex'} flexDirection={'column'} sx={{ '>*': { m: '10px' } }}>
              <FormControl sx={{ mt: '20px' }} fullWidth>
                <Typography variant={'h5'}>{news.melder.name}</Typography>
                <Select
                  fullWidth
                  value={del.type}
                  onChange={(e) => setDel({ ...del, type: e.target.value })}
                >
                  <MenuItem value={5}>Keine Nachricht</MenuItem>
                  <MenuItem value={0}>Individuelle Nachricht</MenuItem>
                  <MenuItem value={1}>Nachricht doppelt</MenuItem>
                  <MenuItem value={2}>Verbotene Quelle</MenuItem>
                  <MenuItem value={3}>Nicht relevant</MenuItem>
                  <MenuItem value={4}>Falscher Eintrag</MenuItem>
                </Select>
              </FormControl>
              <Box>
                <TextField
                  fullWidth
                  label={'Text'}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  value={del.text}
                  disabled={del.type === 5}
                  rows={6} onChange={(e) => setDel({ ...del, text: e.target.value })} />
              </Box>
              <Button variant={'contained'} onClick={goDel} color={'error'}>Löschen</Button>
            </Box>
          </Box>
        </Modal>
        <Info message={"Server meldet: " + message} open={info}
          cancel={() => setInfo(false)} />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={success}
          onClose={() => setSuccess(false)}
          autoHideDuration={4000}
          key={'playersnack'}>
          <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
            Speichern erfolgreich!
          </Alert>
        </Snackbar>
        {working ? <Button sx={{ position: 'fixed', bottom: 10, right: 10, visibility: dirty ? 'visible' : 'hidden' }}
          color={'success'} variant="contained">
          <CircularProgress size={14} sx={{ color: 'black' }} />
        </Button> : <Button
          sx={{ position: 'fixed', bottom: 10, right: 10, visibility: (dirty && checks.all) ? 'visible' : 'hidden' }}
          color={'success'} variant="contained"
          endIcon={<CheckIcon />} onClick={submit}>
          Speichern
        </Button>}
        <Box ref={seotitle_count.preview} sx={{
          color: 'rgba(0, 0, 0, 0)',
          pointerEvents: 'none',
          userSelect: 'none',
          position: 'absolute',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          fontFamily: 'arial,sans-serif',
          fontSize: '20px',
          fontWeight: 400,
          letterSpacing: 0
        }} />
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 7, '>*:not(:first-child)': { mt: 5 } }}>
          <Box sx={{ width: '80%', display: 'flex', flexDirection: 'column', mt: 7, '>*:not(:first-child)': { mt: 5 } }}>
            <TextField fullWidth label="Headline*" variant="outlined"
              inputRef={texts.title}
              InputLabelProps={{ shrink: true }} inputProps={{ autoComplete: 'off' }}
              value={null} onChange={() => {
                if (!dirty) setDirty(true);
                validate();
              }} />
            <Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>Desktop-Empfehlung: <Box
                style={{ color: 'green' }} component={'span'} ref={seotitle_count.pixels}>0</Box>/545px Maximale
                Zeichenlänge: <Box style={{ color: 'green' }} component={'span'} ref={seotitle_count.chars}>0</Box>/68
                Zeichen</Box>
              <TextField fullWidth label="SEO Title*" variant="outlined"
                inputRef={texts.seo_title}
                InputLabelProps={{ shrink: true }} inputProps={{ autoComplete: 'off' }}
                value={null} onChange={() => {
                  if (!dirty) setDirty(true);
                  validate(); setSeoCount()
                }} />
            </Box>
            <Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>Maximale Zeichenlänge: <Box
                style={{ color: 'green' }} component={'span'} ref={seodesc_count.chars}>0</Box>/140</Box>
              <TextField fullWidth label="SEO Description*" variant="outlined"
                inputRef={texts.seo_description}
                InputLabelProps={{ shrink: true }} inputProps={{ autoComplete: 'off' }}
                multiline
                rows={3}
                value={null} onChange={() => {
                  if (!dirty) setDirty(true);
                  validate(); setSeoCount()
                }} />
            </Box>
            <Box>
              <Divider textAlign={"left"} sx={{ pt: 3 }}>Icon*:</Divider>
              <Iconlist onChange={changeIcon} id={news.icon} conf={{
                rowcount: 4,
                filter: [22, 23, 12, 2, 15, 10, 25, 1, 26, 6, 5, 7, 13, 14, 18, 8, 17, 9, 11, 21, 4, 3, 16, 19]
              }} />
              {[5, 6, 7].indexOf(news.icon) !== -1 ?
                <TextField sx={{ mt: '20px' }} fullWidth label="Fehlt für x Spiele*" variant="outlined"
                  InputLabelProps={{ shrink: true }} inputProps={{ autoComplete: 'off' }}
                  value={news.banned_games_count} onChange={changeBanned} />
                : null}
            </Box>
            <Imagesearch onChange={setImg} image={news.image} type={main ? main.type : null}
              id={main ? main.value : null}
              setDirty={setDirty} />
            <Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Box>
                      <Button sx={{
                        backgroundColor: 'gray',
                        border: '1px solid black',
                        pl: '6px',
                        pr: '6px',
                        minHeight: 0,
                        minWidth: 0
                      }} variant={'contained'} onClick={() => insertText('<b></b>')}>
                        <FormatBoldIcon />
                      </Button>
                      <Button sx={{
                        backgroundColor: 'gray',
                        border: '1px solid black',
                        pl: '6px',
                        pr: '6px',
                        minHeight: 0,
                        minWidth: 0
                      }} variant={'contained'} onClick={() => insertText('<i></i>')}>
                        <FormatItalicIcon />
                      </Button>
                      <Button sx={{
                        backgroundColor: 'gray',
                        border: '1px solid black',
                        pl: '6px',
                        pr: '6px',
                        minHeight: 0,
                        minWidth: 0
                      }} variant={'contained'} onClick={() => insertText('<i></i>')}>
                        <FormatUnderlinedIcon />
                      </Button>
                      <Button sx={{
                        backgroundColor: 'gray',
                        border: '1px solid black',
                        pl: '6px',
                        pr: '6px',
                        minHeight: 0,
                        minWidth: 0
                      }} variant={'contained'} onClick={() => insertText('<a href="LINK"></a>')}>
                        <LinkIcon />
                      </Button>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box sx={{ mr: '30px' }} ref={count.words}>Wörter: 0</Box>
                      <Box ref={count.chars}>Zeichen: 0</Box>
                    </Box>
                  </Box>
                </Box>
                <TextField fullWidth multiline inputRef={texts.text} onChange={(e) => {
                  if (!dirty) setDirty(true);
                  count.words.current.innerHTML = 'Wörter: ' + (texts.text.current.value.length === 0 ? '0' : texts.text.current.value.trim().split(' ').length);
                  count.chars.current.innerHTML = 'Zeichen: ' + texts.text.current.value.length;
                  updateCaret(e)
                  validate()
                }} onMouseUp={(e) => updateCaret(e)} onKeyUp={(e) => updateCaret(e)} rows={10} />
              </Box>
            </Box>
            {news.sections.map((e, i) =>
              <Paragraph index={i} setNews={setNews} news={news} setDirty={setDirty} />
            )}
            <Button onClick={addParagraph} variant={'contained'}>+</Button>
          </Box>
          <Divider sx={{ ml: '15px', height: '100vh' }} orientation={'vertical'}></Divider>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ ml: '15px' }}>
              {news.id !== '' ?
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Button color={'error'} variant={'contained'} sx={{ mb: '30px' }}
                    onClick={() => setDel({ ...del, open: true })}>Löschen</Button>
                  <Button color={'primary'} variant={'contained'} sx={{ mb: '30px', ml: '20px' }}
                    onClick={() => window.open('https://hossidev.ligainsider.de/' + news.url, '_blank')}><VisibilityIcon /></Button>
                </Box> : null}
              <Box sx={{ mb: '10px' }}>
                <DateTimePicker
                  label="Datum"
                  value={news.date ?? new Date()}
                  onChange={(newValue) => changeDatum(newValue)}
                  renderInput={(params) => <TextField fullWidth {...params} InputLabelProps={{ shrink: true }} />}
                />
              </Box>
              <Searchbar value={tagsearch} noImg label={'Tags'} type={'wettbewerb-spieler-team-tag'}
                customInputProps={{ style: { padding: 0 } }} sx={{ width: '300px' }} onChange={addTag} />
              <Box sx={{ mt: '20px' }}>
                {news.tags.map(e => (
                  <Box
                    sx={{ display: 'inline-block', borderRadius: '14px', backgroundColor: 'gray', pl: '10px', m: '5px' }}>
                    <Box sx={{ display: 'inline-block' }} component={'span'}>{e.name}</Box>
                    <IconButton size={'small'} sx={{ position: 'inline-block', top: 0, right: 0 }} aria-label="close"
                      onClick={() => removeTag(e.id)}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
              {profile ? <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '20px' }}>
                <Avatar sx={{ width: '50px', height: '50px', mr: '20px' }} src={profile.photo} />
                <Box>
                  {profile.name}
                </Box>
              </Box> : null}
              <Divider sx={{ mt: '10px' }} />
              <Box sx={{ display: 'flex', flexDirection: 'row', mt: '10px' }}>
                <Searchbar value={news.autor} noImg label={'Autor'} type={'userid'}
                  customInputProps={{ style: { padding: 0 } }} sx={{ width: '50%' }} onChange={setAutor} />
                <Searchbar value={news.melder} noImg label={'Melder'} type={'userid'}
                  customInputProps={{ style: { padding: 0 } }} sx={{ width: '50%' }} onChange={setMelder} />
              </Box>
              <Box sx={{ mt: '5px' }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '12px',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                  {checks.tags ? <CheckCircleIcon color={'success'} fontSize={'10px'} />
                    : <CancelIcon color={'error'} fontSize={'10px'} />}
                  Tags <Box>|</Box> {checks.title ? <CheckCircleIcon color={'success'} fontSize={'10px'} />
                    : <CancelIcon color={'error'} fontSize={'10px'} />}
                  Headline <Box>|</Box> {checks.icon ? <CheckCircleIcon color={'success'} fontSize={'10px'} />
                    : <CancelIcon color={'error'} fontSize={'10px'} />}
                  Icon <Box>|</Box> {checks.text ? <CheckCircleIcon color={'success'} fontSize={'10px'} />
                    : <CancelIcon color={'error'} fontSize={'10px'} />}
                  Text <Box>|</Box> {checks.source ? <CheckCircleIcon color={'success'} fontSize={'10px'} />
                    : <CancelIcon color={'error'} fontSize={'10px'} />}
                  Quelle
                </Box>
              </Box>
              <Divider textAlign={'left'} sx={{ mt: '20px' }}>News / Art</Divider>
              <FormControl sx={{ mt: '20px' }} fullWidth>
                <InputLabel shrink>Art</InputLabel>
                <Select
                  fullWidth
                  input={<OutlinedInput notched label={'Art'} />}
                  value={news.type}
                  onChange={(e) => changeType(e.target.value)}
                >
                  <MenuItem value={4}>Normal</MenuItem>
                  <MenuItem value={5}>Top-News</MenuItem>
                  <MenuItem value={10}>Update</MenuItem>
                  <MenuItem value={11}>Dauerkarte-Exklusiv</MenuItem>
                  {news.id !== '' ? <MenuItem value={9}>Aufstellung</MenuItem> : null}
                </Select>
              </FormControl>
              <ToggleButtonGroup
                value={news.status}
                exclusive
                onChange={changeStatus}
                sx={{ mt: '20px' }}
              >
                <ToggleButton value="new" aria-label="left aligned">
                  Neu
                </ToggleButton>
                <ToggleButton value="edit" aria-label="centered">
                  Edit
                </ToggleButton>
                <ToggleButton value="ready" aria-label="right aligned">
                  Ready
                </ToggleButton>
              </ToggleButtonGroup>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '10px' }}>
                <Switch checked={news.approved}
                  onChange={(e) => {
                    setNews({ ...news, approved: e.target.checked })
                    setDirty(true)
                  }} />
                <Box component={'span'}>Veröffentlichen</Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '10px' }}>
                <Switch checked={news.api}
                  onChange={(e) => {
                    setNews({ ...news, api: e.target.checked })
                    setDirty(true)
                  }} />
                <Box component={'span'}>API Freigabe</Box>
              </Box>
              <Divider textAlign={'left'} sx={{ mt: '20px' }}>Quelle</Divider>
              {Source({ index: null, removable: false })}
              {news.additional_sources.map((e, i) => {
                return <><Divider sx={{ mt: '20px' }}></Divider>{Source({ removable: true, index: i })}</>
              })}
              <IconButton size={'small'} aria-label="close"
                onClick={addSource}>
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </>
    )
}
