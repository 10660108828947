
import React, { useEffect, useRef, useState } from 'react';
import { TextField, Grid, Divider, Avatar, Paper } from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CommentIcon from '@mui/icons-material/Comment';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import DeleteIcon from '@mui/icons-material/Delete';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import SendIcon from '@mui/icons-material/Send';
import HealingIcon from '@mui/icons-material/Healing';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DateTimePicker from "@mui/lab/DateTimePicker";
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import Chip from '@mui/material/Chip';

import * as apis from "../providers/apis";


let user = window.localStorage.getItem('user')
if (user != null)
    user = JSON.parse(user)

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
};

export default function WarteschleifeCard(props) {

    const [comments, setComments] = useState([]);
    const [commentBody, setCommentBody] = useState('');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // do you really want to delete
    const [deleteOptions, setDeleteOptions] = useState({ type: 5, text: '' })
    const [openModalDelete, setOpenModalDelete] = React.useState(false);
    const handleOpenModalDelete = () => setOpenModalDelete(true);
    const handleCloseModalDelete = () => setOpenModalDelete(false);

    // do you really want to publish
    const [openModalPublish, setOpenModalPublish] = React.useState(false);
    const handleOpenModalPublish = () => setOpenModalPublish(true);
    const handleCloseModalPublish = () => setOpenModalPublish(false);

    //DateTimePicker
    const [datePublish, setDatePublish] = React.useState(new Date());

    const handleChangeDatePublish = (newValue) => {
        setDatePublish(newValue);
    };

    useEffect(() => {
        setComments(props.content.comments);
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleComment = (body) => {
        setCommentBody(body);
    };

    const removeTimer = () => {
        props.removeTimer(props);
    };

    const handleDelete = () => {
        setOpenModalDelete(false);
        props.removeItem(props, deleteOptions);
    };

    const handlePublish = () => {
        setOpenModalPublish(false);
        props.publishItem(props, datePublish);
    };

    function handleSubmitInternalComment() {
        apis
            .internalnewscommentset(props.content.newsid, user.id, commentBody) //check if usage of user is correct
            .then((response) => response.json())
            .then((json) => {
                if (json !== 0) {
                    console.log('Kommentar hinzugefügt');
                    setComments([...comments, { commentid: json.commentid, username: user.name, date: "now", body: commentBody }]);
                    setCommentBody('');
                } else {
                    console.log('Kommentar fehler');
                }
            })
            .catch((error) => {
                console.error(error);
                console.log('Kommentar fehler');
            });
    };

    //TODO kommentare, für die es noch keine ID gibt löschen
    function handleDeleteInternalComment(commentid, index) {
        const commentsCopy = [...comments];
        commentsCopy.splice(index, 1);

        if (commentid !== "undefined") {
            apis
                .internalnewscommentdelete(user.id, commentid)
                .then((response) => response.json())
                .then((json) => {
                    if (json == 1) {
                        console.log('Kommentar gelöscht');
                        setComments(commentsCopy);
                    } else {
                        console.log('Kommentar fehler');
                    }
                })
                .catch((error) => {
                    console.error(error);
                    console.log('Kommentar fehler');
                });
        }
    };

    var newscol;
    switch (props.content.newsstatus) {
        case "ready":
            newscol = '#66bb6a'
            break;
        case "edit":
            newscol = '#ce93d8'
            break;
        default:
            newscol = '#42a5f5'
    }

    return (
        <Card border={5} sx={{ maxWidth: 250, maxHeight: 350, minHeight: 350, minWidth: "100%" }} style={{ border: "1px solid black" }} >
            <Grid container flexDirection="row" justifyContent={"center"} alignItems={"center"}>
                {/* new Delete Modal */}
                <Modal
                    open={openModalDelete}
                    onClose={handleCloseModalDelete}
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        maxHeight: '80vh',
                        overflow: 'auto'
                    }}>
                        <Box display={'flex'} flexDirection={'column'} sx={{ '>*': { m: '10px' } }}>
                            <FormControl sx={{ mt: '20px' }} fullWidth>
                                <Typography variant={'h5'}>{props.content.username}</Typography>
                                <Select
                                    fullWidth
                                    value={deleteOptions.type}
                                    onChange={(e) => setDeleteOptions({ ...deleteOptions, type: e.target.value })}
                                >
                                    <MenuItem value={5}>Keine Nachricht</MenuItem>
                                    <MenuItem value={0}>Individuelle Nachricht</MenuItem>
                                    <MenuItem value={1}>Nachricht doppelt</MenuItem>
                                    <MenuItem value={2}>Verbotene Quelle</MenuItem>
                                    <MenuItem value={3}>Nicht relevant</MenuItem>
                                    <MenuItem value={4}>Falscher Eintrag</MenuItem>
                                </Select>
                            </FormControl>
                            <Box>
                                <TextField
                                    fullWidth
                                    label={'Text'}
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    value={deleteOptions.text}
                                    disabled={deleteOptions.type === 5}
                                    rows={6} onChange={(e) => setDeleteOptions({ ...deleteOptions, text: e.target.value })} />
                            </Box>
                            <Button variant={'contained'} onClick={handleDelete} color={'error'}>Löschen</Button>
                        </Box>
                    </Box>
                </Modal>
                {/* Modal Publish */}
                <Modal
                    open={openModalPublish}
                    onClose={handleCloseModalPublish}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Grid container sx={{
                        position: 'absolute',
                        top: '25%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        height: 200,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }} alignItems="center" justifyContent="center">
                        <DateTimePicker
                            label="Zeitpunkt der Veröffentlichung"
                            inputFormat="dd/MM/yyyy hh:mm"
                            mask={"__/__/____ __:__"}
                            value={datePublish}
                            onChange={(newValue) => handleChangeDatePublish(newValue)}
                            renderInput={(params) => <TextField fullWidth {...params} InputLabelProps={{ shrink: true }} />}
                        />
                        <Button variant="contained" color="success" onClick={handlePublish}>News veröffentlichen</Button>
                    </Grid>
                </Modal>
                {/* Start Warteschleife Card */}
                {/* Buttons Top */}
                <Grid container justifyContent="space-evenly">
                    <Grid item >
                        <Tooltip title="Zur Krankenakte / Teamseite" placement="top">
                            <IconButton color="primary" component={Link} to={props.content.krankenakte}>
                                <HealingIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item >
                        <Tooltip title="News veröffentlichen" placement="top">
                            <IconButton color="success" onClick={handleOpenModalPublish}>
                                <SendIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item >
                        <Tooltip title="News löschen" placement="top">
                            <IconButton color="error" onClick={handleOpenModalDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {props.content.approved == 1 && new Date(props.content.date_created * 1000) > new Date() ?
                        <Grid item >
                            <Tooltip title="Timer entfernen" placement="top">
                                <IconButton color="error" onClick={removeTimer}>
                                    <AlarmOffIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid> : ""}
                </Grid>
            </Grid>
            <Divider style={{ background: newscol }} sx={{ borderBottomWidth: 2 }} />
            {/* Autor */}
            <Grid container flexDirection="column" height={"60px"} alignItems="center" justifyContent={"center"}>
                <Grid item sm={2}>
                    <Avatar alt={props.content.username} src={props.content.userimage} />
                </Grid>
                <Grid item sm={8}>
                    <Typography>{props.content.username}</Typography>
                    <Typography variant="subtitle2">{props.content.created_string}</Typography>
                </Grid>
                <Grid item sm={2}>
                    <Tooltip title="Interne Kommentare anzeigen">
                        <IconButton onClick={handleClick}>
                            <Badge badgeContent={comments.length} color="warning" >
                                <CommentIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    {/* Internal Comments */}
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}>
                        <Grid container alignItems="center" justifyContent="space-around" sx={{ border: "1px solid #505050" }}>
                            <Grid item container sm={12} width={400} height={500} alignItems="flex-start" style={{
                                overflow: "hidden",
                                overflowY: "scroll",
                                margin: 5
                            }}>
                                <Grid item>
                                    {comments.map((value, index) => {
                                        return (
                                            <Grid container alignItems="center" justifyContent="flex-start" key={"cmt" + index} sx={{ ml: 1, mb: 1, mt: 1 }}>
                                                <Grid item container sm={11} >
                                                    <Grid item backgroundColor="#505050" sx={{ borderRadius: "15px", border: "6px solid #505050" }}>
                                                        <Grid container justifyContent="flex-start">
                                                            <Typography variant="caption" color="primary" fontWeight="fontWeightBold">
                                                                {value.username + ":"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid container justifyContent="flex-start">
                                                            <Typography variant="body2">
                                                                {value.body}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid container justifyContent="flex-end">
                                                            <Typography variant="caption" color="lightgrey">
                                                                {value.date}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item sm={1} >
                                                    <Tooltip title="Kommentar löschen" placement="top">
                                                        <IconButton onClick={(e) => handleDeleteInternalComment(value.commentid, index)}>
                                                            <RemoveCircleRoundedIcon size="small" color="error" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                    <AlwaysScrollToBottom />
                                </Grid>
                            </Grid>
                            <Grid item container sm={11}>
                                <TextField
                                    fullWidth
                                    placeholder={"Kommentar eingeben"}
                                    variant="outlined"
                                    value={commentBody}
                                    onChange={(e) => handleComment(e.target.value)}
                                />
                            </Grid>
                            <Grid item container sm={1}>
                                <IconButton onClick={handleSubmitInternalComment}>
                                    <SendIcon size="large" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Popover>
                </Grid>
            </Grid>
            <Divider style={{ background: newscol }} sx={{ borderBottomWidth: 2 }} />
            {/* Card Media */}
            <Tooltip title="News bearbeiten" placement="top">
                <CardActionArea component={Link} to={"/search/news/" + props.content.newsid}>
                    <CardMedia
                        component="img"
                        height="120"
                        image={props.content.imageURL}
                        alt={props.content.imageURL}
                    />
                    <Grid container justifyContent="flex-end" sx={{ position: "relative" }}>
                        <Grid item sx={{ bottom: 3, right: 3, position: "absolute" }}>
                            {props.content.approved == 1 && new Date(props.content.date_created * 1000) > new Date() ?
                                <Chip
                                    icon={<AccessAlarmIcon />}
                                    label={new Date(props.content.date_created * 1000).toLocaleString("de").slice(0, -3)}
                                    variant="filled"
                                    color="warning" />
                                : ""}
                        </Grid>
                    </Grid>

                    <Grid container flexDirection="column" height={"50px"} alignItems="center" justifyContent="center">
                        {props.content.headline1 !== "" ?
                            <Grid container alignItems="center" justifyContent="center">
                                <Button color="inherit" size="small" component={Link} to={props.content.link_headline1} style={{ textTransform: "none", fontWeight: 600, maxWidth: "250px", maxHeight: '20px', minHeight: '20px' }}>
                                    {props.content.headline1}
                                </Button>
                            </Grid> : ""}
                        {props.content.headline2 !== "" ?
                            <Grid container alignItems="center" justifyContent="center">
                                <Button color="inherit" size="small" component={Link} to={props.content.link_headline2} style={{ textTransform: "none", fontWeight: 600, maxWidth: "250px", maxHeight: '20px', minHeight: '20px' }}>
                                    {props.content.headline2}
                                </Button>
                            </Grid> : ""}
                    </Grid>
                    <Divider />
                    <Grid item container justifyContent={"center"} alignItems="center" height={"40px"} >
                        <Typography variant="body2" color="white" align='center' dangerouslySetInnerHTML={{
                            __html: props.content.title.length > 68 ? `${props.content.title.substring(0, 68)}...` : props.content.title
                        }} />
                    </Grid>
                </CardActionArea>
            </Tooltip>
            <Divider />
            <Grid container alignItems="center" flexDirection={"row"} height="30px">
                <Grid item container sm={12} alignItems={"center"} justifyContent="flex-end">
                    <Typography variant="caption"> Quelle: </Typography>
                    <Button target="_blank" size="small" href={props.content.source} >{props.content.source.replace('www.', '').split('/')[2]}</Button>
                </Grid>
            </Grid>
        </Card >
    )
}