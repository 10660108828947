import React, {useEffect, useRef, useState, Fragment} from 'react';
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams, usePrompt, useSearchParams} from "react-router-dom";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import StarIcon from '@mui/icons-material/Star';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RemoveIcon from '@mui/icons-material/Remove';
import PublishIcon from '@mui/icons-material/Publish';
import DownloadIcon from '@mui/icons-material/Download';
import {
  TextField,
  Grid,
  Divider,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput, Snackbar, Alert,
  Paper,
  Modal,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  ButtonGroup,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  CircularProgress, Typography, Link, useTheme, CardContent, Card
} from "@mui/material";
import * as apis from "../providers/apis";
import * as globals from "../providers/globals"
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import {set as set_page_title} from "../reducers/page_title";
import {set as set_searchbar} from "../reducers/searchbar";
import Searchbar from "../components/searchbar";
import LinearProgress from "@mui/material/LinearProgress";
import CheckIcon from "@mui/icons-material/Check";
import Info from "../components/info";
import Yesno from "../components/yesno";
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';

const LoadingScreen = () => {
  return (
    <Box sx={{mt: 7, width: '100%'}}>
      <LinearProgress/>
    </Box>
  )
}

function Commentloop() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [report, setReport] = useState({open: false, text: ''})
  const [penalty, setPenalty] = useState({index: 0, duration: 0, open: false})
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(set_page_title('Kommentare'))
    load()
  }, [])

  const change = () => {
    let d = [...data]
    d[report.index].report.reason.text = report.text
    setData(d)
    setReport({...report, open: false})
    apis.reportset({action: 'change_reason', reason: report.text, id: d[report.index].id})
  }

  const decide = (index, decision) => {
    let d = [...data]
    if(['green', 'yellow', 'blue'].indexOf(decision.type) !== -1)
      apis.reportset({action: 'decide_'+decision.type, id: d[index].id})
    else if(decision.type === 'red')
      apis.reportset({action: 'decide_red', duration: decision.duration, id: d[index].id})
    d.splice(index, 1)
    setData(d)
  }

  const load = () => {
    setLoading(true)
    apis
      .commentloopget()
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setData(json)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <Modal
        open={penalty.open}
        onClose={() => setPenalty({index: 0, duration: 0, open: false})}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant={'h5'}>User sperren</Typography>
          <FormControl sx={{mt: '20px'}} fullWidth>
            <InputLabel shrink>Dauer</InputLabel>
            <Select
              fullWidth
              input={<OutlinedInput notched label={'Dauer'}/>}
              value={penalty.duration}
              onChange={(e) => setPenalty({...penalty, duration: e.target.value})}
            >
              <MenuItem value={0}>1 Woche</MenuItem>
              <MenuItem value={1}>2 Wochen</MenuItem>
              <MenuItem value={2}>1 Monat</MenuItem>
              <MenuItem value={3}>6 Monate</MenuItem>
              <MenuItem value={4}>1 Jahr</MenuItem>
            </Select>
          </FormControl>
          <Button sx={{mt: '20px', width: '100px'}} variant={'contained'} onClick={() => {
            decide(penalty.index, {type: 'red', duration: penalty.duration})
            setPenalty({...penalty, open: false})
          }} color={'success'}>Sperren</Button>
        </Box>
      </Modal>
      <Modal
        open={report.open}
        onClose={() => setReport({...report, index: 0, open: false})}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Box display={'flex'} flexDirection={'column'}>
            <Box>Grund ändern</Box>
            <TextField sx={{mt: '20px'}} InputLabelProps={{shrink: true}} label={'Grund'} fullWidth value={report.text} onChange={(e) => setReport({...report, text: e.target.value})}/>
          </Box>
          <Button sx={{mt: '20px', width: '100px'}} variant={'contained'} onClick={change} color={'success'}>Ändern</Button>
        </Box>
      </Modal>
      <Box sx={{width: '90%', mt: '40px', pb: '200px'}}>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          {data.map((comment, i) => (
            <>
              <Box key={comment.id} sx={{flexDirection: 'row', display: 'flex', mb: '20px'}}>
                <Box sx={{width: '500px', display: 'flex', flexDirection: 'column', border: '1px solid', borderColor: 'text.primary'}}>
                  <Box sx={{backgroundColor: 'gray', p: '10px'}}>{comment.title}</Box>
                  <Box sx={{p: '10px'}} dangerouslySetInnerHTML={{__html: comment.text}}/>
                </Box>
                <Box sx={{maxWidth: '1000px', minWidth: '700px', display: 'flex', flexDirection: 'column', border: '1px solid', borderColor: 'text.primary', ml: '50px'}}>
                  <Box sx={{backgroundColor: 'gray', p: '10px'}}>
                    {comment.datum} <IconButton aria-label="add" sx={{backgroundColor: '#00ba83'}}
                                                onClick={() => navigate('/search/user/'+comment.user.id)}>
                    <PersonIcon/>
                    </IconButton> <IconButton aria-label="add" sx={{backgroundColor: '#baa100'}}
                                            onClick={() => window.open('https://hossidev.ligainsider.de/'+comment.url, '_blank')}>
                    <VisibilityIcon/>
                  </IconButton> Von: <a target={'_blank'} href={'https://hossidev.ligainsider.de/'+comment.user.url}>{comment.user.name}</a> | Gemeldet von: <a target={'_blank'} href={'https://hossidev.ligainsider.de/'+comment.report.user.url}>{comment.report.user.name}</a>
                  </Box>
                  <Box sx={{p: '10px'}}>
                    {comment.body}
                  </Box>
                  <Divider sx={{m: '10px'}}/>
                  <Box sx={{p: '10px', fontStyle: 'italic', cursor: 'pointer'}} onClick={() => setReport({text: comment.report.reason.text, index: i, open: true})}>Grund: {comment.report.reason.text}</Box>
                  <Box sx={{m: '10px'}}>
                    <Button variant={'contained'} onClick={() => decide(i, {type: 'green'})} sx={{display: 'inline-block', backgroundColor: '#218838', mr: '10px', textTransform: 'none'}}>Freigeben</Button>
                    <Button variant={'contained'} onClick={() => decide(i, {type: 'blue'})} sx={{display: 'inline-block', backgroundColor: '#2680ff', mr: '10px', textTransform: 'none'}}>Leichter Verstoß</Button>
                    <Button variant={'contained'} onClick={() => decide(i, {type: 'yellow'})} sx={{display: 'inline-block', backgroundColor: 'yellow', mr: '10px', textTransform: 'none'}}>Verwarnung</Button>
                    <Button variant={'contained'} onClick={() => setPenalty({...penalty, index: i, open: true})} sx={{display: 'inline-block', backgroundColor: '#dc3545', textTransform: 'none'}}>Schwerer Verstoß</Button>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{mb: '20px'}}/>
            </>
          ))}
        </Box>
        {loading ? <LoadingScreen/> : null}
      </Box>
    </>
  )

}

export default Commentloop;
