import * as React from 'react';
import {Fragment, useEffect, useState, useRef} from "react";
import * as apis from "../providers/apis";
import {Autocomplete, Avatar, TextField, Box} from "@mui/material";

export default function Searchbar(props) {
  const type = props.type;
  const meta = props.meta ?? null;
  const onChange = props.onChange;
  const onInputChange = props.onInputChange;
  const label = props.label;
  const noImg = props.noImg ?? false;
  const showGroup = props.showGroup != null ? props.showGroup : null;
  const [results, setResults] = useState([]);
  const [entry, setEntry] = useState({name: '', id: '', img: ''})
  const [groupBy, setGroupBy] = useState(showGroup);
  const searchTerm = useRef('');

  useEffect(() => {
    if (typeof props.value != 'undefined')
      setEntry({name: props.value.name, id: props.value.id, img: props.value.img})
  }, [props.value])

  useEffect(() => {
    setResults([])
  }, [type])

  useEffect(() => {
    setGroupBy(showGroup);
  }, [showGroup])

  const presearch = (e) => {
    if (e == null || e.target.value === 0 || typeof e.target.value == 'undefined') {
      setResults([]);
      return false;
    }
    searchTerm.current = e.target.value;
    onInputChange && onInputChange({name: e.target.value});
    if (e.target.value === '') {
      setResults([]);
      onChange({id: '', name: '', img: ''})
      return false;
    }
    search(e.target.value)
  }

  const search = (v) => {
    setEntry({name: v, id: '', img: ''});
    apis
      .search(v, type, meta)
      .then((response) => response.json())
      .then((json) => {
        if (v === searchTerm.current)
          setResults(json);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleChange = (v) => {
    onChange(v)
    let temp = {};
    if (v == null) {
      temp.id = ''
      temp.name = ''
      temp.img = ''
    } else {
      temp.id = v.id
      temp.name = v.name
      temp.img = v.img
    }
    setEntry(temp);
    setResults([]);
  }

  return (
    <Autocomplete
      {...props}
      freeSolo
      value={{name: entry.name, id: entry.id, img: entry.img}}
      options={results}
      getOptionLabel={option => option.name || ""}
      onInputChange={presearch}
      onChange={(e, v) => handleChange(v)}
      groupBy={groupBy ? (option) => option.type : null}
      filterOptions={(options, state) => options}
      renderOption={(props, option) => (
        <li {...props} key={option.type + option.id}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              {!noImg ? <Avatar sx={{mr: 2}} src={option.img}/> : null}
              <span>{option.name}</span>
            </Box>
            {option.extra ? <Box>
              {option.extra}
            </Box> : null}
          </Box>
        </li>
      )}
      renderInput={(params, option) => (
        <Box sx={{display: 'flex', position: 'relative'}}>
          <TextField
            {...params}
            autoFocus={props.autofocus ?? false}
            label={label}
            InputLabelProps={{shrink: true}}
            InputProps={{
              ...params.InputProps,
              ...props.customInputProps ?? [],
            }}
          />
          {!noImg ?
            <Avatar sx={{
              visibility: entry.img !== '' ? 'visible' : 'hidden',
              position: 'absolute',
              top: 12,
              right: 40,
              width: 30,
              height: 30
            }} src={entry.img}/>
            : null}
        </Box>
      )}
    />
  );
}
