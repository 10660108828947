import React, { useEffect, useRef, useState, Fragment } from 'react';
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, usePrompt, useSearchParams } from "react-router-dom";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import StarIcon from '@mui/icons-material/Star';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RemoveIcon from '@mui/icons-material/Remove';
import PublishIcon from '@mui/icons-material/Publish';
import DownloadIcon from '@mui/icons-material/Download';
import {
  TextField,
  Grid,
  Divider,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput, Snackbar, Alert,
  Paper,
  Modal,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  ButtonGroup,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  CircularProgress
} from "@mui/material";
import * as apis from "../providers/apis";
import * as globals from "../providers/globals"
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { set as set_page_title } from "../reducers/page_title";
import { set as set_searchbar } from "../reducers/searchbar";
import Searchbar from "../components/searchbar";
import LinearProgress from "@mui/material/LinearProgress";
import CheckIcon from "@mui/icons-material/Check";
import Info from "../components/info";
import Yesno from "../components/yesno";

function Team() {

  const newData = {
    id: '',
    de_name: '',
    isnation: 0,
    filename: '',
    optauuid: '',
    nation_name: '',
    nation_id: '',
    nation_img: '',
    selectboxes: { competitions: [], seasons: [], tms: [] },
    selected: { competitions: '', seasons: '', tms: '' },
    game: []
  };

  const newPlayer = {
    name: '',
    id: '',
    img: '',
    formation_place: 0,
    displacement: '',
    abgang: 0,
    goals: 0,
    assists: 0,
    yellow: 0,
    second_yellow: 0,
    red: 0,
    empty: false,
    visible: false
  }

  const [disabled, setDisabled] = React.useState({ seasons: false, tms: false });
  const [data, setData] = useState({ ...newData });
  const [dirty, setDirty] = useState(false);
  const [vadirty, setVadirty] = useState(false);
  const [working, setWorking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valoading, setValoading] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const page = useSelector(state => state.page.value)
  const [info, setInfo] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [vapostsuccess, setVapostsuccess] = useState(false);
  const [vapostConfirm, setVapostConfirm] = useState(false);
  const [changemin, setChangemin] = useState({ field_index: 0, player_index: 0, min: 0, modal: false });
  const [addplayer, setAddplayer] = useState({ field: '', field_index: 0, player_index: 0, modal: false });
  const [addmissingplayer, setAddmissingplayer] = useState(false);
  const [playerstats, setPlayerstats] = useState({ field: '', field_index: 0, player_index: 0, modal: false });
  const [tmimportmodal, setTmimportmodal] = useState(false);
  const navigate = useNavigate();

  const changeField = (v, field) => {
    setDirty(true);
    let d = { ...data };
    d[field] = v;
    setData(d);
  }

  const handleNation = (v) => {
    setDirty(true);
    let d = { ...data };
    let temp = { name: '', id: '', img: '' };
    if (!(v == null || v === 0)) {
      temp.id = v.id;
      temp.name = v.name;
      temp.img = v.img
    }
    d.nation_id = temp.id;
    d.nation_name = temp.name;
    d.nation_img = temp.img;
    setData(d);
  }

  const handleNationInput = (v) => {
    let d = { ...data };
    d.nation_name = v.name;
    setData(d);
  }

  const submit = () => {
    if (working) return false;
    let d = { ...data };
    setWorking(true);
    if (!vadirty)
      d.game = null;
    console.log(d);
    apis.teamset(d)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setWorking(false)
        if (json.code === 1) {
          setSuccess(true);
          setDirty(false);
          setVadirty(false);
          setTimeout(() => {
            if (d.id === '')
              navigate("/search/team/" + json.id);
          }, 200)
        } else {
          setInfo(true);
          setMessage(json.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  usePrompt(
    "Änderungen wirklich verwerfen?",
    dirty
  );

  const redirectVA = (v, type) => {
    if (vadirty) {
      if (!window.confirm('Änderungen verwerfen?'))
        return false;
    }
    setVadirty(false);
    setValoading(true);
    let d = { ...data };
    let tmid = null;
    if (type === 'competition') {
      setDisabled({ seasons: true, tms: true });
      data.selectboxes.competitions.forEach((e, i) => {
        if (v === e.id) {
          d.selected.competitions = e.id;
          d.selectboxes.seasons = [{ name: e.goto.season, id: e.goto.seasonid }]
          d.selectboxes.tms = [{ name: e.goto.playday, id: e.goto.tmid }]
          d.selected.seasons = e.goto.seasonid;
          d.selected.tms = e.goto.tmid;
          tmid = e.goto.tmid;
        }
      })
    } else if (type === 'season') {
      setDisabled({ seasons: false, tms: true });
      data.selectboxes.seasons.forEach((e, i) => {
        if (v === e.id) {
          d.selected.seasons = e.id;
          d.selectboxes.tms = [{ name: e.goto.playday, id: e.goto.tmid }]
          d.selected.tms = e.goto.tmid;
          tmid = e.goto.tmid;
        }
      })
    } else if (type === 'tm') {
      d.selected.tms = v;
      tmid = v;
    }
    setData(d)
    window.history.pushState(null, null, "/search/team/" + data.id + "/" + tmid)
    apis
      .teamget(data.id, tmid)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setDisabled({ seasons: false, tms: false });
        setData(json)
        setValoading(false)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setDirty(false);
    setVadirty(false);
    setData(globals.clone(newData));
    let id = params.id;
    let tmid = params.tmid;
    if (id != null) {
      setLoading(true);
      apis
        .teamget(id, tmid ?? null)
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          dispatch(set_page_title(json.de_name + ': Bearbeiten'))
          dispatch(set_searchbar({ name: json.de_name, id: json.id, img: json.img, type: 'team' }))
          setData(json)
          setLoading(false)
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setLoading(false)
      dispatch(set_page_title('Team anlegen'))
      dispatch(set_searchbar({ name: '', id: '', img: '', type: 'team' }))
    }
  }, [page])

  const changePublished = (e, v) => {
    setDirty(true);
    setVadirty(true);
    let d = { ...data };
    d.game.published = v;
    setData(d);
  }

  const postva = () => {
    setVapostConfirm(false);
    apis.postva(data.game.id)
      .then((response) => response.json())
      .then((json) => {
        if (json.code === 1) {
          setVapostsuccess(true)
        } else {
          setInfo(true);
          setMessage(json.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const screen = () => {
    return (
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Info message={"Server meldet: " + message} open={info}
          cancel={() => setInfo(false)} />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={success}
          onClose={() => setSuccess(false)}
          autoHideDuration={4000}
          key={'playersnack'}>
          <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
            Speichern erfolgreich!
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={vapostsuccess}
          onClose={() => setVapostsuccess(false)}
          autoHideDuration={4000}>
          <Alert onClose={() => setVapostsuccess(false)} severity="success" sx={{ width: '100%' }}>
            VA erfolgreich gepostet!
          </Alert>
        </Snackbar>
        {working ? <Button sx={{ position: 'fixed', bottom: 10, right: 10, visibility: dirty ? 'visible' : 'hidden' }}
          color={'success'} variant="contained">
          <CircularProgress size={14} sx={{ color: 'black' }} />
        </Button> : <Button sx={{ position: 'fixed', bottom: 10, right: 10, visibility: dirty ? 'visible' : 'hidden' }}
          color={'success'} variant="contained"
          endIcon={<CheckIcon />} onClick={() => submit()}>
          Speichern
        </Button>}
        {data && data.id ?
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'right', mt: '10px' }}>
            <Button onClick={() => navigate("/search/news/?value=" + data.id + '&type=team')}>+News</Button>
          </Box> : null}
        {params.id != null ? <Va /> : ''}
        <Divider textAlign={"left"} sx={{ pt: 3 }}>Stammdaten:</Divider>
        <Grid container spacing={2} sx={{ mt: 0 }} flexDirection={"column"}>
          <Grid item sm={12}>
            <Grid container spacing={2} flexDirection={"row"}>
              <Grid item sm={4}>
                <TextField fullWidth id="de_name" label="Vereinsname*" variant="outlined"
                  InputLabelProps={{ shrink: true }} inputProps={{ autoComplete: 'off' }}
                  value={data.de_name} onChange={(e) => changeField(e.target.value, 'de_name')} />
              </Grid>
              <Grid item sm={4}>
                <TextField fullWidth id="filename" label="Dateiname" variant="outlined" InputLabelProps={{ shrink: true }}
                  value={data.filename} onChange={(e) => changeField(e.target.value, 'filename')} />
              </Grid>
              <Grid item sm={4}>
                <TextField fullWidth id="opta_uuid" label="Opta UUID" variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={data.optauuid} onChange={(e) => changeField(e.target.value, 'optauuid')} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid container spacing={2} flexDirection={"row"}>
              <Grid item sm={4}>
                <FormControl fullWidth>
                  <InputLabel shrink id="nationalteam">Nationalmannschaft</InputLabel>
                  <Select
                    input={<OutlinedInput notched label={'Nationalmannschaft'} />}
                    fullWidth
                    id="art"
                    value={data.isnation}
                    onChange={(e) => changeField(e.target.value, 'isnation')}
                  >
                    <MenuItem value={0}>Nein</MenuItem>
                    <MenuItem value={1}>Ja</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <Searchbar label={'Nation'} type={'nation'} onInputChange={handleNationInput} onChange={handleNation}
                  value={{ name: data.nation_name, id: data.nation_id, img: data.nation_img }} />
              </Grid>
              <Grid item sm={4}>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  const Va = () => {
    if (data.game && data.game.formation)
      return (
        <Fragment>
          <Divider textAlign={"left"} sx={{ pt: 3 }}>VA:</Divider>
          <Grid container spacing={2} sx={{ mt: 0 }} flexDirection={"column"}>
            <Grid item sm={12}>
              <Grid container spacing={2} flexDirection={"row"}>
                <Grid item sm={4}>
                  <FormControl fullWidth>
                    <InputLabel shrink>Wettbewerb</InputLabel>
                    <Select
                      input={<OutlinedInput notched label={'Wettbewerb'} />}
                      fullWidth
                      label={'Wettbewerb'}
                      value={data.selected.competitions}
                      onChange={(e) => redirectVA(e.target.value, 'competition')}
                    >
                      {data.selectboxes.competitions.map((e, i) => (
                        <MenuItem key={'competition' + e.id} value={e.id}>{e.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl fullWidth>
                    <InputLabel shrink>Saison</InputLabel>
                    <Select
                      input={<OutlinedInput notched label={'Saison'} />}
                      fullWidth
                      label={'Saison'}
                      value={data.selected.seasons}
                      onChange={(e) => redirectVA(e.target.value, 'season')}
                      disabled={disabled.seasons}
                    >
                      {data.selectboxes.seasons.map((e, i) => (
                        <MenuItem key={'season' + e.id} value={e.id}>{e.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl fullWidth>
                    <InputLabel shrink>Spieltag</InputLabel>
                    <Select
                      input={<OutlinedInput notched label={'Spieltag'} />}
                      fullWidth
                      label={'Spieltag'}
                      value={data.selected.tms}
                      onChange={(e) => redirectVA(e.target.value, 'tm')}
                      disabled={disabled.tms}
                    >
                      {data.selectboxes.tms.map((e, i) => (
                        <MenuItem key={'playday' + e.id} value={e.id}>{e.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {valoading ? <LoadingScreen /> : <Vascreen />}
        </Fragment>
      )
    else return null
  }

  const Tmimport = () => {

    const [list, setList] = useState([])
    const [season, setSeason] = useState(0);
    const [playday, setPlayday] = useState({ season_index: 0, day_id: 0 });
    const [topelf, setTopelf] = useState(false);
    const [impworking, setImpworking] = useState(false);

    useEffect(() => {
      if (tmimportmodal && list.length === 0) {
        apis.tmimport(data.id, null, false, true)
          .then((response) => response.json())
          .then((json) => {
            setSeason(json[0].id);
            setPlayday({ season_index: 0, day_id: json[0].playdays[0].id });
            setList(json);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }, [tmimportmodal])

    const changeSeason = (v) => {
      setSeason(v);
      const i = list.map(item => item.id).indexOf(v)
      setPlayday({ season_index: i, day_id: list[i].playdays[0].id })
    }

    const changePlayday = (v) => {
      const i = list.map(item => item.id).indexOf(season)
      setPlayday({ season_index: i, day_id: v })
    }

    const imp = () => {
      setImpworking(true);
      apis.tmimport(data.id, playday.day_id, topelf)
        .then((response) => response.json())
        .then((json) => {
          let d = { ...data };
          d.game.players = json.game;
          d.game.formation = json.formation;
          setData(d);
          setTmimportmodal(false);
          setDirty(true)
          setVadirty(true)
        })
        .catch((error) => {
          console.error(error);
        });
    }

    return (
      <Modal
        open={tmimportmodal}
        onClose={() => setTmimportmodal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          {list.length === 0 ?
            <LoadingScreen />
            :
            <Box display={'flex'} flexDirection={'column'}>
              <Box sx={{ pr: '10px', pl: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <FormControl fullWidth sx={{ mr: '5px' }}>
                  <InputLabel shrink>Saison</InputLabel>
                  <Select
                    input={<OutlinedInput notched label={'Saison'} />}
                    value={season}
                    onChange={(e) => changeSeason(e.target.value)}
                  >
                    {list && list.map(e => <MenuItem
                      value={e.id}>{e.name}</MenuItem>)}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ ml: '5px' }}>
                  <InputLabel shrink>Spieltag</InputLabel>
                  <Select
                    fullWidth
                    input={<OutlinedInput notched label={'Spieltag'} />}
                    value={playday.day_id}
                    onChange={(e) => changePlayday(e.target.value)}
                  >
                    {list && list[playday.season_index].playdays.map(e => <MenuItem
                      value={e.id}>{e.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ mt: '10px', pr: '10px', pl: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <FormControl>
                  <FormControlLabel control={<Switch checked={topelf} onChange={() => setTopelf(!topelf)} />} label="Topelf" />
                </FormControl>
                <Button color={'success'} variant="contained" onClick={imp}>{impworking ? <CircularProgress size={14} sx={{ color: 'black' }} /> : 'Importieren'}</Button>
              </Box>
            </Box>
          }
        </Box>
      </Modal>
    )
  }

  const Playerstats = () => {

    let ref;
    const [stats, setStats] = useState({ goals: 0, assists: 0, yellow: 0, second_yellow: 0, red: 0 });
    const prev = useRef();

    useEffect(() => {
      if (playerstats.field === '')
        return false;
      if (playerstats.field) {
        ref = data.game.players[playerstats.field_index[0]][playerstats.field_index[1]][playerstats.player_index]
      } else {
        ref = data.game.bench[playerstats.field_index]
      }
      if (typeof ref == 'undefined')
        return false;
      const vals = {
        goals: ref.goals ?? 0,
        assists: ref.assists ?? 0,
        yellow: ref.yellow,
        second_yellow: ref.second_yellow,
        red: ref.red
      }
      prev.current = vals;
      setStats(vals);
    }, [playerstats])

    const changeStat = (action, v) => {
      let add = action === 'up' ? 1 : -1;
      let d = { ...stats }
      d[v] += add;
      if (d[v] < 0) d[v] = 0;
      setStats(d);
    }

    const handleClose = () => {
      if (prev.current !== stats) {
        setDirty(true);
        setVadirty(true);
        let d = { ...data }
        if (playerstats.field) {
          ref = d.game.players[playerstats.field_index[0]][playerstats.field_index[1]][playerstats.player_index]
        } else {
          ref = d.game.bench[playerstats.field_index]
        }
        Object.keys(stats).forEach(function (i) {
          ref[i] = stats[i];
        });
        setData(d);
      }
      setPlayerstats({ ...playerstats, modal: false })
    }

    return (
      <Modal
        open={playerstats.modal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Box width={'auto'} flexDirection={'row'} display={'flex'} justifyContent={'space-between'}>
            {playerstats.field ?
              <Fragment>
                <Box sx={{ mr: 3 }} width={'40px'} flexDirection={'column'} display={'flex'}>
                  <Box width={'40px'} height={'40px'} justifyContent={'center'} display={'flex'}>
                    <Box sx={{ width: 30, height: 30 }} component={'img'}
                      src={'https://cdn.ligainsider.de/images/va/goal_big.png'} />
                  </Box>
                  <Box sx={{ lineHeight: '40px' }} height={'40px'} width={'100%'} textAlign={'center'}>{stats.goals}</Box>
                  <ButtonGroup
                    orientation="vertical"
                  >
                    <Button onClick={() => changeStat('up', 'goals')}>+</Button>
                    <Button onClick={() => changeStat('down', 'goals')}>-</Button>
                  </ButtonGroup>
                </Box>
                <Box sx={{ mr: 3 }} width={'40px'} flexDirection={'column'} display={'flex'}>
                  <Box width={'40px'} height={'40px'} justifyContent={'center'} display={'flex'}>
                    <Box sx={{ width: 30, height: 30 }} component={'img'}
                      src={'https://cdn.ligainsider.de/images/va/assist_big.png'} />
                  </Box>
                  <Box sx={{ lineHeight: '40px' }} height={'40px'} width={'100%'}
                    textAlign={'center'}>{stats.assists}</Box>
                  <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical outlined button group"
                  >
                    <Button onClick={() => changeStat('up', 'assists')}>+</Button>
                    <Button onClick={() => changeStat('down', 'assist')}>-</Button>
                  </ButtonGroup>
                </Box>
              </Fragment>
              : null}
            <Box sx={{ mr: 3 }} width={'40px'} flexDirection={'column'} display={'flex'}>
              <Box width={'40px'} height={'40px'} justifyContent={'center'} display={'flex'}>
                <Box sx={{ width: 30, height: 40 }} component={'img'}
                  src={'https://cdn.ligainsider.de/images/misc/yellow_big.png'} />
              </Box>
              <Box sx={{ lineHeight: '40px' }} height={'40px'} width={'100%'} textAlign={'center'}>{stats.yellow}</Box>
              <ButtonGroup
                orientation="vertical"
                aria-label="vertical outlined button group"
              >
                <Button onClick={() => changeStat('up', 'yellow')}>+</Button>
                <Button onClick={() => changeStat('down', 'yellow')}>-</Button>
              </ButtonGroup>
            </Box>
            <Box sx={{ mr: 3 }} width={'40px'} flexDirection={'column'} display={'flex'}>
              <Box width={'40px'} height={'40px'} justifyContent={'center'} display={'flex'}>
                <Box sx={{ width: 30, height: 40 }} component={'img'}
                  src={'https://cdn.ligainsider.de/images/misc/yellow_red_big.png'} />
              </Box>
              <Box sx={{ lineHeight: '40px' }} height={'40px'} width={'100%'}
                textAlign={'center'}>{stats.second_yellow}</Box>
              <ButtonGroup
                orientation="vertical"
                aria-label="vertical outlined button group"
              >
                <Button onClick={() => changeStat('up', 'second_yellow')}>+</Button>
                <Button onClick={() => changeStat('down', 'second_yellow')}>-</Button>
              </ButtonGroup>
            </Box>
            <Box width={'40px'} flexDirection={'column'} display={'flex'}>
              <Box width={'40px'} height={'40px'} justifyContent={'center'} display={'flex'}>
                <Box sx={{ width: 30, height: 40 }} component={'img'}
                  src={'https://cdn.ligainsider.de/images/misc/red_big.png'} />
              </Box>
              <Box sx={{ lineHeight: '40px' }} height={'40px'} width={'100%'} textAlign={'center'}>{stats.red}</Box>
              <ButtonGroup
                orientation="vertical"
                aria-label="vertical outlined button group"
              >
                <Button onClick={() => changeStat('up', 'red')}>+</Button>
                <Button onClick={() => changeStat('down', 'red')}>-</Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>
      </Modal>
    )
  }

  const Vascreen = () => {
    return (
      <Fragment>
        <Box sx={{ mt: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box>{data.game.type === 'home' ? 'Heim' : 'Auswärts'} am {data.game.date_ui} gegen: {data.game.team_vs.name}</Box>
          <Box component={'img'} src={data.game.team_vs.img} width={'40px'} sx={{ ml: '5px' }} />
        </Box>
        {/*<Box sx={{background: 'url(https://cdn.ligainsider.de/images/va/playfield_background.jpg)  no-repeat center 95px', width: '100%', height: '300px'}}></Box>*/}
        <Box sx={{
          position: 'relative',
          width: '100%',
          border: '1px solid',
          borderColor: 'text.primary',
        }}>

          <Box sx={{
            width: 200,
            zIndex: 4,
            position: 'absolute',
            top: 30,
            left: 30,
            display: 'flex',
            flexDirection: 'column'
          }}>
            <ToggleButtonGroup sx={{ mb: 2 }} value={data.game.published} onChange={changePublished} exclusive>
              <ToggleButton value={0}><EditIcon /></ToggleButton>
              <ToggleButton value={1}><CheckIcon /></ToggleButton>
              <ToggleButton value={2}><StarIcon /></ToggleButton>
              <ToggleButton value={3}><AccessTimeIcon /></ToggleButton>
            </ToggleButtonGroup>
            <FormControl>
              <InputLabel shrink>Formation</InputLabel>
              <Select
                input={<OutlinedInput notched label={'Formation'} />}
                value={data.game.formation.formation_id}
                onChange={(e) => changeFormation(e.target.value)}
              >
                {data.game.all_formations && data.game.all_formations.map(e => <MenuItem
                  key={'formation' + e.id} value={e.id}>{e.description}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{
            zIndex: 4,
            position: 'absolute',
            top: 30,
            right: 30,
            display: 'flex',
            flexDirection: 'row'
          }}>
            <IconButton title={'VA News posten'} onClick={() => setVapostConfirm(true)}>
              <PublishIcon />
            </IconButton>
            <IconButton title={'Aufstellung importieren'} onClick={() => setTmimportmodal(true)}>
              <DownloadIcon />
            </IconButton>
            <Yesno open={vapostConfirm} accept={postva} cancel={() => setVapostConfirm(false)}
              message={'VA News wirklich posten?' + (vadirty ? ' - Du hast noch ungespeicherte Änderungen' : '')} />
          </Box>

          <Grid container direction={'column'}>
            {data.game.players && data.game.players.map((row, rc) => {
              return (
                <Grid item>
                  <Grid container wrap={'nowrap'} direction={'row'} alignItems={'center'} justifyContent={'center'}>
                    {row.map((col, cc) => {
                      return (
                        <Box
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          flexDirection={'column'}
                          sx={{
                            width: 120,
                            height: 200,
                            mr: data.game.formation.rowconf.charAt(rc) === '1' ? '40px' : '20px',
                            ml: data.game.formation.rowconf.charAt(rc) === '1' ? '40px' : '20px',
                          }}>
                          <Box sx={{ position: 'relative', top: col[0].displacement }}>
                            {col[0].empty ? <Emptybox index={[rc, cc]} /> : <Playerbox index={[rc, cc]} data={col} />}
                          </Box>
                        </Box>
                      )
                    }
                    )}
                  </Grid>
                </Grid>
              )
            })}
            <Grid item sx={{ borderTop: '1px solid', borderColor: 'text.primary' }}>
              <Grid container direction={'row'}>
                <Grid item xs flexDirection={'row'} flexWrap={'wrap'} display={'flex'}>
                  {data.game.bench.map((col, i) => {
                    return (
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                        sx={{
                          width: 120,
                          height: 180,
                          ml: '10px',
                          mr: '10px'
                        }}>
                        <Box sx={{ position: 'relative' }}>
                          <Benchbox index={i} data={col} />
                        </Box>
                      </Box>
                    )
                  }
                  )}
                </Grid>
                <Grid item width={'140px'}>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                    sx={{
                      width: 120,
                      height: 200,
                      mr: '10px',
                      ml: '10px'
                    }}>
                    <Box sx={{ position: 'relative' }}>
                      <Box width={120} height={120}
                        onClick={() => setAddplayer({ field: false, field_index: 0, player_index: 0, modal: true })}>
                        <Avatar sx={{
                          zIndex: 1,
                          width: 'auto',
                          height: 120,
                          border: '1px solid',
                          borderColor: 'text.primary'
                        }}>
                          <AddIcon />
                        </Avatar>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <TableContainer component={Paper}>
              <Paper sx={{
                height: '60px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                pr: '10px',
                pl: '10px'
              }}>
                <Box>Gegen den {data.game.team_vs.name} fehlen:</Box>
                <Box>
                  <IconButton aria-label="add" sx={{ backgroundColor: '#00ba83' }}
                    onClick={() => setAddmissingplayer(true)}>
                    <AddIcon />
                  </IconButton>
                </Box>
              </Paper>
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="left">Spieler</TableCell>
                    <TableCell align="left">Grund</TableCell>
                    <TableCell align="left">Fehlt seit</TableCell>
                    <TableCell align="left" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.game.missing.origin.map((row) => (
                    <TableRow>
                      <TableCell width={30} sx={{ pl: '5px', pr: '5px' }} align="left"><Box component={'img'}
                        src={row.icon} /></TableCell>
                      <TableCell align="left"><span>{row.name}</span></TableCell>
                      <TableCell align="left">{row.tag}</TableCell>
                      <TableCell align="left">{row.since}</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  ))}
                  {data.game.missing.after.map((row, i) => (
                    <TableRow>
                      <TableCell width={30} sx={{ pl: '5px', pr: '5px' }} align="left"><Box component={'img'}
                        src={row.icon} /></TableCell>
                      <TableCell align="left"><span>{row.name}</span></TableCell>
                      <TableCell align="left">{row.tag}</TableCell>
                      <TableCell align="left">{row.since}</TableCell>
                      <TableCell align="right">
                        <IconButton aria-label="add" sx={{ backgroundColor: '#af0909' }}
                          onClick={() => removeMissingplayer(i)}>
                          <RemoveIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
        {/*minute change modal*/}
        <Modal
          open={changemin.modal}
          onClose={() => setChangemin({ ...changemin, modal: false })}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}>
            <TextField autoFocus defaultValue={changemin.min} onKeyPress={changeChangemin} />
          </Box>
        </Modal>
        {/*add player modal*/}
        <Modal
          open={addplayer.modal}
          onClose={() => setAddplayer({ ...addplayer, modal: false })}
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}>
            <Searchbar autofocus type={'spielerva'} onChange={addPlayer} />
          </Box>
        </Modal>
        {/*add missing player modal*/}
        <Modal
          open={addmissingplayer}
          onClose={() => setAddmissingplayer(false)}
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}>
            <Searchbar autofocus type={'spielermissingva'} onChange={addMissingplayer}
              meta={{ competition: data.game.competition_id, datum: data.game.date }} />
          </Box>
        </Modal>
        <Playerstats />
        <Tmimport />
      </Fragment>
    )
  }

  const Playerbox = ({ data: col, index: index }) => {
    return col.map((e, i) => {
      return (
        <Box sx={{ display: e.visible ? 'block' : 'none', position: 'relative' }}>
          <Avatar sx={{ zIndex: 1, width: 'auto', height: 'auto', border: '1px solid', borderColor: 'text.primary' }}
            src={e.img} />
          <Paper sx={{ zIndex: 2, textAlign: 'center', width: '100%' }}>{e.name}</Paper>
          {col.length > 1 && i < col.length - 1 ?
            <Fragment>
              <Box onDoubleClick={() => changeMin(index, i)}
                borderRadius="50%" sx={{
                  textAlign: 'center',
                  lineHeight: 2,
                  fontWeight: 'bold',
                  color: 'black',
                  position: 'absolute',
                  right: -10,
                  top: 10,
                  width: 35,
                  height: 35,
                  background: 'white',
                  zIndex: 3,
                  border: '1px solid',
                  borderColor: 'black',
                  userSelect: 'none'
                }}>
                {e.abgang}.
              </Box>
              <Avatar
                onClick={() => togglePlayer(index, col, i + 1)}
                sx={{
                  position: 'absolute',
                  top: 40,
                  right: -20,
                  zIndex: 3,
                  color: 'white',
                  width: 'auto',
                  height: 'auto',
                  bgcolor: 'black'
                }}>
                <ArrowCircleRightOutlinedIcon />
              </Avatar>
            </Fragment>
            : null}
          {col.length > 1 && i > 0 ?
            <Avatar
              onClick={() => togglePlayer(index, col, i - 1)}
              sx={{
                position: 'absolute',
                top: 40,
                right: 3,
                zIndex: 3,
                color: 'white',
                width: 'auto',
                height: 'auto',
                bgcolor: 'black'
              }}>
              <ArrowCircleLeftOutlinedIcon />
            </Avatar>
            : null}
          {/*control buttons*/}
          <Box sx={{
            position: 'absolute',
            display: 'flex',
            bottom: 20,
            left: 22,
            zIndex: 3
          }}>
            <IconButton sx={{ backgroundColor: 'white', width: 25, height: 25 }} color={'success'} aria-label="star"
              onClick={() => setPlayerstats({
                field: true,
                field_index: index,
                player_index: i,
                modal: true
              })}>
              <StarIcon />
            </IconButton>
            <IconButton sx={{ backgroundColor: 'white', width: 25, height: 25 }} color={'success'} aria-label="add"
              onClick={() => setAddplayer({ field: true, field_index: index, player_index: i, modal: true })}>
              <AddIcon />
            </IconButton>
            <IconButton sx={{ backgroundColor: 'white', width: 25, height: 25 }} color={'success'} aria-label="remove"
              onClick={() => removePlayer(true, index, i)}>
              <RemoveIcon />
            </IconButton>
          </Box>
          {/*Goals*/}
          {e.goals > 0 ?
            <Box sx={{
              position: 'absolute',
              bottom: 30,
              left: -5,
              zIndex: 3
            }}>
              <Box sx={{ position: 'relative' }}
              >
                <Box component={'img'} src={'https://cdn.ligainsider.de/images/va/goal_big.png'} />
                <Box sx={{
                  position: 'absolute', textShadow: '-1px -1px black, 1px -1px black, -1px 1px black, 1px 1px #000',
                  left: -5, bottom: 5, fontSize: 20, fontWeight: 'bold'
                }}>
                  {e.goals}
                </Box>
              </Box>
            </Box>
            : null}
          {/*assist*/}
          {e.assists > 0 ?
            <Box sx={{
              position: 'absolute',
              bottom: 90,
              left: -15,
              zIndex: 3
            }}>
              <Box sx={{ position: 'relative' }}
              >
                <Box component={'img'} src={'https://cdn.ligainsider.de/images/va/assist_big.png'} />
                <Box sx={{
                  position: 'absolute', textShadow: '-1px -1px black, 1px -1px black, -1px 1px black, 1px 1px #000',
                  left: -5, bottom: 5, fontSize: 20, fontWeight: 'bold'
                }}>
                  {e.assists}
                </Box>
              </Box>
            </Box>
            : null}
          {/*icon*/}
          {e.icon !== '' ?
            <Box sx={{
              position: 'absolute',
              bottom: 30,
              right: -15,
              zIndex: 3
            }}>
              <Box sx={{ position: 'relative' }}
              >
                <Box component={'img'} src={e.icon} />
              </Box>
            </Box>
            : null}
          {/*card*/}
          {e.red > 0 || e.second_yellow > 0 || e.yellow > 0 ?
            <Box sx={{
              position: 'absolute',
              bottom: -8,
              right: -20,
              zIndex: 3
            }}>
              <Box sx={{ position: 'relative' }}
              >
                <Box component={'img'}
                  src={'https://cdn.ligainsider.de/images/misc/' + (e.red > 0 ? 'red' : (e.second_yellow > 0 ? 'yellow_red' : (e.yellow > 0 ? 'yellow' : ''))) + '_big.png'} />
                <Box sx={{
                  position: 'absolute', color: 'black',
                  left: 3, bottom: 10, fontSize: 15, fontWeight: 'bold'
                }}>
                  {(e.red > 0 ? e.red : (e.second_yellow > 0 ? e.second_yellow : (e.yellow > 0 ? e.yellow : '')))}.
                </Box>
              </Box>
            </Box>
            : null}
        </Box>
      )
    })
  }

  const Emptybox = ({ index: index }) => {
    return (
      <Box width={120} height={120}
        onClick={() => setAddplayer({ field: true, field_index: index, player_index: 0, modal: true })}>
        <Avatar sx={{ zIndex: 1, width: 'auto', height: 120, border: '1px solid', borderColor: 'text.primary' }}>
          <AddIcon />
        </Avatar>
      </Box>
    )
  }

  const Benchbox = ({ data: player, index: index }) => {
    return (
      <Box>
        <Avatar sx={{ zIndex: 1, width: 'auto', height: 'auto', border: '1px solid', borderColor: 'text.primary' }}
          src={player.img} />
        <Paper sx={{ zIndex: 2, textAlign: 'center', width: '100%' }}>{player.name}</Paper>
        {/*control buttons*/}
        <Box sx={{
          position: 'absolute',
          display: 'flex',
          bottom: 20,
          left: 35,
          zIndex: 3
        }}>
          <IconButton sx={{ backgroundColor: 'white', width: 25, height: 25 }} color={'success'} aria-label="star"
            onClick={() => setPlayerstats({
              field: false,
              field_index: index,
              player_index: 0,
              modal: true
            })}>
            <StarIcon />
          </IconButton>
          <IconButton sx={{ backgroundColor: 'white', width: 25, height: 25 }} color={'success'} aria-label="remove"
            onClick={() => removeBench(index)}>
            <RemoveIcon />
          </IconButton>
        </Box>
        {/*icon*/}
        {player.icon !== '' ?
          <Box sx={{
            position: 'absolute',
            bottom: 30,
            right: -15,
            zIndex: 3
          }}>
            <Box sx={{ position: 'relative' }}
            >
              <Box component={'img'} src={player.icon} />
            </Box>
          </Box>
          : null}
        {/*card*/}
        {player.red > 0 || player.second_yellow > 0 || player.yellow > 0 ?
          <Box sx={{
            position: 'absolute',
            bottom: -8,
            right: -20,
            zIndex: 3
          }}>
            <Box sx={{ position: 'relative' }}
            >
              <Box component={'img'}
                src={'https://cdn.ligainsider.de/images/misc/' + (player.red > 0 ? 'red' : (player.second_yellow > 0 ? 'yellow_red' : (player.yellow > 0 ? 'yellow' : ''))) + '_big.png'} />
              <Box sx={{
                position: 'absolute', color: 'black',
                left: 3, bottom: 10, fontSize: 15, fontWeight: 'bold'
              }}>
                {(player.red > 0 ? player.red : (player.second_yellow > 0 ? player.second_yellow : (player.yellow > 0 ? player.yellow : '')))}.
              </Box>
            </Box>
          </Box>
          : null}
      </Box>
    )
  }

  const togglePlayer = (field_index, col, index) => {
    let d = { ...data };
    let c = [...col];
    c.forEach((e, i) => {
      c[i].visible = i === index;
    })
    d.game.players[field_index[0]][field_index[1]] = c;
    setData(d);
  }

  const changeMin = (field_index, player_index) => {
    setChangemin({
      field_index: field_index,
      player_index: player_index,
      min: data.game.players[field_index[0]][field_index[1]][player_index].abgang,
      modal: true
    });
  }

  const changeChangemin = (e) => {
    setDirty(true);
    setVadirty(true);
    if (isNaN(e.target.value))
      return false;
    if (e.key === 'Enter') {
      setChangemin({ ...changemin, modal: false })
      let d = { ...data };
      d.game.players[changemin.field_index[0]][changemin.field_index[1]][changemin.player_index].abgang = e.target.value;
      setData(d);
    }
  }

  const changeFormation = (v) => {
    setDirty(true);
    setVadirty(true);
    let d = { ...data };
    d.game.formation.formation_id = v;
    let formation;
    d.game.all_formations.forEach(e => {
      if (e.id === v) {
        formation = e;
        return false;
      }
    })
    // loop over new formation and get the formation_place
    formation.lineup.forEach((f_row, f_rc) => {
      f_row.forEach((f_col, f_cc) => {
        let pos = f_col.formation_place
        let found = false;
        // now loop over the actual field and reposition players into formation lineup
        data.game.players.forEach((row, rc) => {
          if (found)
            return false;
          row.forEach((col, cc) => {
            if (col[0].formation_place === pos) {
              col.forEach((c, i) => {
                col[i].displacement = formation.lineup[f_rc][f_cc].displacement
              })
              formation.lineup[f_rc][f_cc] = col;
              found = true;
              return false;
            }
          })
        })
      })
    })
    d.game.players = formation.lineup;
    setData(d);
  }

  const removePlayerByID = (id) => {
    let found = false;
    let player;
    // search field

    data.game.players.forEach((row, rc) => {
      if (found) return false;
      row.forEach((col, cc) => {
        if (found) return false;
        col.forEach((p, i) => {
          if (p.id === id) {
            player = { ...p }
            found = true;
            removePlayer(true, [rc, cc], i)
            return false;
          }
        })
      })
    })

    if (!found) {
      data.game.bench.forEach((p, rc) => {
        if (p.id === id) {
          player = { ...p }
          found = true;
          removePlayer(false, rc, 0)
          return false;
        }
      })
    }

    if (!found) {
      data.game.missing.after.forEach((p, rc) => {
        if (p.id === id) {
          let d = { ...data };
          d.game.missing.after.splice(rc, 1);
          setData(d);
          return false;
        }
      })
      // just delete, don't return object
      return false;
    }

    return found ? player : false;

    // search bench
  }

  const addPlayer = (v) => {
    if (v == null || v.id === '')
      return false;
    setDirty(true);
    setVadirty(true);
    // check if player already in kader
    let player = removePlayerByID(v.id)
    let d = { ...data };
    if (addplayer.field === true) {
      if (player !== false) {
        // pointless to use index because the removePlayer might've altered the existing indices so get the visible index on the container
        let index = 0;
        d.game.players[addplayer.field_index[0]][addplayer.field_index[1]].forEach((e, i) => {
          if (e.visible === true) {
            index = i;
            return false;
          }
        })
        player.abgang = 0; // pointless to transfer the change out time
        player.visible = true;
        if (d.game.players[addplayer.field_index[0]][addplayer.field_index[1]][index].empty) {
          d.game.players[addplayer.field_index[0]][addplayer.field_index[1]] = [{
            ...player,
            displacement: d.game.players[addplayer.field_index[0]][addplayer.field_index[1]][0].displacement,
            formation_place: d.game.players[addplayer.field_index[0]][addplayer.field_index[1]][0].formation_place
          }];
          d.game.players[addplayer.field_index[0]][addplayer.field_index[1]][index].visible = true;
        } else {
          d.game.players[addplayer.field_index[0]][addplayer.field_index[1]][index].visible = false;
          d.game.players[addplayer.field_index[0]][addplayer.field_index[1]].splice(index + 1, 0, {
            ...player,
            displacement: d.game.players[addplayer.field_index[0]][addplayer.field_index[1]][0].displacement,
            formation_place: d.game.players[addplayer.field_index[0]][addplayer.field_index[1]][0].formation_place
          });
        }
      } else {
        let player = { ...newPlayer };
        player.id = v.id;
        player.name = v.display;
        player.img = v.img;
        player.displacement = d.game.players[addplayer.field_index[0]][addplayer.field_index[1]][0].displacement;
        player.formation_place = d.game.players[addplayer.field_index[0]][addplayer.field_index[1]][0].formation_place;
        player.visible = true;
        d.game.players[addplayer.field_index[0]][addplayer.field_index[1]][addplayer.player_index].visible = false;
        if (d.game.players[addplayer.field_index[0]][addplayer.field_index[1]][addplayer.player_index].empty === true)
          d.game.players[addplayer.field_index[0]][addplayer.field_index[1]][addplayer.player_index] = player;
        else
          d.game.players[addplayer.field_index[0]][addplayer.field_index[1]].splice(addplayer.player_index + 1, 0, player);
      }
    } else {
      let p = { ...newPlayer };
      p.id = v.id;
      p.name = v.display;
      p.img = v.img;
      p.visible = true;
      d.game.bench.push(p);
    }
    setData(d);
    setAddplayer({ field: '', field_index: 0, player_index: 0, modal: false });
  }

  const removePlayer = (field, field_index, index) => {
    setDirty(true);
    setVadirty(true);
    let d = { ...data };
    if (field === true) {
      let temp = d.game.players[field_index[0]][field_index[1]];
      if (temp.length > 1) {
        if (temp[index].visible === true) {
          d.game.players[field_index[0]][field_index[1]][index === 0 ? 1 : index - 1].visible = true;
        }
        d.game.players[field_index[0]][field_index[1]].splice(index, 1);
      } else {
        d.game.players[field_index[0]][field_index[1]][index] = {
          formation_place: temp[index].formation_place,
          displacement: temp[index].displacement,
          empty: true
        }
      }
    } else {
      d.game.bench.splice(field_index, 1);
    }
    setData(d);
  }

  const removeBench = (index) => {
    setDirty(true);
    setVadirty(true);
    let d = { ...data };
    d.game.bench.splice(index, 1);
    setData(d);
  }

  const addMissingplayer = (v) => {
    if (v == null || v.id === '')
      return false;
    let kill = false;
    data.game.missing.after.forEach(e => {
      if (e.id === v.id) {
        setAddmissingplayer(false);
        kill = true;
        return false;
      }
    })
    if (kill)
      return false;
    removePlayerByID(v.id)
    setDirty(true);
    setVadirty(true);
    let d = { ...data }
    d.game.missing.after.push(v);
    setData(d);
    setAddmissingplayer(false);
  }

  const removeMissingplayer = (i) => {
    setDirty(true);
    setVadirty(true);
    let d = { ...data };
    d.game.missing.after.splice(i, 1);
    setData(d);
  }

  const LoadingScreen = () => {
    return (
      <Box sx={{ mt: 7, width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  if (loading)
    return LoadingScreen();
  else
    return screen();
}

export default Team;
